<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12">
                                <button *ngIf="!fromApp" (click)="goTo('/estudiante')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                    class="fa fa-arrow-left"></i> Atrás</button>
                                <h5 class="header-title">Uso de la biblioteca</h5>
                                <div class="header-separator"></div>
                            </div>

                            <div class="row">
                                <div class="element1 col-md-2">
                                    <label>Mostrar Préstamos</label>
                                </div>
                                <div class="element3 col-md-1">
                                    <select name="dataTable2_length" [(ngModel)]="paginated.pageSize"
                                        (change)='onpageSizeSelectedPrestamo($event)' aria-controls="dataTable2"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="3">3</option>
                                        <option value="5" selected>5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                    </select>
                                    <!--select class='select-option' [(ngModel)]="selected"
                                        (change)='onOptionsSelected($event)'>
                                      <option class='option'  *ngFor='let option of dropDownData' 
                                      [value]="option.seo_val">{{option.text_val}}</option>
                                   </select-->


                                </div>

                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-table">
                                        <div class="table-responsive">
                                            <table class="table table-sm data-tables datatable-dark table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>Título</th>
                                                        <th>Autor</th>
                                                        <th>Código</th>
                                                        <th>Estado</th>
                                                        <th>Fecha Solicitud</th>
                                                        <th>Fecha Entrega</th>
                                                        <th>Fecha Plazo devolución</th>
                                                        <th>Fecha devolución</th>
                                                        <th>Observaciones</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let registro of prestamos">
                                                        <ngContainer *ngIf="registro.state == 'ent'">
                                                            <td class="nodevuelto">{{ registro.name }}</td>
                                                        </ngContainer>
                                                        <ngContainer *ngIf="registro.state != 'ent'">
                                                            <td class="devuelto">{{ registro.name }}</td>
                                                        </ngContainer>
                                                        <td>{{ registro.autor }}</td>
                                                        <td>{{ registro.codigo }}</td>
                                                        <td>{{ registro.state }}</td>
                                                        <td [innerHtml]="formatDatetime(registro.fecha_solicitud)"></td>
                                                        <td [innerHtml]="formatDatetime(registro.fecha_entrega)"></td>
                                                        <td [innerHtml]="formatDatetime(registro.fecha_plazo)"></td>
                                                        <td [innerHtml]="formatDatetime(registro.fecha_devolucion)"></td>
                                                        <td>{{ registro.observaciones }}</td>
                                                        <td><span *ngIf="plazoVencido(registro.fecha_plazo, registro.state)" class='nodevuelto'><strong>Plazo Vencido</strong></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-table">
                                        <div class="table-responsive">
                                            <table class="table table-sm data-tables datatable-dark">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span>Página {{ paginated.page }} de {{ paginated.totalPages }} </span>
                                                        </td>
                                                        <td>
                                                            <ul class="pagination">
                                                                <li class="paginate_button page-item next"
                                                                    id="dataTable3_next">
                                                                    <button aria-controls="dataTable3" data-dt-idx="3"
                                                                        tabindex="0" class="page-link"
                                                                        (click)="previousPrestamo()">Anterior</button>
                                                                </li>
                                                                <li class="paginate_button page-item next"
                                                                    id="dataTable3_next">
                                                                    <button aria-controls="dataTable3" data-dt-idx="3"
                                                                        tabindex="0" class="page-link"
                                                                        (click)="nextPrestamo()">Siguiente</button>
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>