import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { Evento } from '../classes/evento';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class EventoService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    // this.SERVER_URL = 'http://localhost/froebel-webservice/dist/socio/';
    this.SERVER_URL = URLConstants.backend_url + 'cita/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getCitas(socio_id: string) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'socio_id': socio_id
      }
    });
  }

  updateEvento(evento: Evento) {
    console.log(evento);
    console.log(this.SERVER_URL);
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: evento }, { headers: this.httpHeaders });
  }

}
