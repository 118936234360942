<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/panelsocio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                    class="fa fa-arrow-left"></i> Atrás</button>
                                <a [href]="mailto('Consulta a Biblioteca')" class="btn btn-flat btn-sm btn-outline-dark float-right">
                                    <i class="fa fa-envelope"></i> Enviar correo electrónico a encargado(a) de Biblioteca.</a>
                                <br />
                                <h5 class="header-title">Uso de la biblioteca</h5>
                                <div class="header-separator"></div>
                            </div>
                            <div>
                                <div>
                                    <span>
                                            Teléfono encargado(a) de Biblioteca {{config.biblioteca_telefono}}
                                        </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-table">
                                        <div class="table-responsive">
                                            <table class="table table-sm data-tables datatable-dark">
                                                <thead>
                                                    <tr>
                                                        <th>Estudiante</th>
                                                        <th>Libro</th>
                                                        <th>Fecha entrega al estudiante</th>
                                                        <th>Fecha devolución</th>
                                                        <th>Fecha plazo para devolución</th>
                                                        <th>Estado</th>
                                                        <th>Observaciones</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let registro of prestamoLibros" [ngClass]="{'plazovencido': registro.estado === 'Plazo vencido'}">
                                                        <td>{{ registro.estudiante }}</td>
                                                        <td>{{ registro.name }}</td>
                                                        <td>{{ registro.fecha_entrega | date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{ registro.fecha_devolucion | date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{ registro.fecha_plazo | date: 'dd/MM/yyyy'}}</td>
                                                        <td>{{ registro.estado }}</td>
                                                        <td>{{ registro.observaciones }}</td>
                                                        <td><span *ngIf="plazoVencido(registro.fecha_plazo, registro.state)" class='nodevuelto'><strong>Plazo Vencido</strong></span></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>