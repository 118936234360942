<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">
            
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">
                        
                        <div class="card-body">
                            <button *ngIf="!fromApp" (click)="goTo('/estudiante')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                class="fa fa-arrow-left"></i> Atrás</button>
                            <div class="col-sm-12">
                                <h5 class="header-title">Biblioteca</h5>
                                <div class="header-separator"></div>
                            </div>

                            <div class="row"> 
                                <div class="element1 col-md-1"> 
                                    <label>Mostrar libros</label>
                                </div>                                 
                                <div class="element3 col-md-1"> 
                                    <select name="dataTable2_length" 
                                    [(ngModel)]="paginated.pageSize" 
                                    (change)='onpageSizeSelected($event)'
                                    aria-controls="dataTable2" 
                                    class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="3">3</option>    
                                        <option value="5" selected>5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                    </select>

                                </div> 
                                <div class="element1 col-md-1"> 
                                    <div class="data_tables_filter"> 
                                        <label>Buscar</label>
                                    </div> 
                                </div> 
                                <div class="element2 col-md-2 row"> 
                                    <input type="text" [(ngModel)]="paginated.criteria" placeholder="Buscar..."/>
                                    <div class="icon-container">
                                        <span class="ti-search" (click)="search()"></span><span class="icon-name"></span>
                                    </div>

                                </div> 
                            </div> 
                            <div class="col-sm-12">
                                <div class="single-table">
                                    <div class="table-responsive">
                                        <table class="table table-sm data-tables datatable-dark">
                                            <thead>
                                                <tr>
                                                    <th>Título</th>
                                                    <th>Autor</th>
                                                    <th>Código</th>
                                                    <th>Disponible</th>
                                                    <th>Portada</th>
                                                    <th>Contra portada</th>
                                                    <th>Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let ejemplar of libros">
                                                    <td>{{ ejemplar.name }}</td>
                                                    <td>{{ ejemplar.autor }}</td>
                                                    <td>{{ ejemplar.codigo }}</td>
                                                    <td>{{ ejemplar.state }}</td>
                                                    <td>
                                                        <div id="{{ 'thumbnailPortada-' + ejemplar.id }}" 
                                                            style='display:block; width:150px; height:200px;' >
                                                            <img (click)="clickThumbnailPortada(ejemplar.id)"
                                                            class="thunbnailPortada"
                                                            style='display:block; width:100%;'                                                                             
                                                            src='{{ ejemplar.portada }}'/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div id="{{ 'thumbnailCPortada-' + ejemplar.id }}" 
                                                            style='display:block; width:150px; height:200px;' >
                                                        <img (click)="clickThumbnailCPortada(ejemplar.id)"
                                                        class="thunbnailCPortada"
                                                        style='display:block; width:100%;'                                                                             
                                                        ng-attr-id="{{ 'thumbnailCPortada-' + ejemplar.id }}"                 
                                                        src='{{ ejemplar.cportada }}'/>
                                                        </div>
                                                    </td>
                                                    
                                                    <td>
                                                        <button *ngIf="!isTutor" type="button" id="solicitar"
                                                        (click)="solicitarLibro(ejemplar.id)"
                                                        class="btn btn-xs btn-flat btn-light">
                                                        <i class="fa fa-pencil-square-o"></i>&nbsp;SOLICITAR
                                                        </button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-table">
                                        <div class="table-responsive">
                                            <table class="table table-sm data-tables datatable-dark">
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <span>Página {{ paginated.page }} de {{ paginated.totalPages }} </span>
                                                        </td>
                                                        <td>
                                                            <ul class="pagination">
                                                                <li class="paginate_button page-item next"
                                                                    id="dataTable3_next">
                                                                    <button aria-controls="dataTable3" data-dt-idx="3"
                                                                        tabindex="0" class="page-link"
                                                                        (click)="previous()">Anterior</button>
                                                                </li>
                                                                <li class="paginate_button page-item next"
                                                                    id="dataTable3_next">
                                                                    <button aria-controls="dataTable3" data-dt-idx="3"
                                                                        tabindex="0" class="page-link"
                                                                        (click)="next()">Siguiente</button>
                                                                </li>
                                                            </ul>
                                                        </td>>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>