<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/estudiante')"
                                    class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                        class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <h5 class="header-title">Asignaturas y Silabos</h5>
                                <div class="header-separator"></div>
                            </div>
                            <div id="avisos" style='background-color: firebrick; color:floralwhite'></div>
                            <div class="row col-md-12">
                                <div class="element3 col-md-12 table-responsive mdtext">
                                    <table class="table table-sm">
                                        <ng-container *ngFor="let asi of asignaturas">
                                            <tr>
                                                <td colspan="5" class="asignatura-row"><strong>{{asi.asignatura}}</strong></td>
                                            </tr>
                                            <ng-container *ngFor="let uni of asi.unidades">
                                                <tr>
                                                    <td colspan="3" class="unidad-row">{{uni.unidad}}</td>
                                                </tr>
                                                <tr *ngFor="let unisil of uni.unidad_silabos">
                                                    <td class="pl-3">
                                                        <i *ngIf="unisil.estado == 'terminado'" class="fa fa-check" style="color:green"></i> &nbsp;
                                                        {{unisil.name}}</td>
                                                        <td>{{unisil.fecha_fin}}</td>
                                                </tr>
                                            </ng-container>

                                        </ng-container>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>