<div class="page-container sbar_collapsed">

    <!-- main content area start -->
    <div class="main-content">
        <app-header></app-header>
        <!-- page title area start -->

        <!-- page title area end -->
        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <div class="col-sm-12">
                                    <h5 class="header-title">Datos personales</h5>
                                    <div class="header-separator"></div>
                                </div>


                                <div class="form-row align-items-center">
                                    <div class="col-sm-6">
                                        <label for="apellidoPaterno">Appelidos</label>
                                        <input id="apellidoPaterno" class="form-control form-control-sm" type="text"
                                            placeholder="Paterno" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="apellidoMaterno"> </label>
                                        <input id="apellidoMaterno" class="form-control form-control-sm" type="text"
                                            placeholder="Materno" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-12">
                                        <label for="nombres">Nombres</label>
                                        <input id="nombres" class="form-control form-control-sm" type="text" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-4">
                                        <label for="fechaNacimiento">Fecha de nacimiento</label>
                                        <input id="fechaNacimiento" class="form-control form-control-sm" type="date" />
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="sexo">Sexo</label>
                                        <select id="sexo" class="form-control form-control-sm">
                                            <option value="0">Seleccionar</option>
                                            <option value="m">Masculino</option>
                                            <option value="f">Femenino</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-4">
                                        <label for="tipo_doc">Documento</label>
                                        <select id="tipoDocumento" class="form-control form-control-sm">
                                            <option value="0">Tipo documento</option>
                                            <option value="m">C.I.</option>
                                            <option value="f">Pasaporte</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-8">
                                        <label for="documento">. </label>
                                        <input id="documento" class="form-control form-control-sm" type="text"
                                            placeholder="Nro Documento" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-6">
                                        <label for="lugarRegistro">Lugar de registro</label>
                                        <select id="lugarRegistro" class="form-control form-control-sm">
                                            <option value="0">Seleccionar</option>
                                            <option value="cbba">Cochabamba</option>
                                            <option value="scz">Santa Cruz</option>
                                            <option value="lpz">La Paz</option>
                                        </select>
                                    </div>
                                </div>

                                <div class="form-row align-items-center">
                                    <div class="col-sm-6">
                                        <label for="estadoCivil">Estado civil</label>
                                        <select id="estadoCivil" class="form-control form-control-sm">
                                            <option value="0">Seleccionar</option>
                                            <option value="cbba">Soltero(a)</option>
                                            <option value="scz">Casado(a)</option>
                                            <option value="lpz">No sabe</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-12">
                                        <label for="idioma">Idioma</label>
                                        <input id="idioma" class="form-control form-control-sm" type="text" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-12">
                                        <label for="email">Email</label>
                                        <input id="email" class="form-control form-control-sm" type="email" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-12">
                                        <label for="profesion">Profesión</label>
                                        <input id="profesion" class="form-control form-control-sm" type="text"
                                            placeholder="Profesión" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-12">
                                        <label for="ocupacion">Ocupación</label>
                                        <input id="ocupacion" class="form-control form-control-sm" type="text"
                                            placeholder="Ocupación" />
                                    </div>
                                </div>
                                <div class="form-row align-items-center">
                                    <div class="col-sm-12">
                                        <button type="button"
                                            class="btn btn-flat btn-outline-danger btn-red mb-3 mt-3 pr-4 pl-4 pull-right">
                                            <i class="fa fa-save"></i> &nbsp; Guardar &gt;</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="header-title">Estudiantes vinculados</h4>
                            <div class="single-table">
                                <div class="table-responsive">
                                    <table class="table text-center">
                                        <thead class="text-uppercase bg-black">
                                            <tr class="text-white">
                                                <th>Nombre</th>
                                                <th> </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><strong>Daniela Flores</strong> <br /> <span
                                                        class="text-secondary">Primero Blanco</span></td>
                                                <td>
                                                    <a href="contract.pdf">CONTRATO</a> | <a href="RUDE.pdf">RUDE</a>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><strong>Lucas Alejandro Flores</strong> <br /> <span
                                                        class="text-secondary">Quinto Azul</span></td>
                                                <td>
                                                    <button type="button"
                                                        class="btn btn-flat btn-sm btn-outline-dark ">INSCRIBIR</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- main content area end -->
</div>