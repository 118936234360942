<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">


        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/panelsocio')"
                                    class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                        class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <h2 class="header-title">Reporte de Clases</h2>
                                
                            </div>

                            <div class="row">
                                <div class="element3 col-md-12">
                                    <ng-container *ngFor="let estudiante of estudiantesList">
                                        <h3 class="header-title">{{ estudiante.estudiante }}</h3>
                                        <div class="header-separator"></div>
                                        <div class="table-responsive">
                                            <table class="table table-sm datatable-dark table-striped">
                                                <thead>
                                                    <tr>
                                                        <th style="min-width:70px;">Fecha</th>
                                                        <th>Asignatura / Curso / Docente</th>
                                                        <th>Tipo</th>
                                                        <th>Detalle</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let reporte of estudiante.detalleReporte">
                                                        <td class="reporte-line">
                                                            <div [innerHtml]="formatDatetime(reporte.fecha_reporte)">
                                                            </div>
                                                        </td>
                                                        <td class="reporte-line" style="max-width:250px;">
                                                            <strong>Asignatura: </strong>{{ reporte.asignatura }}<br />
                                                            <strong>Curso: </strong>{{ reporte.curso }}<br />
                                                            <strong>Docente: </strong>{{ reporte.docente }}<br />
                                                            <strong>Referencia: </strong>{{ reporte.name }}<br />
                                                        </td>

                                                        <td class="reporte-line">{{ reporte.tipo_reporte }}</td>
                                                        <td class="reporte-line">{{ reporte.detalle }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </ng-container>
                                    <!--div class="table-responsive">
                                        <table class="table table-sm datatable-dark ">
                                            <thead>
                                                <tr>
                                                    <th style="min-width:70px;">Fecha</th>
                                                    <th style="min-width:120px;">Estudiante</th>
                                                    <th style="min-width:120px;">Asignatura</th>
                                                    <th>Curso/Docente</th>
                                                    <th>Tipo</th>
                                                    <th>Detalle</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let reporte of clasereporte" [(ngClass)]="reporte.class">
                                                    <td class="reporte-line" >
                                                        <div [innerHtml]="formatDatetime(reporte.fecha_reporte)"></div>
                                                    </td>
                                                    <td class="reporte-line">{{ reporte.estudiante }}</td>
                                                    <td class="reporte-line">
                                                        {{ reporte.asignatura }}
                                                    </td>
                                                    <td class="reporte-line">
                                                        {{ reporte.name }}<br/>
                                                        {{ reporte.docente }}
                                                    </td>
                                                    
                                                    <td class="reporte-line">{{ reporte.tipo_reporte }}</td>
                                                    <td class="reporte-line">{{ reporte.detalle }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div-->


                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>