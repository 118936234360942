import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule, Routes } from '@angular/router';
import { BasicComponent } from './components/basic/basic.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { SocioComponent } from './components/socio/socio.component';
import { InscripcionComponent } from './components/inscripcion/inscripcion.component';
import { PendientesComponent } from './components/pendientes/pendientes.component';
import { AuthService } from './services/auth.service';
import { EstudianteComponent } from './components/estudiante/estudiante.component';
import { BibliotecaComponent } from './components/biblioteca/biblioteca.component';
import { BoletinComponent } from './components/boletin/boletin.component';
import { PrestamoComponent } from './components/prestamo/prestamo.component';
import { EncuestaComponent } from './components/encuesta/encuesta.component';
import { ClaseReporteComponent } from './components/clase-reporte/clase-reporte.component';
import { PanelSocioComponent } from './components/panelsocio/panelsocio.component';
import { PrestamoLibrosComponent } from './components/prestamo-libros/prestamo-libros.component';
import { CitaComponent } from './components/cita/cita.component';
import { PaneleleccionComponent } from './components/paneleleccion/paneleleccion.component';
import { EleccionComponent } from './components/eleccion/eleccion.component';
import { AsignaturaComponent } from './components/asignatura/asignatura.component';
import { EventoComponent } from './components/evento/evento.component';
import { BusComponent } from './components/bus/bus.component';
import { EbusComponent } from './components/ebus/ebus.component';
import { MfaComponent } from './components/mfa/mfa.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const rutas: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'login/:token', component: LoginComponent },
  { path: 'socio', component: SocioComponent },
  { path: 'inscripcion/:id', component: InscripcionComponent },
  { path: 'pendientes', component: PendientesComponent },
  { path: 'estudiante', component: EstudianteComponent },
  { path: 'biblioteca', component: BibliotecaComponent },
  { path: 'prestamo', component: PrestamoComponent },
  { path: 'boletin', component: BoletinComponent },
  { path: 'encuesta', component: EncuestaComponent },
  { path: 'clasereporte', component: ClaseReporteComponent },
  { path: 'panelsocio', component: PanelSocioComponent },
  { path: 'paneleleccion', component: PaneleleccionComponent },
  { path: 'eleccion', component: EleccionComponent },
  { path: 'prestamolibros', component: PrestamoLibrosComponent },
  { path: 'cita', component: CitaComponent },
  { path: 'asignatura', component: AsignaturaComponent },
  { path: 'evento', component: EventoComponent },
  { path: 'bus', component: BusComponent },
  { path: 'ebus', component: EbusComponent },
  { path: 'mfa', component: MfaComponent },
  { path: '**', component: LoginComponent },
]

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    BasicComponent,
    SocioComponent,
    InscripcionComponent,
    PendientesComponent,
    EstudianteComponent,
    BibliotecaComponent,
    BoletinComponent,
    PrestamoComponent,
    EncuestaComponent,
    PanelSocioComponent,
    ClaseReporteComponent,
    PrestamoLibrosComponent,
    CitaComponent,
    PaneleleccionComponent,
    EleccionComponent,
    AsignaturaComponent,
    EventoComponent,
    BusComponent,
    EbusComponent,
    MfaComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    // useHash:true, avoids refresh page 404 issue
    RouterModule.forRoot(rutas, { useHash: true }),
    NgbModule
  ],
  providers: [
    AuthService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
