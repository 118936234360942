import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { Libro } from '../classes/libro';
import { Prestamo } from '../classes/prestamo';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class LibroService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    // this.SERVER_URL = 'http://localhost/froebel-webservice/dist/socio/';
    this.SERVER_URL = URLConstants.backend_url + 'biblioteca/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getLibros(criteria, page, pageSize, order, state) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'criteria':criteria,
        'page':page,
        'page_size':pageSize,
        'order':order,
        'state':state
      }
    });
  }

  updateData(libro: Libro) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: libro }, { headers: this.httpHeaders });
  }

  getRecibosPendientes(socioId) {
    return this._http.get<PHPResponse>(this.SERVER_URL + 'recibos_pendientes.php', {
      headers: this.httpHeaders,
      params: {
        'socio_id': socioId
      }
    });
  }

}
