<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>
<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-8 mt-5">
                    <div class="card bg-glass">
                        <div class="card-body">
                            <div class="col-lg-12 pt-3 bg-white pb-5">

                                <button (click)="goTo('/socio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                        class="fa fa-arrow-left"></i> Atrás</button>
                                <br />


                                <ul class="nav nav-tabs mb-3 bg-white" id="pills-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="pills-datos-tab" data-toggle="pill"
                                            href="#pills-datos" role="tab" aria-controls="pills-datos"
                                            aria-selected="true">Datos</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-direccion-tab" data-toggle="pill"
                                            href="#pills-direccion" role="tab" aria-controls="pills-direccion"
                                            aria-selected="false">Dirección
                                            actual</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="pills-social-tab" data-toggle="pill"
                                            href="#pills-social" role="tab" aria-controls="pills-social"
                                            aria-selected="false">Aspectos Soc.</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a class="nav-link" id="pills-adicional-tab" data-toggle="pill"
                                            href="#pills-adicional" role="tab" aria-controls="pills-adicional"
                                            aria-selected="false">Servicios adicionales</a>
                                    </li> -->
                                </ul>
                                <div class="tab-content bg-white" id="pills-tabContent">
                                    <div class="tab-pane fade show active" id="pills-datos" role="tabpane"
                                        aria-labelledby="pills-datos-tab">
                                        <!--div class="col-sm-12"-->
                                        <div *ngIf="selectValues.editables==false" class="form-row align-items-center">
                                            <div class="col-sm-8">
                                                <label>Nombres y apellidos</label>
                                                <div class="input-readonly">
                                                    {{ formRude.alumno_nombres }}&nbsp;
                                                    {{ formRude.alumno_apellido_paterno }}&nbsp;
                                                    {{ formRude.alumno_apellido_materno }}
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                            <div class="col-sm-10">
                                                <label>Nombres</label>
                                                <input [(ngModel)]="formRude.alumno_nombres"
                                                    id="formRude_alumno_nombres" required
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                        </div>
                                        <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                            <div class="col-sm-6">
                                                <label>Ap. Paterno</label>
                                                <input [(ngModel)]="formRude.alumno_apellido_paterno"
                                                    id="formRude_alumno_apellido_paterno" required
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                            <div class="col-sm-6">
                                                <label>Ap. Materno</label>
                                                <input [(ngModel)]="formRude.alumno_apellido_materno"
                                                    id="formRude_alumno_apellido_materno" required
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                        </div>


                                        <h5 class="header-title pt-4">Datos personales</h5>
                                        <div class="header-separator"></div>
                                        <div class="col-sm-12" id="errorsSocio" style="display:none;">
                                        </div>
                                        <div class="form-row align-items-center">

                                            <div class="col-sm-4">
                                                <label>Código R.U.D.E.</label>
                                                <div class="input-readonly">
                                                    {{ formRude.alumno_codigo_rude }}&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Fecha de nacimiento</label>
                                                <input *ngIf="selectValues.editables==true"
                                                    [(ngModel)]="formRude.alumno_fecha_nac"
                                                    id="formRude_alumno_fecha_nac" required
                                                    class="form-control form-control-sm" type="date" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_fecha_nac }}
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label for="sexoAlumnoInput">Sexo</label>
                                                <select *ngIf="selectValues.editables==true"
                                                    [(ngModel)]="formRude.alumno_sexo" id="formRude_alumno_sexo"
                                                    required class="form-control form-control-sm">
                                                    <option *ngFor="let als of selectValues.sexos"
                                                        [ngValue]="als.value">
                                                        {{ als.name }}</option>
                                                </select>
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ getNameValue(selectValues.sexos, formRude.alumno_sexo) }}
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                            <div class="col-sm-6">
                                                <label>Carnet de Identidad</label>
                                                <input [(ngModel)]="formRude.alumno_documento"
                                                    id="formRude_alumno_documento" required
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                        </div>
                                        <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Complemento<span class="required"> *</span></label>
                                                <input [(ngModel)]="formRude.alumno_complemento"
                                                    id="formRude_alumno_complemento"
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                            <div class="col-sm-4">
                                                <label>Expedido<span class="required"> *</span></label>
                                                <select [(ngModel)]="formRude.alumno_expedido"
                                                    id="formRude_alumno_expedido" required
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let exp of selectValues.ciudades"
                                                        [ngValue]="exp.value">{{ exp.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="selectValues.editables==false" class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Carnet de Identidad<span class="required"> *</span></label>
                                                <div class="input-readonly">
                                                    {{ formRude.alumno_documento }} &nbsp;
                                                    {{ formRude.alumno_complemento }} &nbsp;
                                                    {{ getNameValue(selectValues.ciudades, formRude.alumno_expedido) }}
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>País</label>
                                                <input *ngIf="selectValues.editables==true"
                                                    [(ngModel)]="formRude.alumno_pais_nac" id="formRude_alumno_pais_nac"
                                                    required class="form-control form-control-sm" type="text" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_pais_nac }} &nbsp;
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>Departamento</label>
                                                <input *ngIf="selectValues.editables==true"
                                                    [(ngModel)]="formRude.alumno_departamento_nac"
                                                    id="formRude_alumno_departamento_nac" required
                                                    class="form-control form-control-sm" type="text" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_departamento_nac }} &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Provincia</label>
                                                <input *ngIf="selectValues.editables==true"
                                                    [(ngModel)]="formRude.alumno_provincia_nac"
                                                    id="formRude_alumno_provincia_nac" required
                                                    class="form-control form-control-sm" type="text" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_provincia_nac }} &nbsp;
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>Localidad</label>
                                                <input *ngIf="selectValues.editables==true"
                                                    [(ngModel)]="formRude.alumno_localidad_nac"
                                                    id="formRude_alumno_localidad_nac" required
                                                    class="form-control form-control-sm" type="text" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_localidad_nac }} &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                        <h6 class="pt-4 pb-2">Certificado de nacimiento</h6>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Oficialia Nro</label>
                                                <input *ngIf="selectValues.editables==true" type="text"
                                                    [(ngModel)]="formRude.alumno_oficialia_nac"
                                                    id="formRude_alumno_oficialia_nac" required
                                                    class="form-control form-control-sm" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_oficialia_nac }} &nbsp;
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>Libro Nro</label>
                                                <input *ngIf="selectValues.editables==true" type="text"
                                                    [(ngModel)]="formRude.alumno_libro_nac"
                                                    id="formRude_alumno_libro_nac" required
                                                    class="form-control form-control-sm" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_libro_nac }} &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Partida Nro</label>
                                                <input *ngIf="selectValues.editables==true" type="text"
                                                    [(ngModel)]="formRude.alumno_partida_nac"
                                                    id="formRude_alumno_partida_nac" required
                                                    class="form-control form-control-sm" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_partida_nac }} &nbsp;
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <label>Folio Nro</label>
                                                <input *ngIf="selectValues.editables==true" type="text"
                                                    [(ngModel)]="formRude.alumno_folio_nac"
                                                    id="formRude_alumno_folio_nac" required
                                                    class="form-control form-control-sm" />
                                                <div *ngIf="selectValues.editables==false" class="input-readonly">
                                                    {{ formRude.alumno_folio_nac }} &nbsp;
                                                </div>
                                            </div>
                                        </div>

                                        <h5 *ngIf="formRude.alumno_discapacidad==true" class="header-title mt-5">
                                            necesidades especiales
                                        </h5>
                                        <div *ngIf="formRude.alumno_discapacidad==true" class="header-separator"></div>
                                        <div *ngIf="formRude.alumno_discapacidad==true"
                                            class="form-row align-items-center">
                                            <div class="col-sm-3">
                                                <label>Tipo necesidad especial</label>
                                                <div class="input-readonly">
                                                    <div class="input-readonly">
                                                        {{ getNameValue(selectValues.tiposDiscapacidad,
                                                        formRude.alumno_tipo_disc) }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-3">
                                                <label>Grado</label>
                                                <div class="input-readonly">
                                                    <div class="input-readonly">
                                                        {{ getNameValue(selectValues.gradosDiscapacidad,
                                                        formRude.alumno_grado_disc) }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 class="header-title mt-5">Datos de registro del formulario</h5>
                                        <div class="header-separator"></div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-4">
                                                <label>Fecha de llenado</label>
                                                <input [(ngModel)]="formRude.rude_fecha" type="date"
                                                    id="formRude_rude_fecha" required
                                                    class="form-control form-control-sm" />
                                            </div>

                                            <div class="col-sm-4">
                                                <label>Lugar de llenado</label>
                                                <input [(ngModel)]="formRude.rude_lugar" type="text"
                                                    id="formRude_rude_lugar" required
                                                    class="form-control form-control-sm" />
                                            </div>
                                        </div>

                                        <h5 class="header-title mt-5">Datos de salud</h5>
                                        <div class="header-separator"></div>

                                        <div class="form-row align-items-center">
                                            <div class="col-sm-6">
                                                <label>Tipo de sangre</label>
                                                <input [(ngModel)]="formRude.salud_tipo_sangre"
                                                    id="formRude_salud_tipo_sangre" class="form-control form-control-sm"
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-10">
                                                <label>Alergias</label>
                                                <input [(ngModel)]="formRude.salud_alergias"
                                                    id="formRude_salud_alergias" class="form-control form-control-sm"
                                                    type="text" />
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-12">
                                                <label>Datos médicos importantes</label>
                                                <input [(ngModel)]="formRude.salud_datos" id="formRude_salud_datos"
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                        </div>


                                        <h5 class="header-title mt-5">Datos de contacto</h5>
                                        <div class="header-separator"></div>

                                        <div class="form-row align-items-center">
                                            <div class="col-sm-12">
                                                <label>Email</label>
                                                <input [(ngModel)]="formRude.alumno_email" id="formRude_alumno_email"
                                                    required class="form-control form-control-sm" type="email" />
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-12">
                                                <label>Idioma que habla frecuentemente</label>
                                                <input [(ngModel)]="formRude.idioma_materno"
                                                    id="formRude_idioma_materno" required
                                                    class="form-control form-control-sm" type="text" />
                                            </div>
                                        </div>

                                        <div class="form-row align-items-center">
                                            <div class="col-sm-12">
                                                <label>Con quién vive actualmente</label>
                                                <select [(ngModel)]="formRude.hospedador" id="formRude_hospedador"
                                                    required (ngModelChange)="onchangeHospedador($event)"
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let hos of selectValues.hospedadores"
                                                        [ngValue]="hos.value">{{ hos.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div *ngIf="formRude.hospedador=='extraordinaria'" class="form-row align-items-center">
                                            <div class="col-sm-12">
                                                <label>Nombre del centro de acogida</label>
                                                <input [(ngModel)]="formRude.hospedador_nombre" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div *ngIf="formRude.hospedador=='tutor'" class="col-lg-12">
                                            <!-- Datos Otro -->
                                            <hr />
                                            <h6 class="header-title">DATOS</h6>
                                            <div class="header-separator"></div>
                                            <div class="form-row align-items-center">
                                                <label>Relación con alumno</label>
                                                <input [(ngModel)]="formRude.tutor_relacion" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Documento C.I.</label>
                                                <input [(ngModel)]="formRude.tutor_cedula" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Apellidos<span class="required"> *</span></label>
                                                <input [(ngModel)]="formRude.tutor_apellido_paterno" type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="Apellido Paterno" />
                                                <input [(ngModel)]="formRude.tutor_apellido_materno" type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="Apellido Materno" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Nombres</label>
                                                <input [(ngModel)]="formRude.tutor_nombres" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Idioma que habla frecuentemente</label>
                                                <input [(ngModel)]="formRude.tutor_idioma" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Ocupación laboral actual</label>
                                                <input [(ngModel)]="formRude.tutor_ocupacion" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Mayor grado de instrucción alcanzado</label>
                                                <input [(ngModel)]="formRude.tutor_instruccion" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Fecha de nacimiento</label>
                                                <input [(ngModel)]="formRude.tutor_fecha_nacimiento" type="date"
                                                    class="form-control form-control-sm" />
                                            </div>

                                            <!-- el rudo-->
                                            <h5 class="pt-3">Datos domicilio</h5>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-10">
                                                    <label>Departamento</label>
                                                    <input [(ngModel)]="formRude.domicilio_departamento"
                                                        id="formRude_domicilio_departamento1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-5">
                                                    <label>Provincia</label>
                                                    <input [(ngModel)]="formRude.domicilio_provincia"
                                                        id="formRude_domicilio_provincia1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                                <div class="col-sm-5">
                                                    <label>Sección/Municipio</label>
                                                    <input [(ngModel)]="formRude.domicilio_municipio"
                                                        id="formRude_domicilio_municipio1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>

                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-5">
                                                    <label>Localidad/Comunidad</label>
                                                    <input [(ngModel)]="formRude.domicilio_localidad"
                                                        id="formRude_domicilio_localidad1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                                <div class="col-sm-5">
                                                    <label>Zona/Villa</label>
                                                    <input [(ngModel)]="formRude.domicilio_zona"
                                                        id="formRude.domicilio_zona1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-7">
                                                    <label>Avenida/Calle</label>
                                                    <input [(ngModel)]="formRude.domicilio_avenida"
                                                        id="formRude_domicilio_avenida1" required type="text"
                                                        class="form-control form-control-sm" />
                                                </div>
                                                <div class="col-sm-3">
                                                    <label>No. Vivienda</label>
                                                    <input [(ngModel)]="formRude.domicilio_nro_vivienda"
                                                        id="formRude_domicilio_nro_vivienda1" required type="text"
                                                        maxlength="7" class="form-control form-control-sm">
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-10">
                                                    <label>Punto de referencia <small>(opcional)</small></label>
                                                    <input [(ngModel)]="formRude.domicilio_punto_referencia"
                                                        id="formRude_domicilio_punto_referencia1" type="text"
                                                        class="form-control form-control-sm" />
                                                </div>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <div class="form-group">
                                                    <label>Teléfono fijo</label>
                                                    <input [(ngModel)]="formRude.domicilio_telf_fijo" type="text"
                                                        maxlength="8" class="form-control form-control-sm">
                                                </div>
                                                <div class="form-group">
                                                    <label>Celular de Contacto</label>
                                                    <input [(ngModel)]="formRude.domicilio_telf_movil" type="text"
                                                        maxlength="8" class="form-control form-control-sm">
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="formRude.hospedador=='extraordinaria'" class="col-lg-12">
                                            <!-- Datos extraordinaria -->
                                            <hr />
                                            <h6 class="header-title">Datos del tutor(a) extraordinario(a)</h6>
                                            <div class="header-separator"></div>
                                            <div class="form-row align-items-center">
                                                <label>Documento C.I. o DNI</label>
                                                <input [(ngModel)]="formRude.tutor2_cedula" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Complemento</label>
                                                <input [(ngModel)]="formRude.tutor2_complemento" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Expedido</label>
                                                    <select [(ngModel)]="formRude.tutor2_expedido"
                                                        id="formRude_tutor2_expedido" required
                                                        class="form-control form-control-sm">
                                                        <option *ngFor="let exp of selectValues.ciudades"
                                                            [ngValue]="exp.value">{{ exp.name }}</option>
                                                    </select>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Apellidos<span class="required"> *</span></label>
                                                <input [(ngModel)]="formRude.tutor2_apellido_paterno" type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="Apellido Paterno" />
                                                <input [(ngModel)]="formRude.tutor2_apellido_materno" type="text"
                                                    class="form-control form-control-sm"
                                                    placeholder="Apellido Materno" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Nombres</label>
                                                <input [(ngModel)]="formRude.tutor2_nombres" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Cargo actual</label>
                                                <input [(ngModel)]="formRude.tutor2_cargo" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="form-row align-items-center">
                                                <label>Nombre de la Institución</label>
                                                <input [(ngModel)]="formRude.tutor2_institucion" type="text"
                                                    class="form-control form-control-sm" />
                                            </div>

                                            <!-- el rudo-->
                                            <h5 class="pt-3">Datos domicilio</h5>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-10">
                                                    <label>Departamento</label>
                                                    <input [(ngModel)]="formRude.domicilio_departamento"
                                                        id="formRude_domicilio_departamento1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-5">
                                                    <label>Provincia</label>
                                                    <input [(ngModel)]="formRude.domicilio_provincia"
                                                        id="formRude_domicilio_provincia1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                                <div class="col-sm-5">
                                                    <label>Sección/Municipio</label>
                                                    <input [(ngModel)]="formRude.domicilio_municipio"
                                                        id="formRude_domicilio_municipio1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>

                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-5">
                                                    <label>Localidad/Comunidad</label>
                                                    <input [(ngModel)]="formRude.domicilio_localidad"
                                                        id="formRude_domicilio_localidad1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                                <div class="col-sm-5">
                                                    <label>Zona/Villa</label>
                                                    <input [(ngModel)]="formRude.domicilio_zona"
                                                        id="formRude.domicilio_zona1" required type="text"
                                                        maxlength="38" class="form-control form-control-sm" />
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-7">
                                                    <label>Avenida/Calle</label>
                                                    <input [(ngModel)]="formRude.domicilio_avenida"
                                                        id="formRude_domicilio_avenida1" required type="text"
                                                        class="form-control form-control-sm" />
                                                </div>
                                                <div class="col-sm-3">
                                                    <label>No. Vivienda</label>
                                                    <input [(ngModel)]="formRude.domicilio_nro_vivienda"
                                                        id="formRude_domicilio_nro_vivienda1" required type="text"
                                                        maxlength="7" class="form-control form-control-sm">
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-sm-10">
                                                    <label>Punto de referencia <small>(opcional)</small></label>
                                                    <input [(ngModel)]="formRude.domicilio_punto_referencia"
                                                        id="formRude_domicilio_punto_referencia1" type="text"
                                                        class="form-control form-control-sm" />
                                                </div>
                                            </div>

                                            <div class="form-check form-check-inline">
                                                <div class="form-group">
                                                    <label>Teléfono fijo</label>
                                                    <input [(ngModel)]="formRude.domicilio_telf_fijo" type="text"
                                                        maxlength="8" class="form-control form-control-sm">
                                                </div>
                                                <div class="form-group">
                                                    <label>Celular de Contacto</label>
                                                    <input [(ngModel)]="formRude.domicilio_telf_movil" type="text"
                                                        maxlength="8" class="form-control form-control-sm">
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="formRude.hospedador=='padre' || formRude.hospedador=='padres'"
                                            class="col-lg-12">
                                            <hr />
                                            <!-- PADRE -->
                                            <h6 class="header-title">DATOS Padre</h6>
                                            <div class="header-separator"></div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Documento C.I.</label>
                                                    <div class="input-readonly">
                                                        {{ padre.documento }} &nbsp;
                                                        {{ padre.documento_complemento }} &nbsp;
                                                        {{ getNameValue(selectValues.ciudades,
                                                        padre.documento_expedido) }}
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Nombre</label>
                                                    <div class="input-readonly">
                                                        {{ padre.nombres }} &nbsp;
                                                        {{ padre.apellido_paterno }} &nbsp;
                                                        {{ padre.apellido_materno }} &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Idioma que habla frecuentemente</label>
                                                    <div class="input-readonly">{{ formRude.padre_idioma }} &nbsp;</div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Ocupación laboral actual</label>
                                                    <div class="input-readonly">
                                                        {{ padre.trabajo_actual }} &nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Mayor grado de instrucción alcanzado</label>
                                                    <div class="input-readonly">
                                                        {{ padre.instruccion }}&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Fecha de nacimiento</label>
                                                    <div class="input-readonly">
                                                        {{ padre.fecha_nacimiento }}&nbsp;
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div *ngIf="formRude.hospedador=='madre' || formRude.hospedador=='padres'"
                                            class="col-lg-12">
                                            <hr />
                                            <!-- MADRE -->
                                            <h6 class="header-title">DATOS Madre</h6>
                                            <div class="header-separator"></div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Documento C.I.</label>
                                                    <div class="input-readonly">
                                                        {{ madre.documento }} &nbsp;
                                                        {{ madre.documento_complemento }} &nbsp;
                                                        {{ getNameValue(selectValues.ciudades, madre.documento_expedido)
                                                        }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Nombre</label>
                                                    <div class="input-readonly">
                                                        {{ madre.nombres }} &nbsp;
                                                        {{ madre.apellido_paterno }} &nbsp;
                                                        {{ madre.apellido_materno }}
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Idioma que habla frecuentemente</label>
                                                    <div class="input-readonly">
                                                        {{ madre.idioma }}&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Ocupación laboral actual</label>
                                                    <div class="input-readonly">
                                                        {{ madre.trabajo_actual }}&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Mayor grado de instrucción alcanzado</label>
                                                    <div class="input-readonly">
                                                        {{ madre.instruccion }}&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-row align-items-center">
                                                <div class="col-lg-12">
                                                    <label>Fecha de nacimiento</label>
                                                    <div class="input-readonly">
                                                        {{ madre.fecha_nacimiento }}&nbsp;
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- TAB DIRECCION -->
                                    <div class="tab-pane fade" id="pills-direccion" role="tabpane"
                                        aria-labelledby="pills-direccion-tab">
                                        <div class="col-sm-12">
                                            <h5 class="header-title">Dirección actual</h5>
                                            <div class="header-separator"></div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-10">
                                                <label>Departamento</label>
                                                <input [(ngModel)]="formRude.domicilio_departamento" type="text"
                                                    id="formRude_domicilio_departamento" required maxlength="38"
                                                    class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-5">
                                                <label>Provincia</label>
                                                <input [(ngModel)]="formRude.domicilio_provincia" type="text"
                                                    id="formRude_domicilio_provincia" required maxlength="38"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="col-sm-5">
                                                <label>Sección/Municipio</label>
                                                <input [(ngModel)]="formRude.domicilio_municipio" type="text"
                                                    id="formRude_domicilio_municipio" required maxlength="38"
                                                    class="form-control form-control-sm" />
                                            </div>

                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-5">
                                                <label>Localidad/Comunidad</label>
                                                <input [(ngModel)]="formRude.domicilio_localidad" type="text"
                                                    id="formRude_domicilio_localidad" required maxlength="38"
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="col-sm-5">
                                                <label>Zona/Villa</label>
                                                <input [(ngModel)]="formRude.domicilio_zona" type="text"
                                                    id="formRude_domicilio_zona" required maxlength="38"
                                                    class="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-7">
                                                <label>Avenida/Calle</label>
                                                <input [(ngModel)]="formRude.domicilio_avenida" type="text"
                                                    id="formRude_domicilio_avenida" required
                                                    class="form-control form-control-sm" />
                                            </div>
                                            <div class="col-sm-3">
                                                <label>No. Vivienda</label>
                                                <input [(ngModel)]="formRude.domicilio_nro_vivienda" type="text"
                                                    id="formRude_domicilio_nro_vivienda" required maxlength="7"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        <div class="form-row align-items-center">
                                            <div class="col-sm-10">
                                                <label>Punto de referencia</label>
                                                <input [(ngModel)]="formRude.domicilio_punto_referencia" type="text"
                                                    id="formRude_domicilio_punto_referencia"
                                                    class="form-control form-control-sm" />
                                            </div>
                                        </div>

                                        <div class="form-row align-items-center">
                                            <div class="col-sm-3">
                                                <label>Teléfono fijo</label>
                                                <input [(ngModel)]="formRude.domicilio_telf_fijo" type="text"
                                                    id="formRude_domicilio_telf_fijo" required maxlength="8"
                                                    class="form-control form-control-sm">
                                            </div>
                                            <div class="col-sm-3">
                                                <label>Celular contacto</label>
                                                <input [(ngModel)]="formRude.domicilio_telf_movil" type="text"
                                                    id="formRude_domicilio_telf_movil" maxlength="8"
                                                    class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        <!-- <div class="form-row align-items-center">
                                            <div class="col-sm-6">
                                                <label>Zona autobus escolar</label>
                                                <select [(ngModel)]="formRude.domicilio_zona_bus"
                                                    id="formRude_domicilio_zona_bus"
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let zona of selectValues.zonas"
                                                        [ngValue]="zona.value">{{ zona.name }}</option>
                                                </select>
                                            </div>
                                        </div> -->
                                    </div>

                                    <!-- TAB SOCIAL -->
                                    <div class="tab-pane fade" id="pills-social" role="tabpanel"
                                        aria-labelledby="pills-social-tab">
                                        <div class="col-sm-8" id="errorsNoSocio" style="display:none;">
                                        </div>

                                        <h5 class="header-title mt-3">
                                            Idioma y pertenencia cultural del estudiante
                                        </h5>
                                        <div class="header-separator"></div>

                                        <div>¿Cuál es el idioma en el aprendió a hablar en su niñez?</div>
                                        <div class="col-sm-6">
                                            <input [(ngModel)]="formRude.idioma_materno" type="text"
                                                id="formRude_idioma_materno1" required maxlength="10"
                                                class="form-control form-control-sm" placeholder="Idioma">
                                        </div>
                                        <hr />
                                        <h6>¿Qué idioma(s) habla frecuentemente?</h6>
                                        <div class="col-sm-6">
                                            <input [(ngModel)]="formRude.idioma_1" id="formRude_idioma_1" type="text"
                                                required maxlength="10" class="form-control form-control-sm"
                                                placeholder="1ro">
                                            <input [(ngModel)]="formRude.idioma_2" id="formRude_idioma_2" type="text"
                                                maxlength="10" class="form-control form-control-sm"
                                                placeholder="2do (opcional)">
                                            <input [(ngModel)]="formRude.idioma_3" id="formRude_idioma_3" type="text"
                                                maxlength="10" class="form-control form-control-sm"
                                                placeholder="3ro (opcional)">
                                        </div>

                                        <hr />
                                        <h6>
                                            ¿Pertenece a una nación, pueblo indígena originario campesino o
                                            afroboliviano?
                                        </h6>
                                        <div class="form-row align-items-center">
                                            <div class="col-md-6">
                                                <select [(ngModel)]="formRude.pertenencia"
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let p of selectValues.pueblos" [ngValue]="p.value">
                                                        {{ p.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>

                                        <h5 class="header-title mt-5">
                                            Salud del estudiante
                                        </h5>
                                        <div class="header-separator"></div>

                                        <h6>
                                            ¿Existe alg&uacute;n Centro de Salud/Posta/Hospital en su
                                            comunidad/barrio/zona?
                                        </h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="centroSalud"
                                                    [(ngModel)]="formRude.salud_centro" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="centroSalud"
                                                    [(ngModel)]="formRude.salud_centro" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>
                                            El año pasado, por problemas de salud, ¿acudió o se atendió
                                            en ...? (Puede marcar más de una opción)
                                        </h6>
                                        <div class="col-md-12">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_sus"
                                                    class="custom-control-input" id="formRude_salud_sus">
                                                <label class="custom-control-label" for="formRude_salud_sus">
                                                    1. Seguro Unico de salud.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_caja"
                                                    class="custom-control-input" id="formRude_salud_caja">
                                                <label class="custom-control-label" for="formRude_salud_caja">
                                                    2. Caja o seguro de salud.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_publico"
                                                    class="custom-control-input" id="formRude_salud_publico">
                                                <label class="custom-control-label" for="formRude_salud_publico">
                                                    3. Establecimientos de salud públicos.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_privado"
                                                    class="custom-control-input" id="formRude_salud_privado">
                                                <label class="custom-control-label" for="formRude_salud_privado">
                                                    4. Establecimientos de salud privados.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_vivienda"
                                                    class="custom-control-input" id="formRude_salud_vivienda">
                                                <label class="custom-control-label" for="formRude_salud_vivienda">
                                                    5. En su vivienda.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_tradicional"
                                                    class="custom-control-input" id="formRude_salud_tradicional">
                                                <label class="custom-control-label" for="formRude_salud_tradicional">
                                                    6. Medicina Tradicional.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.salud_automedicar"
                                                    id="formRude_salud_automedicar" class="custom-control-input">
                                                <label class="custom-control-label" for="formRude_salud_automedicar">
                                                    7. La farmacia sin receta médica (automedicación).
                                                </label>
                                            </div>
                                        </div>

                                        <hr />
                                        <h6>
                                            El año pasado ¿Cuántas veces fue al Centro de Salud?
                                        </h6>
                                        <div>Si respondió las opciones 1, 2 y.o 3 de la pregunta anterior.</div>
                                        <div class="form-row align-items-center">
                                            <div class="col-md-6">
                                                <select [(ngModel)]="formRude.salud_visitas" id="formRude_salud_visitas"
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let sv of selectValues.saludVisitas"
                                                        [ngValue]="sv.value">
                                                        {{sv.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>¿Tiene Seguro de Salud?</h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="tieneSeguro"
                                                    [(ngModel)]="formRude.salud_tiene_seguro" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="tieneSeguro"
                                                    [(ngModel)]="formRude.salud_tiene_seguro" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h5 class="header-title mt-3">
                                            Acceso del estudiante a servicios básicos.
                                        </h5>
                                        <div class="header-separator"></div>

                                        <h6>¿Tiene acceso a agua por ca&ntilde;er&iacute;a de red?</h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioAgua"
                                                    [(ngModel)]="formRude.servicio_agua" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioAgua"
                                                    [(ngModel)]="formRude.servicio_agua" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>¿Tiene ba&ntilde;o en su vivienda?</h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioBanno"
                                                    [(ngModel)]="formRude.servicio_banno" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioBanno"
                                                    [(ngModel)]="formRude.servicio_banno" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>¿Tiene red de alcantarillado?</h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioAlcantarilla"
                                                    [(ngModel)]="formRude.servicio_alcantarilla" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioAlcantarilla"
                                                    [(ngModel)]="formRude.servicio_alcantarilla" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>¿Usa energ&iacute;a el&eacute;ctrica para alumbrar su vivienda?</h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioElectricidad"
                                                    [(ngModel)]="formRude.servicio_electricidad" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioElectricidad"
                                                    [(ngModel)]="formRude.servicio_electricidad" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>¿Cuenta con servicio de recojo de basura?</h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioBasurero"
                                                    [(ngModel)]="formRude.servicio_basurero" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="servicioBasurero"
                                                    [(ngModel)]="formRude.servicio_basurero" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>Tipo de vivienda</h6>
                                        <div class="form-check form-check-inline">
                                            <select [(ngModel)]="formRude.tipo_vivienda" id="formRude_tipo_vivienda"
                                                class="form-control form-control-sm">
                                                <option *ngFor="let tv of selectValues.tiposVivienda"
                                                    [ngValue]="tv.value">
                                                    {{ tv.name }}</option>
                                            </select>
                                        </div>
                                        <hr />
                                        <h5 class="header-title mt-3">
                                            Acceso a internet del estudiante
                                        </h5>
                                        <div class="header-separator"></div>
                                        <h6>
                                            El estudiante accede a internet en (Puede marcar más de una opción)
                                        </h6>
                                        <div class="col-md-12">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.internet_casa"
                                                    class="custom-control-input" id="formRude_internet_casa">
                                                <label class="custom-control-label" for="formRude_internet_casa">
                                                    1. Su vivienda
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.internet_publico"
                                                    class="custom-control-input" id="formRude_internet_publico">
                                                <label class="custom-control-label" for="formRude_internet_publico">
                                                    2. Lugares Públicos
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.internet_no"
                                                    class="custom-control-input" id="formRude_internet_no">
                                                <label class="custom-control-label" for="formRude_internet_no">
                                                    3. No accede a internet.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.internet_ue"
                                                    class="custom-control-input" id="formRude_internet_ue">
                                                <label class="custom-control-label" for="formRude_internet_ue">
                                                    4. La Unidad Educativa.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.internet_celular"
                                                    class="custom-control-input" id="formRude_internet_celular">
                                                <label class="custom-control-label" for="formRude_internet_celular">
                                                    5. Teléfono Celular.
                                                </label>
                                            </div>
                                        </div>
                                        <hr />

                                        <h6>¿Con qué frecuencia usa internet?</h6>
                                        <div class="form-row align-items-center">
                                            <div class="col-md-5">
                                                <select [(ngModel)]="formRude.internet_frecuencia"
                                                    id="formRude_internet_frecuencia"
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let fi of selectValues.frecuenciaInternet"
                                                        [ngValue]="fi.value">{{ fi.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <h5 class="header-title mt-5">
                                            Medio de transporte hasta la Unidad Educativa.
                                        </h5>
                                        <div class="header-separator"></div>

                                        <h6>
                                            Generalmente, ¿Cómo llega el estudiante a la Unidad Educativa?
                                            (Coloque solo una opción)
                                        </h6>
                                        <div class="form-row align-items-center">
                                            <div class="col-md-5">
                                                <select [(ngModel)]="formRude.transporte_tipo"
                                                    id="formRude_transporte_tipo" class="form-control form-control-sm">
                                                    <option *ngFor="let tt of selectValues.tiposTransporte"
                                                        [ngValue]="tt.value">{{ tt.name }}</option>
                                                </select>
                                            </div>
                                            <div class="col-md-5">
                                                <input [(ngModel)]="formRude.transporte_otro"
                                                    id="formRude_transporte_otro" type="text"
                                                    class="form-control form-control-sm" placeholder="Otro" />
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>
                                            Según el medio de transporte señalado, ¿Cuál es el tiempo
                                            máximo que demora el estudiante desde su vivienda hasta la Unidad
                                            Educativa?
                                        </h6>
                                        <div class="form-row align-items-center">
                                            <div class="col-md-5">
                                                <select [(ngModel)]="formRude.transporte_tiempo"
                                                    id="formRude_transporte_tiempo"
                                                    class="form-control form-control-sm">
                                                    <option *ngFor="let tit of selectValues.tiemposTransporte"
                                                        [ngValue]="tit.value">{{ tit.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <h5 class="header-title mt-5">
                                            Abandono escolar correspondiente a la gestión anterior
                                        </h5>
                                        <div class="header-separator"></div>

                                        <h6>
                                            ¿El estudiante abandonó la Unidad Educativa el año pasado?
                                        </h6>
                                        <div class="form-check form-check-inline">
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="abandonoPasado"
                                                    [(ngModel)]="formRude.abandono_pasado" [value]="true">
                                                <label class="form-check-label">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="abandonoPasado"
                                                    [(ngModel)]="formRude.abandono_pasado" [value]="false">
                                                <label class="form-check-label">No</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <h6>
                                            ¿Cuál o cuáles fueron las razones de abandono escolar?
                                        </h6>
                                        <div class="col-md-12">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_ayudar"
                                                    class="custom-control-input" id="abandono_ayudar">
                                                <label class="custom-control-label" for="abandono_ayudar">
                                                    1. Tuvo que ayudar a sus padres en su trabajo.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_trabajo"
                                                    class="custom-control-input" id="formRude_abandono_trabajo">
                                                <label class="custom-control-label" for="formRude_abandono_trabajo">
                                                    2. Tuvo un trabajo remunerado.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_dinero"
                                                    class="custom-control-input" id="formRude_abandono_dinero">
                                                <label class="custom-control-label" for="formRude_abandono_dinero">
                                                    3. Falta de dinero.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_edad"
                                                    class="custom-control-input" id="formRude_abandono_edad">
                                                <label class="custom-control-label" for="formRude_abandono_edad">
                                                    4. Edad temprana (precocidad)/edad tardía (rezago)
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_distancia"
                                                    class="custom-control-input" id="formRude_abandono_distancia">
                                                <label class="custom-control-label" for="formRude_abandono_distancia">
                                                    5. La unidad educativa era distante.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_casa"
                                                    class="custom-control-input" id="formRude_abandono_casa">
                                                <label class="custom-control-label" for="formRude_abandono_casa">
                                                    6. Labores de casa/cuidado de niños(as).
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_embarazo"
                                                    class="custom-control-input" id="formRude_abandono_embarazo">
                                                <label class="custom-control-label" for="formRude_abandono_embarazo">
                                                    7. Embarazo o paternidad.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_medico"
                                                    class="custom-control-input" id="formRude_abandono_medico">
                                                <label class="custom-control-label" for="formRude_abandono_medico">
                                                    8. Por enfermedad/accidente/discapacidad.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_viaje"
                                                    class="custom-control-input" id="formRude_abandono_viaje">
                                                <label class="custom-control-label" for="formRude_abandono_viaje">
                                                    9. Viaje o traslado.
                                                </label>
                                            </div>
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_interes"
                                                    class="custom-control-input" id="formRude_abandono_interes">
                                                <label class="custom-control-label" for="formRude_abandono_interes">
                                                    10. Falta de interés.
                                                </label>
                                            </div>

                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" [(ngModel)]="formRude.abandono_bullying"
                                                    class="custom-control-input" id="formRude_abandono_bullying">
                                                <label class="custom-control-label" for="formRude_abandono_bullying">
                                                    11. Bullying o discriminación en la Unidad Educativa.
                                                </label>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="abandonoOtroInput">Otro (especifique)</label>
                                            <input [(ngModel)]="formRude.abandono_otro" id="formRude_abandono_otro"
                                                type="text" maxlength="40" class="form-control form-control-sm" />
                                        </div>

                                        <div class="form-group d-none">
                                            <label for="abandonoOtroInput">RUDE FECHA</label>
                                            <input [(ngModel)]="formRude.rude_fecha" id="formRude_rude_fecha"
                                                type="text" maxlength="40" class="form-control form-control-sm" />
                                        </div>

                                    </div>
                                    <!-- TAB ADICIONAL -->
                                    <div class="tab-pane fade" id="pills-adicional" role="tabpane"
                                        aria-labelledby="pills-adicional-tab">
                                        <!--div class="col-sm-12"-->
                                        <h5 class="header-title">SERVICIOS ADICIONALES</h5>
                                        <div class="col-sm-6">
                                            <ul class="list-group list-group-flush">
                                                <li *ngFor="let extra of config.extras" class="list-group-item">
                                                    <a href="{{extra.value}}"
                                                        class="btn btn-flat btn-outline-danger btn-red btn-lg btn-block text-right"
                                                        target="new">{{ extra.name }} &gt;&gt;</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 text-right pt-5">
                                    <button (click)="saveInscripcion()" type="button" id="guardarInscripcion"
                                        class="btn btn-flat btn-outline-danger btn-red mb-3 mt-3 pr-4 pl-4">
                                        <i class="fa fa-save"></i> &nbsp; Guardar &gt;</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>