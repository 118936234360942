import { ThisReceiver } from "@angular/compiler";

export class Busestudiante {
    gestion_id: number;
    zona_id: number;
    perfil_id: number;
    direccion_recojo: string;
    direccion_entrega: string;
    tipo: string;

    constructor(data: any = {}) {
        this.gestion_id = data.gestion_id;
        this.zona_id = data.zona_id;
        this.perfil_id = data.perfil_id;
        this.direccion_entrega = data.direccion_entrega;
        this.direccion_recojo = data.direccion_recojo;
        this.tipo = data.tipo;
    }
}
