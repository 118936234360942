<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <button (click)="goTo('/ebus')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                class="fa fa-arrow-left"></i> Atrás</button>
                            <div class="col-sm-12">
                                <div class="row">
                                    <h5 class="header-title">Buses - Zonas de cobertura</h5>                                    
                                </div>
                                <div class="header-separator"></div>
                                <h5 class="header-title">Estudiante: {{ estudiante.nombres }} {{
                                    estudiante.apellido_paterno }} {{ estudiante.apellido_materno }} </h5>
                                <div>
                                    Seleccione el tipo de servicio:
                                </div>
                                <div class="col-sm-6">

                                    <button class="btn" (click)="clickTipo('recojo')">
                                        <i *ngIf="tipo == 'recojo'" class="fa fa-check text-success"></i> Solo
                                        recojo <br />(Medio servicio)</button>&nbsp;
                                    <button class="btn" (click)="clickTipo('retorno')">
                                        <i *ngIf="tipo == 'retorno'" class="fa fa-check text-success"></i> Solo
                                        retorno <br />(Medio servicio)</button>&nbsp;
                                    <button class="btn" (click)="clickTipo('ambos')">
                                        <i *ngIf="tipo == 'ambos'" class="fa fa-check text-success"></i> Ambos
                                        <br />(Servicio completo)</button>
                                </div>
                                <div>
                                    Dirección recojo: <input id="direccion_recojo"
                                        class="form-control form-control-sm" />
                                </div>
                                <div>
                                    Dirección retorno: <input id="direccion_retorno"
                                        class="form-control form-control-sm" /><br />
                                </div>
                                <div class="header-separator"></div>
                                <h5 class="header-title">La zona podrá ser actualizada por el colegio</h5>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-table">
                                        <div class="table-responsive">
                                            <table class="table table-sm data-tables datatable-dark">
                                                <thead>
                                                    <tr>
                                                        <th>Zona</th>
                                                        <th>Recojo y retorno</th>
                                                        <th>Solo recojo o retorno</th>
                                                        <th>Mapa</th>
                                                        <th>Solicitar</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let zona of zonas">
                                                        <td>{{ zona.name }}</td>
                                                        <td>{{ zona.costo_completo }}</td>
                                                        <td>{{ zona.costo_medio }}</td>
                                                        <td>
                                                            <div id="{{ 'zona-' + zona.id }}"
                                                                style='display:block; width:150px; height:200px;'>
                                                                <img (click)="clickThumbnailPortada(zona.id)"
                                                                    class="thunbnailPortada"
                                                                    style='display:block; width:100%;'
                                                                    src='{{ zona.mapa }}' />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <button class="btn" (click)="clickZona(zona.id)">
                                                                <ng-container *ngIf="zonaId != zona.id">
                                                                    &nbsp;Seleccionar
                                                                </ng-container>
                                                                <ng-container *ngIf="zonaId == zona.id">
                                                                    <i
                                                                        class="fa fa-check text-success"></i>&nbsp;Seleccionado
                                                                </ng-container>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                <button id="grabar_button" (click)="clickGrabar()" type="button">Confirmar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>