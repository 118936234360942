<!--div class="submit-btn-area col-md-6" *ngFor="let encuesta of encuestas">
    <button id="encuesta_button" (click)="encuestaClick(encuesta.id )" type="button">{{ encuesta.name }} válido hasta {{
        encuesta.fecha_cierre }} <i class="ti-arrow-right"></i></button>
</div-->




<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <h5 class="header-title">Panel de estudiante</h5>
                                <div class="header-separator"></div>

                                <div *ngIf="isMenuAvailable('biblioteca')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="biblioteca_button" (click)="bibliotecaClick()" type="button">Libros
                                            disponibles <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('prestamolibros')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="prestamo_button" (click)="prestamosClick()" type="button">Libros que leí
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('boletin')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="boletin_button" (click)="boletinClick()" type="button">Boletin de
                                            Calificaciones <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('encuesta')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="encuesta_button" (click)="encuestaClick()" type="button">Encuestas<i
                                                class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('asignatura')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="asignatura_button" (click)="asignaturaClick()"
                                            type="button">Asignaturas<i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('evento')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="evento_button" (click)="eventoClick()" type="button">Actividades
                                            planificadas<i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>
