import { Component, OnInit } from '@angular/core';
import { Evento } from 'src/app/classes/evento';
import { EventoService } from 'src/app/services/cita.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cita',
  templateUrl: './cita.component.html',
  styleUrls: ['./cita.component.css']
})
export class CitaComponent implements OnInit {
  public citas: any[];
  public isLoading = false;
  private evento: Evento;

  constructor(
    private _eventoService: EventoService,
    private _router: Router
  ) {
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    this.playAudio('error.mp3');
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  ngOnInit(): void {
    this.loadCitas();
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  loadCitas(): void {
    console.log("loadCitas");
    let socio_id = localStorage.getItem("socio_id")
    this._eventoService.getCitas(socio_id).subscribe(
      response => {
        console.log(response);
        if (response.status === 200) {
          this.citas = response.body;
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  solicitarCita(evento_id: number): void {
    this.evento = new Evento();
    let socio_id = localStorage.getItem("socio_id")
    this.evento.solicitante_id = parseInt(socio_id);
    this.evento.estado = 'reservado';
    this.evento.id = evento_id;
    //let result = this._eventoService.updateEvento(this.evento);

    this._eventoService.updateEvento(this.evento).subscribe(
      response => {
        if (response.status == 200) {
          alert("La cita fue solicitada");
          this.showSuccess();
          this.loadCitas();
        }
        this.hideLoader();
      },
      error => {
        this.hideLoader();
        this.showError();
      }
    );
  }
}
