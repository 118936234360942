export class User {
    username: string;
    token: string;
    name: string;
    password: string;
    userID: number;
    mfaCode: string;
    constructor(username='', password=''){
        this.username = username;
        this.password = '';
    }
}
