import { ThisReceiver } from "@angular/compiler";

export class Evento {
    id: number;
    name: string;
    fecha_ini: string;
    fecha_fin: string;
    detalle: string; 
    docente_id: number;
    user_id: number;
    solicitante_id: number;
    tipo: string;
    estado: string;

    constructor(data: any = {}) {
        this.id = data.id;
        this.name = data.name;
        this.fecha_ini = data.fecha_ini;
        this.fecha_fin = data.fecha_fin;
        this.detalle = data.detalle;
        this.docente_id = data.docente_id;
        this.user_id = data.user_id;
        this.solicitante_id = data.solicitante_id;
        this.tipo = data.tipo;
        this.estado = data.estado;
    }
}