import { Component, OnInit } from '@angular/core';
import { PrestamoService } from 'src/app/services/prestamo.service';
import { RespuestaService } from 'src/app/services/respuesta.service';
import { Prestamo } from 'src/app/classes/prestamo';
import { Respuesta } from 'src/app/classes/respuesta';
import { Paginated } from 'src/app/classes/paginated';
import { exception } from 'console';
import { Router } from '@angular/router';


@Component({
  selector: 'app-prestamo',
  templateUrl: './prestamo.component.html',
  styleUrls: ['./prestamo.component.css']
})
export class PrestamoComponent implements OnInit {
  public show:boolean = false;
  public prestamos: any[];
  public isLoading: boolean;
  public libros: any[];
  public paginated: Paginated;
  public currentPage: Number;
  public fromApp: boolean;
  
  constructor(
    private _prestamoService: PrestamoService,
    private _router: Router

  ) { 
    this.paginated = new Paginated();
  }

  ngOnInit(): void {
    this.paginated.pageSize = 5;
    this.paginated.page = 1;
    this.fromApp = localStorage.getItem("fromApp") == "true";
    this.loadPrestamos(localStorage.getItem("socio_id"), this.paginated.page, this.paginated.pageSize, "fecha_devolucion");
  }

  loadPrestamos(criteria, page, pageSize, order): void {
    if (criteria===undefined) {
      criteria="";
    }
    this._prestamoService.getPrestamos(criteria, page, pageSize, order).subscribe(
      response => {
        console.log(response);
        if (response.status === 200) {
          this.prestamos = response.body;
          this.paginated.totalPages = response.totalPages;
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  searchPrestamo() {
    this.loadPrestamos(localStorage.getItem("socio_id"), this.paginated.page, this.paginated.pageSize, "fecha_devolucion");

  }

  previousPrestamo() {
    if (this.paginated.page>1) {
      this.paginated.page = this.paginated.page - 1;
      this.loadPrestamos(localStorage.getItem("socio_id"), this.paginated.page, this.paginated.pageSize, "fecha_devolucion");      
      
    }
  }

  nextPrestamo() {
    if (this.paginated.page < this.paginated.totalPages) {
      this.paginated.page = this.paginated.page + 1;
      this.loadPrestamos(localStorage.getItem("socio_id"), this.paginated.page, this.paginated.pageSize, "fecha_devolucion");      
    }
  }

  public onpageSizeSelectedPrestamo(event) {
    const value = event.target.value;
    this.paginated.pageSize = value;
    this.loadPrestamos(localStorage.getItem("socio_id"), 1, this.paginated.pageSize, "fecha_devolucion");      
  }

  currentDate() {
    let result = new Date();
    console.log("currentDate ");
    console.log(result);
    return result;
  }

  convertDateToString(dateString:string) {
    let curr = this.currentDate();
    let result;
    try {
      let newDate = new Date(dateString);
      result = newDate;      
    } catch (exception) {      
    }
    return result;
  }

  plazoVencido(plazo:string, state:string) {
    let curr = this.currentDate();
    let result = false;
    try {
      if (plazo.length>0 && state=='ent') {
        let plazoDate = this.convertDateToString(plazo);
        result = curr > plazoDate;
      }
    } catch (exception) {      
    }
    return result;
  }

  formatDatetime(datetime:string) {
    if(datetime == null){
      return '';
    }
    let result = '';
    try{
      const dt_split = datetime.split(' ');
      const dt = dt_split[0].split('-');
      result = '<strong>'+dt[2]+'/'+dt[1]+'/'+dt[0] + '</strong>';
    }
    catch(exception){
    }
    return result;
  }
}
