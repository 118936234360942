<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">

        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button *ngIf="!fromApp" (click)="goTo('/panelsocio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                    class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <h5 class="header-title">Elecciones</h5>
                                <div class="header-separator"></div>
                            </div>

                            <div class="row">
                                <div class="element3 col-md-12">
                                    <div class="table-responsive">
                                        <div>
                                            <!-- <span *ngIf="elecciones?.recibos_pendientes?.length > 0">Tiene deudas pendientes, por favor pase por oficinas del Colegio para regularizarlas y habilitarse para esta elección. Gracias</span> -->
                                        </div>

                                        <div> <!-- <div *ngIf="elecciones?.recibos_pendientes?.length == 0"> -->
                                            <table class="table table-sm data-tables datatable-dark">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <div *ngIf="elecciones.length > 0">
                                                                <ng-container *ngFor="let eleccion of elecciones">
                                                                    <span>{{ eleccion.name }}</span>
                                                                </ng-container>
                                                            </div>
                                                            <div *ngIf="elecciones.length == 0">
                                                                <span>
                                                                    No existen Elecciones habilitadas en este momento o la eleccion vigente ya se cerró.
                                                                </span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container *ngIf="noVota">
                                                        <tr>
                                                            <td>
                                                                <span>No está habilitado para votar, por favor consulte con Administración del Colegio.</span><br/>
                                                                <ng-container *ngIf="votosocio.length == 0">
                                                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                                                        <button (click)="votarInhabilitado()" type="button" id = "inhabilitadoButton">Registrar que se presentó</button>
                                                                    </div>
                                                                </ng-container>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                    <ng-container *ngIf="!noVota">
                                                        <ng-container *ngFor="let consejo of consejos">
                                                            <ng-container *ngIf="!votoFound(consejo.id)">
                                                                <tr>
                                                                    <td>
                                                                        <span>Consejo de {{ consejo.name }}</span>
                                                                        <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                                                            <button (click)="votar(consejo.id, consejo.name)" type="button">Votar</button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                            <ng-container *ngIf="votoFound(consejo.id)">
                                                                <tr>
                                                                    <td>
                                                                        <span>Consejo de {{ consejo.name }} - Voto realizado</span>
                                                                    </td>
                                                                </tr>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>