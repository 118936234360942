import { Component, OnInit } from '@angular/core';
import { EstudianteService } from 'src/app/services/estudiante.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-estudiante',
  templateUrl: './estudiante.component.html',
  styleUrls: ['./estudiante.component.css']
})

export class EstudianteComponent implements OnInit {
  token: string = null;
  puserid: string = null;
  ptipo_perfil: string = null;
  params: any;
  pruta: string = null;
  papp: string = null;
  public configpanels: any[];
  public isLoading = false;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _configService: ConfigurationService,
    private _estudianteService: EstudianteService) {
  }

  ngOnInit(): void {
    let composed = this.route.snapshot.paramMap.get("app");
    if (composed !== null) {
      //let parts = composed.toString().split("-");
      if (!(localStorage.getItem("username") === null)) {
        console.log(localStorage.getItem("ruta"));
        if (localStorage.getItem("ruta") == 'biblioteca') {
          this.bibliotecaClick();
        } else if (localStorage.getItem("ruta") == 'prestamo') {
          this.prestamosClick();
        }
      }
    }
    this.loadConfig();
  }

  hideLoader() {
    this.isLoading = false;
  }

  loadConfig(): void {
    
    this._configService.getPanelConfig("estudiante").subscribe(
      response => {

        if (response.status === 200) {
          console.log(response.body);
          this.configpanels = response.body;
          //console.log("boletin "+this.isValueInArray("boletin", this.config));
        } else {
          //console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  isMenuAvailable(value:string) {
    let exists = false;
    
    for(let i=0; i < this.configpanels.length; i++){
      if (this.configpanels[i].value == value) {
        exists = true;
      } 
    }
    return exists;
    
  }

  bibliotecaClick() {
    this.router.navigate(['biblioteca']);
  }

  prestamosClick() {
    this.router.navigate(['prestamo']);
  }

  boletinClick() {
    this.router.navigate(['boletin']);
  }

  encuestaClick() {
    this.router.navigate(['encuesta']);
  }

  asignaturaClick() {
    this.router.navigate(['asignatura']);
  }

  eventoClick() {
    this.router.navigate(['evento']);
  }
}
