import { Component, OnInit } from '@angular/core';
import { IpServiceService } from '../../services/ip-service.service'; 

import { ActivatedRoute, Router } from '@angular/router';
import { Voto } from 'src/app/classes/voto';
import { EleccionService } from 'src/app/services/eleccion.service';

@Component({
  selector: 'app-eleccion',
  templateUrl: './eleccion.component.html',
  styleUrls: ['./eleccion.component.css']
})

export class EleccionComponent implements OnInit {
  public puestos: any[];
  public planchas: any[];
  public candidatos: any[];
  public isLoading = false;
  public votos = new Array();
  private saveButtonPressed = false;
  public ipAddress: string;
  private navigator: string;
  
  // public dialog = new MDCDialog(document.querySelector('.mdc-dialog'));
  // public list = new MDCList(document.querySelector('.mdc-dialog .mdc-list'));

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _eleccionService: EleccionService,
    private ip: IpServiceService) {
  }

  ngOnInit(): void {
    this.loadCandidatos();
    this.getIP();
  }

  getIP() {
    this.navigator = window.navigator.userAgent;
    this.ip.getIPAddress().subscribe((res: any)=>{
      this.ipAddress = "IP " + res.ip + " " + this.navigator;
    });
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  loadCandidatos(): void {
    let eleccion_id = localStorage.getItem('eleccion_id');
    let consejo_id = localStorage.getItem('consejo_id');

    this._eleccionService.getCandidatos(eleccion_id, consejo_id).subscribe(
      response => {
        if (response.status === 200) {
          this.planchas = response.body.planchas;
          this.puestos = response.body.puestos;
          
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  compararPlanchaPuesto(cpuesto_id, cplancha, ppuesto_id, pplancha) {

    return (cpuesto_id == ppuesto_id && cplancha == pplancha)
  }

  candidatoVacio(candidato): boolean {
    //let cand = candidato + "";
    if (candidato !== null) {
      return false;
    }
    return true;
  }

  switchVoto(pcandidato) {
    let counter = 0;
    let maximo = 0;
    this.puestos.forEach((puesto) => {
      if (pcandidato.puesto_id == puesto.id) {
        maximo = puesto.maximo;
        puesto.planchas.forEach((plancha) => {
          plancha.candidatos.forEach((candidato) => {
            if (candidato.voto) {
              counter++;
            }
          });
        });
      }
    });
    
    let newValue = !pcandidato.voto;
    if (newValue) {
      this.puestos.forEach((puesto) => {
        if (pcandidato.puesto_id == puesto.id) {
          maximo = puesto.maximo;
          puesto.planchas.forEach((plancha) => {
            plancha.candidatos.forEach((candidato) => {
              if (maximo == 1) {
                if (candidato.candidato_id != pcandidato.candidato_id) {
                  candidato.voto = false;
                } else {
                  candidato.voto = true;
                }                
              } else { //more than 1 votes allowed
                if (counter < maximo) {
                  if (candidato.candidato_id == pcandidato.candidato_id) {
                    candidato.voto = true;
                  }
                }
              }
            });
          });
        }
      });
    } else { //only uncheck any candidate
      this.puestos.forEach((puesto) => {
        if (pcandidato.puesto_id == puesto.id) {
          puesto.planchas.forEach((plancha) => {
            plancha.candidatos.forEach((candidato) => {
              if (candidato.candidato_id == pcandidato.candidato_id) {
                candidato.voto = false;
              }
            });
          });
        }
      });
    }
  }

  switchVoto1(candidato) {
    let newValue = false;
    let error = false;
    var avisos = document.getElementById("avisos");

    this.candidatos.forEach((can) => {
      if (can.socio_id == candidato.socio_id) {
        newValue = !can.voto;
      }
    });

    if (newValue) { //check only if new Check is selected

      let contador = new Array();
      let maximos = new Array();
      this.puestos.forEach((pue) => {
        contador[pue.name] = 0;
        maximos[pue.name] = pue.maximo;
      });
      this.puestos.forEach((pue) => {
        this.candidatos.forEach((can) => {
          if (can.name == pue.name) {
            if (can.voto) {
              contador[pue.name]++;
            }
          }
        });
      });

      this.puestos.forEach((pue) => {
        if (pue.name == candidato.name) {
          if (contador[pue.name] >= maximos[pue.name]) {
            avisos.innerHTML = 'El puesto de ' + pue.name + ' admite solo ' + maximos[pue.name] + ' voto(s). Desmarque uno para marcar este.';
            error = true;
          }
        }
      });
    }

    //if (!error) {
      this.candidatos.forEach((can) => {
        if ((can.puesto_id == candidato.puesto_id && parseInt(can.maximo) == 1)) {
          can.voto = false;
        }

        if ((can.socio_id == candidato.socio_id)) {
          can.voto = !can.voto;
          avisos.innerHTML = '';
          error = false;
        }
      });
    //}
  }


  grabarVoto0(blanco) {
    if (!this.saveButtonPressed) {
      this.saveButtonPressed = true;

      this.candidatos.forEach((can) => {
        if (can.voto) {
          let voto = new Voto();
          voto.candidato_id = can.candidato_id;
          voto.eleccion_id = parseInt(localStorage.getItem("eleccion_id"));
          voto.puesto_id = can.puesto_id;
          voto.socio_id = parseInt(localStorage.getItem("socio_id"));
          this.votos.push(voto);
        }
      });
      let votosocio = new Array();
      votosocio["socio_id"] = localStorage.getItem("socio_id");
      votosocio["eleccion_id"] = localStorage.getItem("eleccion_id");
      votosocio["consejo_id"] = localStorage.getItem("consejo_id");
      votosocio["dispositivo"] = this.ipAddress;
      
      let payload = {};
      if (blanco) {
        payload = {
          "socio": {
            "socio_id": localStorage.getItem("socio_id"),
            "eleccion_id": localStorage.getItem("eleccion_id"),
            "consejo_id": localStorage.getItem("consejo_id")
          },
          "votos": []
        };
      } else {
        payload = {
          "socio": {
            "socio_id": localStorage.getItem("socio_id"),
            "eleccion_id": localStorage.getItem("eleccion_id"),
            "consejo_id": localStorage.getItem("consejo_id")
          },
          "votos": this.votos
        };
      }
      
      this._eleccionService.updateData(payload).subscribe(
        response => {
          if (response.status == 200) {
            if (response.message != 'Su voto fue registrado, gracias por votar.') {
              alert(response.message);
            }
            this.showSuccess();
            this.goTo("paneleleccion");
          }
          this.hideLoader();
        },
        error => {
          this.hideLoader();
          this.showError();
        }
      );
    }
  }

  pad(num, size) {
    num = num.toString();
    while (num.length < size) num = "0" + num;
    return num;
}
  formatDate(date) {
    var year = date.getFullYear(),
        month = date.getMonth() + 1, // months are zero indexed
        day = date.getDate(),
        hour = date.getHours(),
        minute = date.getMinutes(),
        second = date.getSeconds(),
        hourFormatted = hour % 12 || 12, // hour returned in 24 hour format
        minuteFormatted = minute < 10 ? "0" + minute : minute,
        morning = hour < 12 ? "am" : "pm";

    return year + "/" + this.pad(month, 2) + "/" + this.pad(day, 2) + " " + this.pad(hourFormatted, 2) + ":" +
    this.pad(minuteFormatted, 2) + ":" + this.pad(second, 2);
  }

  grabarVoto(blanco) {
    
    let message = "Una vez grabado, no podrá corregir su voto. Por favor confirme que desea registrar su voto";
    if (blanco) {
      message = "Su voto será registrado como Blanco, no podrá corregir su voto. Por favor confirme que desea registrar su voto";
    }
    
    if(confirm(message)) {
      this.votos = new Array();
      if (!this.saveButtonPressed) {
        this.saveButtonPressed = true;

        this.puestos.forEach((puesto) => {
            puesto.planchas.forEach((plancha) => {
              plancha.candidatos.forEach((candidato) => {
                if (candidato.voto) {
                  let voto = new Voto();
                  voto.candidato_id = candidato.candidato_id;
                  voto.eleccion_id = parseInt(localStorage.getItem("eleccion_id"));
                  voto.puesto_id = candidato.puesto_id;
                  voto.socio_id = parseInt(localStorage.getItem("socio_id"));
                  this.votos.push(voto);
                }
              });
            });
        });

        let date = new Date();
        let votosocio = new Array();
        votosocio["socio_id"] = localStorage.getItem("socio_id");
        votosocio["eleccion_id"] = localStorage.getItem("eleccion_id");
        votosocio["consejo_id"] = localStorage.getItem("consejo_id");
        votosocio["fecha_voto"] = this.formatDate(date);
        votosocio["dispositivo"] = this.ipAddress;

        let payload = {};
        if (blanco) {
          payload = {
            "socio": {
              "socio_id": localStorage.getItem("socio_id"),
              "eleccion_id": localStorage.getItem("eleccion_id"),
              "consejo_id": localStorage.getItem("consejo_id"),
              "dispositivo": this.ipAddress,
              "blanco": "t"
            },
            "votos": []
          };
        } else {
          if (this.votos.length == 0) {
            alert("Debe escoger por lo menos un candidato, o presionar el botón 'Votar Blanco'.");
            this.saveButtonPressed = false;
            return false;
          }
          payload = {
            "socio": {
              "socio_id": localStorage.getItem("socio_id"),
              "eleccion_id": localStorage.getItem("eleccion_id"),
              "consejo_id": localStorage.getItem("consejo_id"),
              "dispositivo": this.ipAddress,
              "blanco": "f"
            },
            "votos": this.votos
          };
        }
        
        this._eleccionService.updateData(payload).subscribe(
          response => {
            if (response.status == 200) {
              //this.prestamo = new Prestamo(response.body);
              if (response.message != 'Su voto fue registrado, gracias por votar.') {
                alert(response.message);
              }
              this.showSuccess();
              this.goTo("paneleleccion");
            }
            this.hideLoader();
          },
          error => {
            this.hideLoader();
            this.showError();
          }
        );
      }
    }
  }

}
