import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { ClaseReporte } from '../classes/clase_reporte';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class ClaseReporteService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    // this.SERVER_URL = 'http://localhost/froebel-webservice/dist/socio/';
    this.SERVER_URL = URLConstants.backend_url + 'estudiante/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  updateData(claseReporte: ClaseReporte) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: claseReporte }, { headers: this.httpHeaders });
  }

  getClaseReporte(socioId) {
    return this._http.get<PHPResponse>(this.SERVER_URL + 'get_clase_reporte.php', {
      headers: this.httpHeaders,
      params: {
        'socio_id': socioId
      }
    });
  }

}
