<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>
<div class="pop">
    <div class="success_pop">
        <i class="fa fa-thumbs-o-up" style="font-size: 80px; color:rgb(27, 146, 11)"></i>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-6 mt-5">
                    <div class="card bg-glass">
                        <div class="card-body">
                            <div class="col-lg-12 pt-3 bg-white">
                                <button (click)="goTo('/panelsocio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                    class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <div id="accordion2" class="according accordion-s2">
                                    <div class="card">
                                        <div class="card-header">
                                            <a class="card-link" data-toggle="collapse" aria-expanded="true" href="#accordion21">
                                                Datos de perfil
                                            </a>
                                        </div>
                                        <div id="accordion21" class="collapse show" data-parent="#accordion2">
                                            <div class="card-body">
                                                <ul class="nav nav-tabs mb-3" id="pills-tab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active show" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Socio</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Padre/Madre</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="pills-tabContent">
                                                    <div class="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                                        <div class="col-sm-12">
                                                            <div class="col-sm-8" id="errorsSocio" style="display:none;">
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div *ngIf="selectValues.editables==false" class="col-sm-6">
                                                                    <label>Nombres y apellidos</label>
                                                                    <div class="input-readonly">
                                                                        {{ socio.nombres }} &nbsp; {{ socio.apellido_paterno }} &nbsp; {{ socio.apellido_materno }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Nombres</label>
                                                                    <input [(ngModel)]="socio.nombres" required id="socio_nombres" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label> Ap. Paterno</label>
                                                                    <input [(ngModel)]="socio.apellido_paterno" required id="socio_apellido_paterno" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" placeholder="Apellido paterno" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label> Ap. Materno</label>
                                                                    <input [(ngModel)]="socio.apellido_materno" required id="socio_apellido_materno" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" placeholder="Apellido materno" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Carnet de Identidad</label>
                                                                    <input [(ngModel)]="socio.documento" required id="socio_documento" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Complemento</label>
                                                                    <input [(ngModel)]="socio.documento_complemento" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                                <div *ngIf="selectValues.editables==true" class="col-sm-5">
                                                                    <label>Expedido</label>
                                                                    <select [(ngModel)]="socio.documento_expedido" id="socio_documento_expedido" required class="form-control form-control-sm">
                                                                    <option
                                                                        *ngFor="let scitie of selectValues.ciudades"
                                                                        [ngValue]="scitie.value">{{ scitie.name }}
                                                                    </option>
                                                                </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div *ngIf="selectValues.editables==false" class="col-sm-5">
                                                                    <label>Fecha de nacimiento</label>
                                                                    <div class="input-readonly">
                                                                        {{ socio.fecha_nacimiento }} &nbsp;
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="selectValues.editables==true" class="col-sm-5">
                                                                    <label>Fecha de nacimiento</label>
                                                                    <input type="date" required class="form-control form-control-sm required" [(ngModel)]="socio.fecha_nacimiento" required id="socio_fecha_nacimiento" />
                                                                </div>
                                                                <div *ngIf="selectValues.editables==false" class="col-sm-5">
                                                                    <label for="sexoSocioInput">Sexo</label>
                                                                    <div class="input-readonly">{{ getNameValue(selectValues.sexos, socio.sexo) }} &nbsp;</div>
                                                                </div>
                                                                <div *ngIf="selectValues.editables==true" class="col-sm-5">
                                                                    <label for="sexoSocioInput">Sexo</label>
                                                                    <select [(ngModel)]="socio.sexo" required class="form-control form-control-sm">
                                                                    <option *ngFor="let ss of selectValues.sexos"
                                                                        [ngValue]="ss.value">{{ ss.name }}</option>
                                                                </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Departamento</label>
                                                                    <input [(ngModel)]="socio.departamento" required id="socio_departamento" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Provincia</label>
                                                                    <input [(ngModel)]="socio.provincia" required id="socio_provincia" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Sección/Municipio</label>
                                                                    <input [(ngModel)]="socio.municipio" required id="socio_municipio" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Localidad/Comunidad</label>
                                                                    <input [(ngModel)]="socio.localidad" required id="socio_localidad" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Zona/Villa</label>
                                                                    <input [(ngModel)]="socio.zona" required id="socio_zona" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-7">
                                                                    <label>Avenida/Calle</label>
                                                                    <input [(ngModel)]="socio.avenida" required id="socio_avenida" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-3">
                                                                    <label>Nro. vivienda</label>
                                                                    <input [(ngModel)]="socio.nro_vivienda" required id="socio_nro_vivienda" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Punto de referencia</label>
                                                                    <input [(ngModel)]="socio.direccion_ref" required id="socio_direccion_ref" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center mt-3">
                                                                <div class="col-sm-10">
                                                                    <label for="socio_email">Email</label>
                                                                    <input [(ngModel)]="socio.email" required id="socio_email" class="form-control form-control-sm" type="email" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Telf. Fijo</label>
                                                                    <input [(ngModel)]="socio.telf_fijo" required id="socio_telf_fijo" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Telf. Móvil</label>
                                                                    <input [(ngModel)]="socio.telf_movil" id="socio_telf_movil" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>
                                                                    Idioma que habla frecuentemente
                                                                </label>
                                                                    <input [(ngModel)]="socio.idioma" required id="socio_idioma" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label for="instruccionSocioInput">
                                                                    Mayor grado de instrucción alcanzado
                                                                </label>
                                                                    <input [(ngModel)]="socio.instruccion" required id="socio_instruccion" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>





                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-6" style="display:none;">
                                                                    <label>Relación con estudiante</label>
                                                                    <div class="input-readonly">
                                                                        {{ getNameValue(selectValues.tiposRelacion, socio.tipo_relacion) }} &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==false" class="form-row align-items-center">
                                                                <div class="col-sm-8">
                                                                    <div class="input-readonly">
                                                                        {{ socio.documento }}&nbsp; {{ socio.documento_complemento}} &nbsp; {{ getNameValue(selectValues.ciudades, socio.documento_expedido) }}
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <h5 class="header-title mt-5">Datos de facturación</h5>
                                                            <div class="header-separator"></div>
                                                            <h6 class="mt-3">Datos facturacion</h6>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-6">
                                                                    <label>NIT/CI</label>
                                                                    <input type="text" [(ngModel)]="socio.nit_ci" id="socio_nit_ci" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-6">
                                                                    <label>Razón social</label>
                                                                    <input type="text" [(ngModel)]="socio.razon_social" id="socio_razon_social" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>




                                                            <h5 class="header-title mt-5">datos laborales</h5>
                                                            <div class="header-separator"></div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Ocupación laboral actual</label>
                                                                    <input [(ngModel)]="socio.trabajo_actual" required id="socio_trabajo_actual" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Nombre de empresa en que trabaja</label>
                                                                    <input [(ngModel)]="socio.trabajo_empresa" id="socio_trabajo_empresa" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Email laboral</label>
                                                                    <input [(ngModel)]="socio.trabajo_email" id="socio_trabajo_email" class="form-control form-control-sm" type="email" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Telf. laboral</label>
                                                                    <input [(ngModel)]="socio.trabajo_telf" id="socio_trabajo_telf" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Telf. móvil laboral</label>
                                                                    <input [(ngModel)]="socio.trabajo_movil" id="socio_trabajo_movil" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-12">
                                                                    <label>Dirección laboral</label>
                                                                    <input [(ngModel)]="socio.trabajo_direccion" id="socio_trabajo_direccion" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>


                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-12">
                                                                    <button (click)="saveSocio()" type="button" id="guardarSocio" class="btn btn-flat btn-outline-danger btn-red mb-3 mt-3 pr-4 pl-4 pull-right">
                                                                        <i class="fa fa-save"></i> &nbsp;
                                                                        Guardar datos de Socio
                                                                        &gt;</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                                        <div class="col-sm-12">

                                                            <div class="col-sm-8" id="errorsNoSocio" style="display:none;">
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                No registraré datos del otro progenitor
                                                                <input type="checkbox" (change)="setnosocioblank()" [(ngModel)]="noSocioBlank">
                                                            </div>
                                                            <div *ngIf="selectValues.editables==false" class="form-row align-items-center">
                                                                <div class="col-sm-6">
                                                                    <label>Nombres y apellidos</label>
                                                                    <div class="input-readonly">
                                                                        {{ nosocio.nombres }} {{ nosocio.apellido_paterno }} &nbsp; {{ nosocio.apellido_materno }} &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Nombres</label>
                                                                    <input [(ngModel)]="nosocio.nombres" required id="nosocio_nombres" oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Apellido paterno</label>
                                                                    <input [(ngModel)]="nosocio.apellido_paterno" id="nosocio_apellido_paterno" required oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" placeholder="Apellido paterno" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Apellido materno</label>
                                                                    <input [(ngModel)]="nosocio.apellido_materno" id="nosocio_apellido_materno" required oninput="this.value = this.value.toUpperCase()" class="form-control form-control-sm" placeholder="Apellido materno" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-4">
                                                                    <label for="parentescoInput">Relación con
                                                                        estudiante</label>
                                                                    <div class="input-readonly">
                                                                        {{ getNameValue(selectValues.tiposRelacion, nosocio.tipo_relacion) }} &nbsp;
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==false" class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>
                                                                        Carnet de Identidad
                                                                    </label>
                                                                    <div class="input-readonly">
                                                                        {{ nosocio.documento }}&nbsp; {{ nosocio.documento_complemento }} &nbsp; {{ getNameValue(selectValues.ciudades, nosocio.documento_expedido) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Carnet de Identidad</label>
                                                                    <input [(ngModel)]="nosocio.documento" required id="nosocio_documento" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="selectValues.editables==true" class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>complemento</label>
                                                                    <input [(ngModel)]="nosocio.documento_complemento" id="nosocio_documento_complemento" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Expedido</label>
                                                                    <select [(ngModel)]="nosocio.documento_expedido" id="nosocio_documento_expedido" required class="form-control form-control-sm">
                                                                        <option
                                                                            *ngFor="let scitie of selectValues.ciudades"
                                                                            [ngValue]="scitie.value">{{ scitie.name }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div *ngIf="selectValues.editables==false" class="col-sm-5">
                                                                    <label>Fecha de nacimiento</label>
                                                                    <div class="input-readonly">
                                                                        {{ nosocio.fecha_nacimiento }} &nbsp;
                                                                    </div>
                                                                </div>
                                                                <div *ngIf="selectValues.editables==true" class="col-sm-5">
                                                                    <label>Fecha de nacimiento</label>
                                                                    <input [(ngModel)]="nosocio.fecha_nacimiento" id="nosocio_fecha_nacimiento" required type="date" class="form-control form-control-sm" />
                                                                </div>
                                                                <div *ngIf="selectValues.editables==false" class="col-sm-5">
                                                                    <label>Sexo</label>
                                                                    <div class="input-readonly">{{ getNameValue(selectValues.sexos, nosocio.sexo) }} &nbsp;</div>
                                                                </div>
                                                                <div *ngIf="selectValues.editables==true" class="col-sm-5">
                                                                    <label>Sexo</label>
                                                                    <select [(ngModel)]="nosocio.sexo" required id="nosocio_sexo" class="form-control form-control-sm">
                                                                        <option *ngFor="let nss of selectValues.sexos"
                                                                            [ngValue]="nss.value">{{ nss.name }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Departamento</label>
                                                                    <input [(ngModel)]="nosocio.departamento" required id="nosocio_departamento" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label> Provincia</label>
                                                                    <input [(ngModel)]="nosocio.provincia" required id="nosocio_provincia" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Sección / Municipio </label>
                                                                    <input [(ngModel)]="nosocio.municipio" required id="nosocio_municipio" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Localidad / Comunidad</label>
                                                                    <input [(ngModel)]="nosocio.localidad" required id="nosocio_localidad" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Zona / Villa</label>
                                                                    <input [(ngModel)]="nosocio.zona" required id="nosocio_zona" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-7">
                                                                    <label>Avenida / Calle</label>
                                                                    <input [(ngModel)]="nosocio.avenida" required id="nosocio_avenida" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-3">
                                                                    <label>Nro. vivienda</label>
                                                                    <input [(ngModel)]="nosocio.nro_vivienda" required id="nosocio_nro_vivienda" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Punto de referencia</label>
                                                                    <input [(ngModel)]="nosocio.direccion_ref" required id="nosocio_direccion_ref" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center mt-3">
                                                                <div class="col-sm-10">
                                                                    <label>Email</label>
                                                                    <input [(ngModel)]="nosocio.email" required id="nosocio_email" class="form-control form-control-sm" type="email" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Telf. Fijo</label>
                                                                    <input [(ngModel)]="nosocio.telf_fijo" required id="nosocio_telf_fijo" class="form-control form-control-sm" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Telf. Móvil</label>
                                                                    <input [(ngModel)]="nosocio.telf_movil" id="nosocio_telf_movil" class="form-control form-control-sm" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Idioma que habla frecuentemente</label>
                                                                    <input [(ngModel)]="nosocio.idioma" required id="nosocio_idioma" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Mayor grado de instrucción alcanzado</label>
                                                                    <input [(ngModel)]="nosocio.instruccion" required id="nosocio_instruccion" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>

                                                            <h5 class="header-title mt-5">Datos laborales</h5>
                                                            <div class="header-separator"></div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Ocupación laboral actual</label>
                                                                    <input [(ngModel)]="nosocio.trabajo_actual" required id="nosocio_trabajo_actual" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Nombre de empresa en que trabaja</label>
                                                                    <input [(ngModel)]="nosocio.trabajo_empresa" id="nosocio_trabajo_empresa" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>

                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-10">
                                                                    <label>Email laboral</label>
                                                                    <input [(ngModel)]="nosocio.trabajo_email" id="nosocio_trabajo_email" class="form-control form-control-sm" type="email" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-5">
                                                                    <label>Telf. laboral</label>
                                                                    <input [(ngModel)]="nosocio.trabajo_telf" id="nosocio_trabajo_telf" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                                <div class="col-sm-5">
                                                                    <label>Telf. móvil laboral</label>
                                                                    <input [(ngModel)]="nosocio.trabajo_movil" id="nosocio_trabajo_movil" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-12">
                                                                    <label>Dirección laboral</label>
                                                                    <input [(ngModel)]="nosocio.trabajo_direccion" id="nosocio_trabajo_direccion" class="form-control form-control-sm" type="text" />
                                                                </div>
                                                            </div>
                                                            <div class="form-row align-items-center">
                                                                <div class="col-sm-12">
                                                                    <button (click)="saveNoSocio()" type="button" id="guardarNoSocio" class="btn btn-flat btn-outline-danger btn-red mb-3 mt-3 pr-4 pl-4 pull-right">
                                                                        <i class="fa fa-save"></i> &nbsp;
                                                                        Guardar datos de Padre/Madre
                                                                        &gt;</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Modal -->
                <div class="modal fade" id="facturacion-popup">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">-</h5>
                                <button type="button" class="close" data-dismiss="modal"><span>&times;</span></button>
                            </div>
                            <div class="modal-body">
                                <p class="text-danger">
                                    <strong><i class="fa fa-icon fa-warning"></i> Atención:</strong> Los datos personales registrados son de suma importancia, ya que se emplearán tanto para comunicación con los usuarios como para la emisión de las Facturas
                                    correspondientes a pensiones y otros servicios.
                                </p>
                                <div class="form-row align-items-left">
                                    <div class="col-sm-8">
                                        <label>Correo electrónico</label>
                                        <input id="popupemail" [(ngModel)]="popup.email" [readOnly]="!editPopup" class="form-control form-control-sm" type="email" />
                                    </div>
                                    <div class="col-sm-8">
                                        <label>Teléfono</label>
                                        <input id="popuptelefono" [(ngModel)]="popup.telf_fijo" [readOnly]="!editPopup" class="form-control form-control-sm" type="email" />
                                    </div>
                                    <div class="col-sm-8">
                                        <label>Razón social</label>
                                        <input id="popuprs" [(ngModel)]="popup.razon_social" [readOnly]="!editPopup" class="form-control form-control-sm" type="email" />
                                    </div>
                                    <div class="col-sm-8">
                                        <label>NIT</label>
                                        <input id="popupnit" [(ngModel)]="popup.nit_ci" [readOnly]="!editPopup" class="form-control form-control-sm" type="email" />
                                    </div>
                                </div>

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-warning" (click)="editModal()" *ngIf="!editPopup">Editar datos</button>
                                <button type="button" class="btn btn-danger" (click)="restoreModal()" *ngIf="editPopup">Cancelar</button>
                                <button type="button" class="btn btn-primary" (click)="saveModal()" *ngIf="editPopup">Guardar datos</button>
                                <button type="button" class="btn btn-primary" (click)="finishModal()" *ngIf="!editPopup">Aceptar y continuar</button>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 mt-5">
                    <div class="card bg-glass">
                        <div class="card-body">
                            <div class="col-sm-12 bg-white">
                                <h5 class="header-title pt-3">Estudiantes registrados</h5>
                                <button type="button" class="btn btn-primary btn-flat btn-lg mt-3" hidden id="popup-button" data-toggle="modal" data-target="#facturacion-popup">Launch demo modal</button>
                                <div class="header-separator"></div>

                                <div *ngFor="let estudiante of estudiantes" class="form-row align-items-center like-row">
                                    <div class="col-sm-6">
                                        <strong>
                                            {{ estudiante.nombres }}
                                            {{ estudiante.apellido_paterno }}
                                            {{ estudiante.apellido_materno }}
                                            {{ estudiante.nombres }}
                                        </strong>
                                        <br />
                                        <span *ngIf="estudiante.curso" class="text-secondary">
                                            {{ estudiante.curso }}
                                        </span>
                                        <br />
                                        <span *ngIf="estudiante.curso" class="text-secondary">
                                            Email {{ estudiante.email }}
                                            <br />
                                            Contraseña: {{ estudiante.email_password }}
                                        </span>

                                    </div>
                                    <div class="col-sm-6">
                                        <button type="button" id="inscribir" *ngIf="estudiante.estado_rude!='cerrado'" (click)="inscribirEstudiante(estudiante)" class="btn btn-xs btn-flat btn-light">
                                            <i class="fa fa-pencil-square-o"></i>&nbsp;INSCRIBIR
                                        </button>
                                        <button *ngIf="isMenuAvailable('rude')" (click)="openRude(estudiante)" class="btn btn-xs btn-flat btn-light">
                                            <i class="fa fa-file-text-o"></i>&nbsp;R.U.D.E.
                                        </button>
                                        <button *ngIf="isMenuAvailable('materiales')" (click)="openMateriales(estudiante)" class="btn btn-xs btn-flat btn-light">
                                            <i class="fa fa-file-text-o"></i>&nbsp;MATERIALES
                                        </button>
                                        <button *ngIf="isMenuAvailable('cartaasentimiento')" (click)="openAsentimiento(estudiante)" class="btn btn-xs btn-flat btn-light">
                                            <i class="fa fa-file-text-o"></i>&nbsp;CARTA ASENTIMIENTO
                                        </button>
                                        <button *ngIf="isMenuAvailable('ficha')" (click)="openFicha(estudiante)" class="btn btn-xs btn-flat btn-light">
                                            <i class="fa fa-file-text-o"></i>&nbsp;FICHA DE INSCRIPCIÓN<br/>GESTIÓN 2022
                                        </button>
                                    </div>
                                </div>

                                <div class="col-lg-12 text-right">
                                    <button *ngIf="encuestasPendientes" (click)="openEncuestas()" type="button" class="btn btn-flat btn-outline-dark mb-3 mt-3 pr-4 pl-4">
                                        <i class="fa fa-file-pdf-o"></i> &nbsp; Encuestas pendientes</button>

                                    <button (click)="openContrato()" type="button" class="btn btn-flat btn-outline-dark mb-3 mt-3 pr-4 pl-4">
                                        <i class="fa fa-file-pdf-o"></i> &nbsp; Contrato</button>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

            </div>



        </div>
    </div>
</div>