import { Component, OnInit, } from '@angular/core';
import { ClaseReporteService } from 'src/app/services/clasereporte.service';
import { ActivatedRoute, Router } from '@angular/router';
import { resourceLimits } from 'worker_threads';

@Component({
  selector: 'app-clasereporte',
  templateUrl: './clase-reporte.component.html',
  styleUrls: ['./clase-reporte.component.css']
})
export class ClaseReporteComponent implements OnInit {
  public show: boolean = false;
  public clasereporte: any[];
  public estudiantes: any[];
  public estudiantesList: any[];
  public detalles: any[];
  public isLoading: boolean;
  public colors = [
    'child-1', 'child-2', 'child-3', 'child-4', 'child-5', 'child-6', 'child-7', 'child-8', 'child-9', 'child-10'
  ]
  public est_colors: string[];


  constructor(
    private _claseReporteService: ClaseReporteService,
    private _router: Router
  ) {
    this.clasereporte = [];
    this.isLoading = true;
    this.est_colors = [];
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadClaseReportes(localStorage.getItem("socio_id"));
  }
  goTo(path) {
    this._router.navigate([path]);
  }

  formatDatetime(datetime:string){
    let result = '';
    const dt_split = datetime.split(' ');
    const dt = dt_split[0].split('-');
    const tt = dt_split[1];
    result = '<strong>'+dt[2]+'/'+dt[1]+'/'+dt[0] + '</strong><br/>'+ tt;
    return result;
  }

  loadClaseReportes(criteria): void {
    if (criteria === undefined) {
      criteria = "";
    }
    this.estudiantesList = [];
    this._claseReporteService.getClaseReporte(criteria).subscribe(
      response => {
        console.log(response);
        if (response.status === 200) {
          this.estudiantes = response.body;
          let estudianteActual = "";
          
          this.estudiantes.forEach(element=> { //cargar todos los estudiantes
            if (estudianteActual != element.estudiante) {
              let estud = {
                estudiante: "",
                detalleReporte: []
              }
              estud.estudiante = element.estudiante;
              this.estudiantesList.push(estud);
              estudianteActual = element.estudiante;
            }
          });

          this.estudiantes.forEach(element=> { //agregar a la lista de estudiantes los reportes
            this.estudiantesList.forEach(elem => {
              if(element.estudiante == elem.estudiante) {
                let detalle = {
                  asignatura: element.asignatura,
                  docente: element.docente,
                  detalle: element.detalle,
                  fecha_reporte: element.fecha_reporte,
                  name: element.name,
                  tipo_reporte: element.tipo_reporte,
                  adicional: element.adicional
                }
                elem.detalleReporte.push(detalle);
              }
            })
          });

          //console.log(response.body);
          this.estudiantes = response.body;
          console.log(this.estudiantes);
          //this.clasereporte = response.body;
          /*this.clasereporte.forEach(element => {
            if (this.est_colors.indexOf(element.estudiante) == -1) {
              this.est_colors.push(element.estudiante);
            }
            element['class'] = this.colors[this.est_colors.indexOf(element.estudiante)];
          });
          */
         console.log(this.estudiantesList);
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

}
