import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { URLConstants } from "src/app/classes/constants";
import { Formrude } from '../classes/formrude';

@Injectable({
  providedIn: 'root'
})
export class InscripcionService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    this.SERVER_URL = URLConstants.backend_url + 'inscripcion/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getFormulario(estudianteId) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'estudiante_id': estudianteId
      }
    });
  }

  saveInscripcion(formRude) {
    var da = new Date();
    formRude.rude_fecha = da.getFullYear() + '-' + (da.getMonth() + 1) + '-' + da.getDate();
    
    return this._http.post<PHPResponse>(this.SERVER_URL + 'save.php',
      { body: formRude }, { headers: this.httpHeaders });
  }

  getPadres(estudianteId) {
    const serviceUrl = this.SERVER_URL + 'get_padres.php';
    return this._http.get<PHPResponse>(serviceUrl, {
      headers: this.httpHeaders,
      params: {
        'estudiante_id': estudianteId
      }
    });
  }

}
