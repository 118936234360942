import { Component, OnInit } from '@angular/core';

import { EventoService } from 'src/app/services/evento.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-evento',
  templateUrl: './evento.component.html',
  styleUrls: ['./evento.component.css']
})
export class EventoComponent implements OnInit {
  public eventos: any[];
  public isLoading = false;
  private navigator: string;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _eventoService: EventoService) {
  }

  ngOnInit(): void {
    this.loadEventos();
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  loadEventos(): void {
    this._eventoService.getEventos().subscribe(
      response => {
        if (response.status === 200) {
          console.log(response.body);
          this.eventos = response.body.eventos;
          console.log(this.eventos);
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

}
