export class Config {
    name: string;
    gestion_id: number;
    cafeteria: string;
    transporte: string;
    mensaje_material: string;
    ingles: string;
    mensaje_deudores: Text;
    firmantes: any[];
    extras: any[];
    biblioteca_email: string;
    biblioteca_telefono: string;
    constructor(data: any = {}) {
        this.name = data.name;
        this.gestion_id = data.gestion_id;
        this.cafeteria = data.cafeteria;
        this.transporte = data.transporte;
        this.mensaje_material = data.mensaje_material;
        this.ingles = data.ingles;
        this.mensaje_deudores = data.mensaje_deudores;
        this.firmantes = data.firmantes;
        this.extras = data.extras;
        this.biblioteca_email = data.biblioteca_email;
        this.biblioteca_telefono = data.biblioteca_telefono;
    }
}
