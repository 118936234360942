import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PanelSocioService } from 'src/app/services/panelsocio.service';
import { SocioService } from 'src/app/services/socio.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EleccionService } from 'src/app/services/eleccion.service';
import { CircularesService } from 'src/app/services/circulares.service';

@Component({
  selector: 'app-panelsocio',
  templateUrl: './panelsocio.component.html',
  styleUrls: ['./panelsocio.component.css']
})

export class PanelSocioComponent implements OnInit {
  public elecciones: any[];
  public configpanels: any[];
  public circulares: any[];
  public isLoading = false;
  public tieneDeuda = false;
  public selectValues;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _eleccionService: EleccionService,
    private _panelsocioService: PanelSocioService,
    private _socioService: SocioService,
    private _configService: ConfigurationService,
    private _circularesService: CircularesService) {
      this.circulares = [];
  }

  ngOnInit(): void {
    this.loadTieneDeuda();
    this.loadElecciones();
    this.loadCirculares();
    this.loadConfig();
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  loadTieneDeuda(): void {

    this._socioService.getTieneDeuda().subscribe(
      response => {
        if (response.status === 200) {
          try {
            this.tieneDeuda = (response.body[0].deuda == 't');
          } catch (err) {
            this.tieneDeuda = true;  
          }          
        } else {
          this.tieneDeuda = true;
          //console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  loadConfig(): void {
    
    this._configService.getPanelConfig("socio").subscribe(
      response => {

        if (response.status === 200) {
          console.log(response.body);
          this.configpanels = response.body;
          //console.log("boletin "+this.isValueInArray("boletin", this.config));
        } else {
          //console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  loadElecciones(): void {

    let socio_id = localStorage.getItem("socio_id")
    this._eleccionService.getElecciones().subscribe(
      response => {

        if (response.status === 200) {
          this.elecciones = response.body.elecciones;
          console.log(response.body.elecciones);
        } else {
          //console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  loadCirculares(): void {
    this._circularesService.getCirculares().subscribe(
      response => {
        if (response.status === 200) {
          for(let circ of response.body.circulares){
            //console.log(circ);
            circ.mostrar = false;
            this.circulares.push(circ);
          }
          //this.circulares = response.body.circulares;
        } else {
          //console.log(response);
        }
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  formatFecha(fecha:any){
    if(fecha.toString().length >= 10){
      let split = fecha.toString().split('-');
      return `${split[2]}/${split[1]}/${split[0]}`;
    }
    return '--/--/----';
  }
  
  circularClick(id){
    this.circulares.forEach(element => {
      if(element.id == id){
        element.mostrar = !element.mostrar;
      }      
    });
  }

  isMenuAvailable(value:string) {
    let exists = false;
    
    for(let i=0; i < this.configpanels.length; i++){
      if (this.configpanels[i].value == value) {
        exists = true;
      } 
    }

    if (value == 'elecciones') {
      console.log('elecciones validando ' + exists.toString());
    }
    return exists;
    
  }

  inscripcionClick() {
    this.router.navigate(['socio']);
  }

  busClick() {
    this.router.navigate(['ebus']);
  }

  boletinClick() {
    this.router.navigate(['boletin']);
  }

  pedagogicoClick() {
    this.router.navigate(['clasereporte']);
  }

  pagosClick() {
    this.router.navigate(['pendientes']);
  }

  prestamoLibrosClick() {
    this.router.navigate(['prestamolibros']);
  }

  citaClick() {
    this.router.navigate(['cita']);
  }

  paneleleccionClick() {
    this.router.navigate(['paneleleccion']);
  }
  
  encuestaClick() {
    this.router.navigate(['encuesta']);
  }
}
