import { isNull } from "@angular/compiler/src/output/output_ast";
import { isEmpty } from "rxjs/operators";

export class Socio {
    id: number;
    codigo_interno: string;
    nit_ci: string;
    razon_social: string;
    nombres: string;
    apellido_paterno: string;
    apellido_materno: string;
    sexo: string;
    documento: string;
    documento_complemento: string;
    documento_expedido: string;
    direccion: string;
    direccion_ref: string;
    departamento: string;
    provincia: string;
    municipio: string;
    localidad: string;
    zona: string;
    avenida: string;
    nro_vivienda: string;
    email: string;

    idioma: string;
    telf_fijo: string;
    telf_movil: string;
    instruccion: string;
    especialidad: string;

    trabajo_actual: string;
    trabajo_empresa: string;
    trabajo_direccion: string;
    trabajo_email: string;
    trabajo_telf: string;
    trabajo_movil: string;
    fecha_nacimiento: string;
    es_socio: boolean;
    tipo_perfil: string;
    tipo_relacion: string;

    webapp_access: boolean;
    webapp_user: string;

    constructor(data: any = {}) {
        this.id = data.id;
        this.codigo_interno = data.codigo_interno;
        this.nit_ci = data.nit_ci;
        this.razon_social = data.razon_social;
        this.nombres = data.nombres;
        this.apellido_paterno = data.apellido_paterno;
        this.apellido_materno = data.apellido_materno;
        this.sexo = data.sexo;
        this.documento = data.documento;
        this.documento_complemento = data.documento_complemento;
        this.documento_expedido = data.documento_expedido;
        this.direccion = data.direccion;
        this.direccion_ref = data.direccion_ref;
        this.departamento = data.departamento;
        this.provincia = data.provincia;
        this.municipio = data.municipio;
        this.localidad = data.localidad;
        this.zona = data.zona;
        this.avenida = data.avenida;
        this.nro_vivienda = data.nro_vivienda;
        this.email = data.email;
        this.idioma = data.idioma;
        this.telf_fijo = data.telf_fijo;
        this.telf_movil = data.telf_movil;
        this.instruccion = data.instruccion;
        this.especialidad = data.especialidad;
        this.trabajo_actual = data.trabajo_actual;
        this.trabajo_empresa = data.trabajo_empresa;
        this.trabajo_direccion = data.trabajo_direccion;
        this.trabajo_email = data.trabajo_email;
        this.trabajo_telf = data.trabajo_telf;
        this.trabajo_movil = data.trabajo_movil;
        this.fecha_nacimiento = data.fecha_nacimiento;
        this.es_socio = data.es_socio;
        this.tipo_perfil = data.tipo_perfil;
        this.tipo_relacion = data.tipo_relacion;
        this.webapp_access = data.webapp_access;
        this.webapp_user = data.webapp_user;
    }

    validateData() {
        let regexpEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
        let regexPhone = new RegExp('^[0-9 ()+-]+$');
        let regexNumber = new RegExp('^[\d]+$');
        if (!this.validateText(this.nombres)) return { message: 'Nombres', status: false };
        if (!this.validateText(this.apellido_paterno)) return { message: 'Apellido paterno', status: false };
        if (!this.validateText(this.apellido_materno)) return { message: 'Apellido materno', status: false };
        if (!this.validateText(this.fecha_nacimiento)) return { message: 'Fecha de nacimiento', status: false };
        if (!this.validateText(this.documento)) return { message: 'Carnet de identidad', status: false };
        if (!this.validateText(this.departamento)) return { message: 'Departamento', status: false };
        if (!this.validateText(this.provincia)) return { message: 'Provincia', status: false };
        if (!this.validateText(this.municipio)) return { message: 'Municipio', status: false };
        if (!this.validateText(this.localidad)) return { message: 'Localidad', status: false };
        if (!this.validateText(this.zona)) return { message: 'Zona', status: false };
        if (!this.validateText(this.avenida)) return { message: 'Avenida/Calle', status: false };
        if (!this.validateText(this.nro_vivienda)) return { message: 'Nro. vivienda', status: false };
        if (!this.validateText(this.direccion_ref)) return { message: 'Punto de referencia', status: false };
        if (!this.validateText(this.idioma)) return { message: 'Idioma que habla frecuentemente', status: false };
        if (!this.validateText(this.instruccion)) return { message: 'instruccion', status: false };

        // if (!this.validateText(this.trabajo_actual)) return {message: 'Ocupación laboral actual', status: false};
        // if (!this.validateText(this.trabajo_empresa)) return {message: 'Nombre de empresa en que trabaja', status: false};
        // if (!this.validateText(this.trabajo_direccion)) return {message: 'Dirección laboral', status: false};


        if (!regexpEmail.test(this.email)) return { message: 'Email. ej: minombre@webmail.com', status: false };
        if (!regexpEmail.test(this.trabajo_email)) return { message: 'Email laboral. ej: usuario@miempresa.com', status: false };
        // Validate phones
        //if (!regexPhone.test(this.telf_fijo)) return {message: 'Telf. Fijo. ej: +591 4 4858221', status: false};
        //if (!regexPhone.test(this.telf_movil)) return {message: 'Telf. Móvil. ej: +591 77088478', status: false};
        //if (!regexPhone.test(this.trabajo_telf)) return {message: 'Telf. laboral. ej: +591 4 4858221', status: false};
        //if (!regexPhone.test(this.trabajo_movil)) return {message: 'Telf. móvil laboral. ej: +591 77088478', status: false};

        return { status: true, message: 'OK' };

    }

    validateSocio(prefix: string, valids='valids') {
        // valid will return valid ids
        // novalid will return no valid ids
        let expected = true;
        if (valids === 'novalids'){
            expected = false;
        }
        let ids:string[] = [];
        if (this.validateText(this.nombres) === expected ) { ids.push(prefix + 'nombres'); }
        if (this.validateText(this.apellido_paterno) === expected) { ids.push(prefix + 'apellido_paterno'); }
        if (this.validateText(this.apellido_materno) === expected) { ids.push(prefix + 'apellido_materno'); }
        if (this.validateText(this.documento) === expected) { ids.push(prefix + 'documento'); }
        if (this.validateText(this.documento_expedido) === expected) { ids.push(prefix + 'documento_expedido'); }
        if (this.validateText(this.departamento) === expected) { ids.push(prefix + 'departamento'); }
        if (this.validateText(this.provincia) === expected) { ids.push(prefix + 'provincia'); }
        if (this.validateText(this.localidad) === expected) { ids.push(prefix + 'localidad'); }
        if (this.validateText(this.municipio) === expected) { ids.push(prefix + 'municipio'); }
        if (this.validateText(this.zona) === expected) { ids.push(prefix + 'zona'); }
        if (this.validateText(this.avenida) === expected) { ids.push(prefix + 'avenida'); }
        if (this.validateText(this.nro_vivienda) === expected) { ids.push(prefix + 'nro_vivienda'); }
        if (this.validateText(this.email) === expected) { ids.push(prefix + 'email'); }
        if (this.validateText(this.direccion_ref) === expected) { ids.push(prefix + 'direccion_ref'); }
        if (this.validateText(this.telf_fijo) === expected) { ids.push(prefix + 'telf_fijo'); }
        if (this.validateText(this.idioma) === expected) { ids.push(prefix + 'idioma'); }
        if (this.validateText(this.trabajo_actual) === expected) { ids.push(prefix + 'trabajo_actual'); }
        if (this.validateText(this.instruccion) === expected) { ids.push(prefix + 'instruccion'); }
        if (this.validateText(this.fecha_nacimiento) === expected) { ids.push(prefix + 'fecha_nacimiento'); }

        return {
            status: ids.length === 0,
            ids: ids
        };
    }

    validateText(text_value: string) {
        if (typeof text_value === 'undefined') {
            return false;
        }
        return text_value !== null && text_value.trim() !== '';
    }

    setBlank() {
        this.nit_ci  = ' ';
        this.razon_social  = ' ';
        this.nombres  = ' ';
        this.apellido_paterno  = ' ';
        this.apellido_materno  = ' ';
        this.sexo  = ' ';
        this.documento  = ' ';
        this.documento_complemento  = ' ';
        this.documento_expedido  = ' ';
        this.direccion  = ' ';
        this.direccion_ref  = ' ';
        this.departamento  = ' ';
        this.provincia  = ' ';
        this.municipio  = ' ';
        this.localidad  = ' ';
        this.zona  = ' ';
        this.avenida  = ' ';
        this.nro_vivienda  = ' ';
        this.email  = ' ';
        this.idioma  = ' ';
        this.telf_fijo  = ' ';
        this.telf_movil  = ' ';
        this.instruccion  = ' ';
        this.especialidad  = ' ';
        this.trabajo_actual  = ' ';
        this.trabajo_empresa  = ' ';
        this.trabajo_direccion  = ' ';
        this.trabajo_email  = ' ';
        this.trabajo_telf  = ' ';
        this.trabajo_movil  = ' ';
        this.fecha_nacimiento  = '__/__/____';
        this.tipo_perfil  = ' ';
        this.tipo_relacion  = ' ';
    }
}

export class PopupSocio{
    id: number;
    email: string;
    telf_fijo: string;
    razon_social: string;
    nit_ci: string;

    constructor(data: any ={}){
        this.id = data.id;
        this.email = data.email;
        this.telf_fijo = data.telf_fijo;
        this.razon_social = data.razon_social;
        this.nit_ci = data.nit_ci;
    }

    validate(): boolean{
        if(this.email === null || this.email.length < 4) return false;
        if(this.razon_social === null || this.razon_social.length < 4) return false;
        if(this.nit_ci === null || this.nit_ci.length < 4) return false;
        if(this.telf_fijo === null || this.telf_fijo.length < 4) return false;
        return true;
    }
}