import { Component, OnInit, Input } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { Busestudiante } from 'src/app/classes/busestudiante';
import { Estudiante } from 'src/app/classes/estudiante';
import { Gestion } from 'src/app/classes/gestion';
import { BusestudianteService } from 'src/app/services/busestudiante.service';
import { EstudianteService } from 'src/app/services/estudiante.service';
import { GestionService } from 'src/app/services/gestion.service';
import { ZonaService } from 'src/app/services/zona.service';

@Component({
  selector: 'app-bus',
  templateUrl: './bus.component.html',
  styleUrls: ['./bus.component.css']
})

export class BusComponent implements OnInit {
  public zonas: any[];
  public gestionActual: Gestion;
  public estudiante: Estudiante;
  public isLoading = false;
  private normalSize = true;
  public tipo = "ambos";
  public zonaId = "0";

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _gestionService: GestionService,
    private _busestudianteService: BusestudianteService,
    private _estudianteService: EstudianteService,
    private _zonaService: ZonaService) {
    this.estudiante = new Estudiante();
  }

  ngOnInit(): void {
    this.loadEstudiante(localStorage.getItem("estudiante_id"));
    this.loadZonas();
    this.loadCurrentGestion();
  }

  loadEstudiante(id): void {
    this._estudianteService.getEstudiante(id).subscribe(
      response => {
        if (response.status === 200) {
          this.estudiante = response.body;
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  loadZonas(): void {
    this._zonaService.getZonas().subscribe(
      response => {

        if (response.status === 200) {
          this.zonas = response.body;
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  loadCurrentGestion(): void {
    this._gestionService.getGestionActual().subscribe(
      response => {

        if (response.status === 200) {
          this.gestionActual = response.body;
          let gestionActual = response.body as Gestion;

        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }


  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
    alert('Se registró la solicitud de transporte en bus');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  imageClicked(elementId) {
    var img = document.getElementById(elementId); 
    if (this.normalSize) {
      img.style.height = '600px';
      img.style.width = '450px';  
    } else {
      img.style.height = '200px';
      img.style.width = '150px';
    }
    this.normalSize = !this.normalSize;    

  }

  clickThumbnailPortada(id) {
    var elementId = "zona-"+id;
    this.imageClicked(elementId); 
  }

  clickTipo(tipo) {
    this.tipo = tipo;
  }

  clickZona(id) {
    this.zonaId = id;
  }

  clickGrabar() {
    
    let busestudiante = new Busestudiante();
    busestudiante.zona_id = Number(this.zonaId);
    busestudiante.perfil_id = Number(this.estudiante.id);
    busestudiante.direccion_recojo = (document.getElementById("direccion_recojo") as HTMLInputElement).value;
    busestudiante.direccion_entrega = (document.getElementById("direccion_retorno") as HTMLInputElement).value;
    busestudiante.tipo = this.tipo;
    busestudiante.gestion_id = this.gestionActual.id;

    this._busestudianteService.updateData(busestudiante).subscribe(
      response => {
        if (response.status == 200) {
          if (response.message != 'La solicitud de transporte fue registrada.') {
            alert(response.message);
          }        
          this.showSuccess();
          this.goTo("ebus");
        }
        this.hideLoader();
      },
      error => {
        this.hideLoader();
        this.showError();
      }
    );

  }
}
