import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public fromApp: boolean;
  
  constructor(private _authService: AuthService) { }

  ngOnInit(): void {
    this.fromApp = localStorage.getItem("fromApp") == "true";
  }

  displayUsername(){
    let leyenda = "Socio: ";
    if (localStorage.getItem("tipo_perfil") == "est") {
      leyenda = "Estudiante: ";
    }
    let username = leyenda + 
    //localStorage.getItem("codigo_socio") + " - " +
    localStorage.getItem("nombres");
    return username
  }

  logout(){
    this._authService.logout();
  }

}
