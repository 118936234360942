import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { PopupSocio, Socio } from 'src/app/classes/socio';
import { Estudiante } from 'src/app/classes/estudiante';
import { AuthService } from 'src/app/services/auth.service';
import { SocioService } from 'src/app/services/socio.service';
import { EncuestaService } from 'src/app/services/encuesta.service';
import { Router } from '@angular/router';
import { FormConstants, URLConstants } from 'src/app/classes/constants';
import { InscripcionService } from 'src/app/services/inscripcion.service';
import { Formrude } from 'src/app/classes/formrude';
import { Encuesta } from 'src/app/classes/encuesta';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-socio',
  templateUrl: './socio.component.html',
  styleUrls: ['./socio.component.css']
})
export class SocioComponent implements OnInit, AfterViewChecked {
  public configpanels: any[];
  public socio: Socio;
  public nosocio: Socio;
  public estudiantes: any[];
  public sexos: any;
  public ciudades: any;
  public selectValues;
  public isLoading: boolean;
  public noSocioBlank: boolean;
  public encuestasPendientes: boolean;
  public encuestas: Encuesta[];
  public editPopup: boolean;
  public popup: any;
  public popupEstudiante: number;
  constructor(
    private _configService: ConfigurationService,
    private _authService: AuthService,
    private _router: Router,
    private _socioService: SocioService,
    private _inscripcionService: InscripcionService,
    private _encuestaService: EncuestaService) {
    this.socio = new Socio();
    this.nosocio = new Socio();
    this.selectValues = FormConstants;
    this.sexos = FormConstants.sexos;
    this.ciudades = FormConstants.ciudades;
    this.noSocioBlank = false;
    this.encuestasPendientes = false;
    this.encuestas = [];
    this.editPopup = false;
    this.popup = new PopupSocio({id:0, email:'', telefono:'', razonSocial:'', nit:''});
    this.popupEstudiante = 0;
  }

  ngOnInit(): void {
    this.loadConfig();
    this.showLoader();
    this.setnosocioblank();
    
    this._authService.validateAccount();
    this._socioService.getCurrentSocio().subscribe(
      response => {
        if (response.status == 200) {
          this.socio = new Socio(response.body);
          this.nosocio = new Socio(response.body.corepresentante);
          this.estudiantes = response.body.estudiantes;
          this.hideLoader();
        }
      },
      error => {
        console.log(error.error);
        this.hideLoader();
      }
    );
    this.getEncuestas();
  }
  
  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  ngAfterViewChecked() {
    //this.isLoading = false;
  }

  showLoader() {
    this.isLoading = true;
  }

  hideLoader() {
    this.isLoading = false;
  }

  getNameValue(obCollection: any, value) {
    let obResponse = obCollection.find(element => element.value === value)
    if (obResponse !== undefined) {
      return obResponse.name;
    }
    return '';
  }

  logout() {
    this._authService.logout();
  }

  setnosocioblank() {
    if (this.noSocioBlank) {
      this.nosocio.setBlank();
    }
  }

  loadConfig(): void {
    
    this._configService.getPanelConfig("socio").subscribe(
      response => {

        if (response.status === 200) {
          console.log(response.body);
          this.configpanels = response.body;
          //console.log("boletin "+this.isValueInArray("boletin", this.config));
        } else {
          //console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  inscribirEstudiante(estudiante) {
    this.showLoader();
    this._socioService.getCurrentSocio().subscribe(
      response => {
        if (response.status == 200) {
          let foundSocio = new Socio(response.body);
          let foundNosocio = new Socio(response.body.corepresentante);
          this.hideLoader();
          let noValidData = foundSocio.validateSocio('socio_', 'novalids');
          if (noValidData.ids.length !== 0) {
            //this.spotInputs(noValidData.ids, 'form-control form-control-sm novalid');
            alert('ASEGURESE DE HABER GUARDADO LOS DATOS DE SOCIO ANTES DE PROCEDER');
            return false;
          }
          if (this.nosocio.apellido_paterno != ' ' && this.nosocio.apellido_materno != ' ' && this.nosocio.nombres != ' ') {
            noValidData = foundNosocio.validateSocio('nosocio_', 'novalids');
            if (noValidData.ids.length !== 0) {
              alert('ASEGURESE DE HABER GUARDADO LOS DATOS DE PADRE/MADRE ANTES DE PROCEDER');
              return false;
            }  
          }
          this.facturacionPopup(estudiante.id);
          // this._router.navigate(['/inscripcion', estudiante.id])
        }
      },
      error => {
        console.log(error);
        this.hideLoader();
      }
    );

  }

  facturacionPopup(estudianteID: number){
    this.editPopup = false;
    this.popup = new PopupSocio({
      id: this.socio.id,
      email:this.socio.email, 
      telf_fijo:this.socio.telf_fijo, 
      razon_social:this.socio.razon_social, 
      nit_ci:this.socio.nit_ci
    });
    this.popupEstudiante = estudianteID;
    var element = document.getElementById('popup-button');
    element.click();
  }

  editModal(){
    this.editPopup = true;
  }

  saveModal(){
    if(!this.popup.validate()){
      alert("Debe llenar correctamente todos los campos para proceder.");
      return;
    }
    this.showLoader();
    this._socioService.postPopup(this.popup).subscribe(
      response => {
        console.log(response);
        if (response.status == 200) {
          this.socio = new Socio(response.body);
          this.restoreModal();
        }
        this.hideLoader();
      },
      error => {
        console.log(error);
        this.hideLoader();
        // this.showError();
      }
    );
  }

  restoreModal(){
    this.editPopup = false;
    this.popup = new PopupSocio(this.socio);
  }

  finishModal(){
    if(!this.popup.validate()){
      alert("Debe llenar correctamente todos los campos para proceder.");
      return;
    }
    var element = document.getElementById('popup-button');
    element.click();
    this._router.navigate(['/inscripcion', this.popupEstudiante]);
  }

  saveSocio() {
    let validData = this.socio.validateSocio('socio_', 'valids');
    this.spotInputs(validData.ids, 'form-control form-control-sm');
    let noValidData = this.socio.validateSocio('socio_', 'novalids');
    if (noValidData.ids.length !== 0) {
      this.spotInputs(noValidData.ids, 'form-control form-control-sm novalid');
      alert('VERIFIQUE LOS DATOS DE SOCIO ANTES DE GUARDAR');
      return false;
    }
    this.showLoader();
    this._socioService.updateData(this.socio).subscribe(
      response => {
        console.log(response);
        if (response.status == 200) {
          this.socio = new Socio(response.body);
          this.showSuccess();
          alert('LOS DATOS DE SOCIO FUERON GUARDADOS');
        }
        this.hideLoader();
      },
      error => {
        console.log(error);
        this.hideLoader();
        this.showError();
      }
    );
  }

  saveNoSocio() {
    let validData = this.nosocio.validateSocio('nosocio_', 'valids');
    this.spotInputs(validData.ids, 'form-control form-control-sm');
    if (this.nosocio.apellido_paterno != ' ' && this.nosocio.apellido_materno != ' ' && this.nosocio.nombres != ' ') {
      let noValidData = this.nosocio.validateSocio('nosocio_', 'novalids');
      if (noValidData.ids.length !== 0) {
        this.spotInputs(noValidData.ids, 'form-control form-control-sm novalid');
        alert('VERIFIQUE LOS DATOS DE PADRE/MADRE ANTES DE GUARDAR');
        return false;
      }
    }    

    this.showLoader();
    this._socioService.updateData(this.nosocio).subscribe(
      response => {
        if (response.status == 200) {
          this.nosocio = new Socio(response.body);
          this.showSuccess();
          alert('LOS DATOS DE PADRE/MADRE FUERON GUARDADOS');
        }
        this.hideLoader();
      },
      error => {
        console.log(error);
        this.hideLoader();
      }
    );
  }

  getEncuestas(){
    this._encuestaService.getEncuestas().subscribe(
      result => {
        this.encuestas = [];
        result.body.forEach(element => {
          let enc = new Encuesta();
          enc = Object.assign(enc, element);
          this.encuestas.push(enc);
          this.encuestasPendientes = true;
        });
      },
      error => {console.log(error);}
    );
  }

  openEncuestas(){
    this._router.navigate(['encuesta']);
  }

  openContrato() {
    let id = localStorage.getItem("token");
    //window.open(`${URLConstants.backend_url}contrato/get.php?id=${id}`, '_blank');
    window.open(`${URLConstants.odoo_url}contrato_socio/${URLConstants.odoo_db}/${id}`, '_blank');
    //window.location.href = `http://localhost/froebel-webservice/dist/contrato/get.php?id=${id}`;
  }

  openRude(estudiante: Estudiante) {
    this.showLoader();
    this._inscripcionService.getFormulario(estudiante.id).subscribe(
      response => {
        if (response.status == 200) {
          let rude: Formrude = new Formrude;
          rude = Object.assign(rude, response.body);
          this.hideLoader();
          let validation = rude.validateObject('rude', 'novalids');
          if (validation.ids.length !== 0) {
            alert('VERIFIQUE EL FORMULARIO DE INSCRIPCION');
          } else {
            window.open(`${URLConstants.backend_url}rude/get.php?estudiante_id=${estudiante.id}`, '_blank');
          }
        }
        this.hideLoader();
      },
      error => {
        console.log(error);
        this.hideLoader();
      }
    );
  }

  openMateriales(estudiante: Estudiante) {
    let id = localStorage.getItem("socio_id");
    window.open(`${URLConstants.odoo_url}lista_materiales/${URLConstants.odoo_db}/${estudiante.id}`, '_blank');
  }

  openAsentimiento(estudiante: Estudiante) {
    let id = estudiante.id;
    window.open(`${URLConstants.odoo_url}contrato_asentimiento/${URLConstants.odoo_db}/${estudiante.id}`, '_blank');
  }

  openFicha(estudiante: Estudiante) {
    this.showLoader();
    this._inscripcionService.getFormulario(estudiante.id).subscribe(
      response => {
        if (response.status == 200) {
          let rude: Formrude = new Formrude;
          rude = Object.assign(rude, response.body);
          this.hideLoader();
          let validation = rude.validateObject('rude', 'novalids');
          if (validation.ids.length !== 0) {
            alert('VERIFIQUE EL FORMULARIO DE INSCRIPCION');
          } else {
            window.open(`${URLConstants.backend_url}rude/getficha.php?estudiante_id=${estudiante.id}`, '_blank');
          }
        }
        this.hideLoader();
      },
      error => {
        console.log(error);
        this.hideLoader();
      }
    );
  }


  spotInputs(inputIds: string[], class_name: string) {
    for (let x of inputIds) {
      let doc = document.getElementById(x);
      if (doc === null) {
        console.log('Element ID: ' + x + ' not found');
        continue;
      }
      doc.className = class_name;
    }
  }

  isMenuAvailable(value:string) {
    for(let panel of this.configpanels){
      if(value == panel.value){
        return true;
      }
    }
    return false;
    
  }

}
