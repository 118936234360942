import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { EstudianteService } from 'src/app/services/estudiante.service';
import { URLConstants } from 'src/app/classes/constants';

@Component({
  selector: 'app-ebus',
  templateUrl: './ebus.component.html',
  styleUrls: ['./ebus.component.css']
})

  
export class EbusComponent implements OnInit {
  public estudiantes: any[];
  public isLoading = false;
  private normalSize = true;
  public _contratoUrl: string;     

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _estudianteService: EstudianteService) {
      this._contratoUrl =  URLConstants.odoo_url +  'froebel_bus_contrato/' + URLConstants.odoo_db + '/';
    }
  
  ngOnInit(): void {
    this.loadEstudiantes();
  }

  loadEstudiantes(): void {
    this._estudianteService.getEstudianteBySocioId(localStorage.getItem("socio_id"), true).subscribe(
      response => {
        if (response.status === 200) {
          this.estudiantes = response.body;
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  clickContrato(beId) {
    window.open(this._contratoUrl+beId, "_blank");
  }

  clickEstudiante(id, estudiante) {
    localStorage.setItem("estudiante_id", id);
    localStorage.setItem("estudiante", estudiante);
    this.goTo("bus");
  }

}
