import { Component, OnInit } from '@angular/core';
import { IpServiceService } from '../../services/ip-service.service'; 

import { ActivatedRoute, Router } from '@angular/router';
import { EleccionService } from 'src/app/services/eleccion.service';

@Component({
  selector: 'app-paneleleccion',
  templateUrl: './paneleleccion.component.html',
  styleUrls: ['./paneleleccion.component.css']
})
export class PaneleleccionComponent implements OnInit {
  public elecciones: any[];
  public consejos: any[];
  public votosocio: any[];
  public noVota: boolean = false;
  public isLoading = false;
  private ipAddress: string;
  private navigator: string;
  public fromApp: boolean;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private ip: IpServiceService,
    private _eleccionService: EleccionService) {
  }

  ngOnInit(): void {
    this.fromApp = localStorage.getItem("fromApp") == "true";
    this.loadElecciones();
    this.getIP();
  }

  getIP() {
    this.navigator = window.navigator.userAgent;
    this.ip.getIPAddress().subscribe((res: any)=>{
      this.ipAddress = "IP " + res.ip + " " + this.navigator;
    });
  }


  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  loadElecciones(): void {

    let socio_id = localStorage.getItem("socio_id")
    this._eleccionService.getElecciones().subscribe(
      response => {
        
        if (response.status === 200) {
          
          this.elecciones = response.body.elecciones;
          this.consejos = response.body.consejos;
          this.votosocio = response.body.votosocio;
          
          if (response.body.no_vota == 't') {
            this.noVota = true;
          }
          this.setEleccion(response.body);
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  setEleccion(body) {
    if (body.hasOwnProperty('elecciones')) {
      if (body.elecciones.length > 0) {
        localStorage.setItem('eleccion_id', body.elecciones[0].id);
        localStorage.setItem('eleccion_name', body.elecciones[0].name);
      }
    }    
  }

  votar(consejo_id, name) {
    localStorage.setItem("consejo_id", consejo_id);
    localStorage.setItem("consejo_name", name);
    this._router.navigate(['eleccion']);
  }

  votoFound(consejo_id): Boolean {
    let found = false;
    this.votosocio.forEach((vs) => {
      if (vs.socio_id == localStorage.getItem("socio_id") &&
        vs.consejo_id == consejo_id &&
        vs.eleccion_id == localStorage.getItem("eleccion_id")) {
        found = true;
      }
    });

    return found;
  }

  votarInhabilitado() {
    let date = new Date();
    let votosocio = new Array();

    this.consejos.forEach((cons) => {
      votosocio["socio_id"] = localStorage.getItem("socio_id");
      votosocio["eleccion_id"] = localStorage.getItem("eleccion_id");
      votosocio["consejo_id"] = cons.id;
      votosocio["inhabilitado"] = true;

      let payload = {
        "socio": {
          "socio_id": localStorage.getItem("socio_id"),
          "eleccion_id": localStorage.getItem("eleccion_id"),
          "consejo_id": localStorage.getItem("consejo_id"),
          "inhabilitado": 't',
          "dispositivo": this.ipAddress
        },
        "votos": []
      };
      this.updateVotosocio(payload);
    });

    document.getElementById("inhabilitadoButton").style.display= "none";
  }

  updateVotosocio(payload) {
    this._eleccionService.updateData(payload).subscribe(
      response => {
        if (response.status == 200) {
          if (response.message != 'Su voto fue registrado, gracias por votar.') {

          }
          //this.showSuccess();    
        }
        this.hideLoader();
      },
      error => {
        this.hideLoader();
        this.showError();
      }
    );    
  }
}
