import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { URLConstants } from "src/app/classes/constants";
import { Busestudiante } from '../classes/busestudiante';

@Injectable({
  providedIn: 'root'
})
export class BusestudianteService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    this.SERVER_URL = URLConstants.backend_url + 'busestudiante/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getBusestudiante(estudiante_id, gestion_id) {
      const service_url = this.SERVER_URL + 'get.php';
      return this._http.get<PHPResponse>(service_url, {
        headers: this.httpHeaders,
        params: {
          estudiante_id: estudiante_id,
          gestion_id: gestion_id
        }
      });
  }

  updateData(busestudiante: Busestudiante) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: busestudiante }, { headers: this.httpHeaders });
  }

}
