import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { promise } from 'protractor';
import { PHPResponse } from '../classes/phpresponse';
import { User } from '../classes/user';
import { Router } from '@angular/router';
import { URLConstants } from 'src/app/classes/constants';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  SERVER_URL: string;
  CONFIG_URL: string;
  auth_url: string;
  validateAccountUrl: string;
  headers: HttpHeaders;

  constructor(
    private router: Router,
    private http: HttpClient) {
    this.SERVER_URL = URLConstants.backend_url + 'account/';
    this.CONFIG_URL = URLConstants.backend_url + 'config/';
    this.auth_url = URLConstants.backend_url + 'authenticate.php';
    this.validateAccountUrl = URLConstants.backend_url + 'account/validate.php';
  }

  onInit() {
  }

  getAccount() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + localStorage.getItem('token')
      })
    };
    return this.http.get<PHPResponse>(this.SERVER_URL + 'get.php',
      httpOptions);
  }

  validateAccount() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + localStorage.getItem('token')
      })
    };
    this.http.post<PHPResponse>(this.SERVER_URL + 'validate.php',
      { 'socio_id': parseInt(localStorage.getItem('socio_id')) }, httpOptions).subscribe(
        response => {
          console.log("VALIDATION");
          if (response.status != 200) {
            this.router.navigate(['login']);
          }
          // recibos pendientes de pago
          if (parseInt(response.body.pendientes) > 0) {
            this.router.navigate(['pendientes']);
          }
        },
        error => {
          console.log(error);
        }
      )
  }

  authenticate(user: string, password: string, token: string) {
    if (typeof (Storage) !== 'undefined') {
      // Código cuando Storage es compatible
    } else {
      // Código cuando Storage NO es compatible
      console.log('NOOOOOOOO');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + localStorage.getItem('token')
      })
    };
    
    return this.http.post<PHPResponse>(this.SERVER_URL + 'authenticate.php',
      { 'username': user, 'password': password, 'token': token },
      { headers: this.headers });
  }

  validateMFACode(userId: string, token: string, mfaCode: string) {
    if (typeof (Storage) !== 'undefined') {
      // Código cuando Storage es compatible
    } else {
      // Código cuando Storage NO es compatible
      console.log('NOOOOOOOO');
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + localStorage.getItem('token')
      })
    };
    
    return this.http.post<PHPResponse>(this.SERVER_URL + 'authenticate.php',
      { 'user_id': userId, 'mfa_code': mfaCode, 'token': token },
      { headers: this.headers });
  }

  setSession(data: any) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("socio_id", data.id);
    localStorage.setItem("codigo_socio", data.codigo_interno);
    localStorage.setItem("nombres", data.nombres);
    localStorage.setItem("username", data.username);
    localStorage.setItem("es_socio", data.es_socio);
    localStorage.setItem("tipo_perfil", data.tipo_perfil);
  }
  
  removeSession() {
    localStorage.removeItem("token");
    localStorage.removeItem("socio_id");
    localStorage.removeItem("codigo_socio");
    localStorage.removeItem("nombres");
    localStorage.removeItem("username");
    localStorage.removeItem("es_socio");
    localStorage.removeItem("tipo_perfil");
  }

  setTempData(data: any) {
    localStorage.setItem("tokenTemp", data.token);
    localStorage.setItem("socio_idTemp", data.id);
    localStorage.setItem("codigo_socioTemp", data.codigo_interno);
    localStorage.setItem("nombresTemp", data.nombres);
    localStorage.setItem("usernameTemp", data.username);
    localStorage.setItem("es_socioTemp", data.es_socio);
    localStorage.setItem("tipo_perfilTemp", data.tipo_perfil);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);
  }

  getConfig() {
    return this.http.get<PHPResponse>(this.CONFIG_URL + 'get.php', {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + localStorage.getItem('token')
      }),
      params: {
        id: '1'
      }
    });
  }

}
