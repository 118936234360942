<div id="preloader">
    <div class="loader">
        <img src="assets/img/loading.gif"/>
        <span class="loader-label">Cargando</span>
    </div>
</div>
<div class="login-area login-s2">
    <div class="container">
        <div class="login-box ptb--100">
            <div class="form">
                <div class="login-form-head bg-white">

                    <img src="assets/img/froebel.png" style="max-height: 128px" />
                    <h4>PORTAL<br /></h4>
                </div>
                <div class="login-form-body">

                    <div class="form-gp" [ngClass]="userFocus ? 'focused': ''" id="usuarioContainer">
                        <label for="userInput">Usuario</label>
                        <input [(ngModel)]="user.username" id="userInput" type="text" (keyup)="validateValues($event)"
                            (focus)="toggleUserFocus($event)" (blur)="toggleUserFocus($event)" />
                        <i class="ti-user"></i>
                        <div id="loginUserAlert" class="text-danger"></div>
                    </div>
                    <div class="form-gp" [ngClass]="pinFocus ? 'focused': ''" id="pinContainer">
                        <label for="pinInput">Contraseña</label>
                        <input [(ngModel)]="user.password"  type="password" id="pinInput" (keyup)="validateValues($event)"
                            (focus)="togglePinFocus($event)" (blur)="togglePinFocus($event)" />
                        <i class="ti-lock"></i>
                        <div id="loginPinAlert" class="text-danger"></div>
                    </div>
                    <div class="row mb-4 rmber-area">
                        <div class="col-6">
                        </div>
                        <div class="col-6 text-right">
                            <!--a class="login-link" href="#">Olvidó su Contraseña?</a-->
                        </div>
                    </div>
                    <div class="submit-btn-area">
                        <span></span>
                        <button id="form_submit" (click)="loginClick()" type="button">Ingresar <i
                                class="ti-arrow-right"></i></button>
                    </div>
                    <div class="form-footer text-center mt-5">

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>