import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { Prestamo } from '../classes/prestamo';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class PrestamoService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    // this.SERVER_URL = 'http://localhost/froebel-webservice/dist/socio/';
    this.SERVER_URL = URLConstants.backend_url + 'prestamo/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getPrestamos(criteria, page, pageSize, order) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'alumno_id': localStorage.getItem("socio_id"),
        'page': page,
        'page_size': pageSize,
        'order': order
      }
    });
  }

  updateData(prestamo: Prestamo) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: prestamo }, { headers: this.httpHeaders });
  }

  getRecibosPendientes(socioId) {
    return this._http.get<PHPResponse>(this.SERVER_URL + 'recibos_pendientes.php', {
      headers: this.httpHeaders,
      params: {
        'socio_id': socioId
      }
    });
  }

}
