export class Paginated {
    criteria: string;
    page: number;
    pageSize: number;
    totalPages: number;
    order: string;
    state: string;
    
    constructor(){
        
    }
}
