export class Formrude {
    config_id: number;
    // 1 Datos de la UE
    ue_codigo_sie: string;

    // 2.1 Datos estudiante
    alumno_apellido_paterno: string;
    alumno_apellido_materno: string;
    alumno_nombres: string;
    alumno_email: string;
    // 2.2 Lugar de nacimiento
    alumno_pais_nac: string;
    alumno_provincia_nac: string;
    alumno_departamento_nac: string;
    alumno_localidad_nac: string;
    // 2.3 Certificado de nacimiento
    alumno_oficialia_nac: string;
    alumno_libro_nac: string;
    alumno_partida_nac: string;
    alumno_folio_nac: string;
    // 2.4 Fehca de nacimiento
    alumno_fecha_nac: string;
    // 2.5 Documento de identificacion
    alumno_documento: string;
    alumno_complemento: string;
    alumno_expedido: string;
    // 2.5.1 Solo para extranjeros: Tipo de documento presentado para la inscripción
    alumno_tipo_documento_ext: string;
    alumno_documento_ext: string;
    // 2.6 CODIGO RUDE
    alumno_codigo_rude: string;
    // 2.7 Sexo
    alumno_sexo: string;
    // 2.8 Presenta alguna discapacidad?
    alumno_discapacidad: boolean;
    // 2.9 Nro de registro discapacidad IBC
    alumno_codigo_disc: string;
    // 2.10 Tipo de discapacidad
    alumno_tipo_disc: string;
    alumno_grado_disc: string;

    // 3 Direccion actual del estudiante
    domicilio_pais: string;
    domicilio_departamento: string;
    domicilio_provincia: string;
    domicilio_municipio: string;
    domicilio_localidad: string;
    domicilio_zona: string;
    domicilio_avenida: string;
    domicilio_nro_vivienda: string;
    domicilio_telf_fijo: string;
    domicilio_telf_movil: string;
    domicilio_punto_referencia: string;
    domicilio_zona_bus: string;

    // 4.1 Idioma y pertenencia cultural de estudiante
    // 4.1.1 Idioma en que aprendio a hablar
    idioma_materno: string;
    // 4.1.2 Que idiomas habla frecuentemente
    idioma_1: string;
    idioma_2: string;
    idioma_3: string;
    // 4.1.3 Pertenece a una nacion, pueblo...
    pertenencia: string;
    // pertenencia_alt = fields.Char('Pertenencia')

    // 4.2 Salud de estudiante
    // 4.2.1 Centro de salud en comunidad
    salud_centro: boolean;
    // 4.2.2 acudio el anno pasado a...?
    salud_sus: boolean;
    salud_caja: boolean;
    salud_publico: boolean;
    salud_privado: boolean;
    salud_vivienda: boolean;
    salud_tradicional: boolean;
    salud_automedicar: boolean;
    // 4.2.3 Cuantas veces fue al centro de salud el anno pasado
    salud_visitas: string;
    // 4.2.4 Tiene seguro de salud?
    salud_tiene_seguro: boolean;

    // 4.3.1 Tiene agua por canneria?
    servicio_agua: boolean;
    // 4.3.2 Banno en su vivienda
    servicio_banno: boolean;
    // 4.3.3 Tiene alcantarillado
    servicio_alcantarilla: boolean;
    // 4.3.4 Usa electricidad en su vivienda
    servicio_electricidad: boolean;
    // 4.3.5 Servicio de recojo de basura
    servicio_basurero: boolean;
    // 4.3.6 Vivienda que ocupa
    tipo_vivienda: string;

    // 4.4.1 Accede a internet en
    internet_casa: boolean;
    internet_ue: boolean;
    internet_publico: boolean;
    internet_celular: boolean;
    internet_no: boolean;
    // 4.4.2 Con que frecuencia usa internet
    internet_frecuencia: string;

    // 4.5.1 En gestion pasada el estudiante trabajo
    trabajo_pasado: boolean;
    trabajo_no_sabe: boolean;
    trabajo_ene: boolean;
    trabajo_feb: boolean;
    trabajo_mar: boolean;
    trabajo_abr: boolean;
    trabajo_may: boolean;
    trabajo_jun: boolean;
    trabajo_jul: boolean;
    trabajo_ago: boolean;
    trabajo_sep: boolean;
    trabajo_oct: boolean;
    trabajo_nov: boolean;
    trabajo_dic: boolean;
    // 4.5.2 En que actividad trabajo el estudiante
    trabajo_realizado: string;
    trabajo_otro: string;
    // 4.5.3 Turno de trabajo
    trabajo_turno: string;
    trabajo_mannana: boolean;
    trabajo_tarde: boolean;
    trabajo_noche: boolean;
    // 4.5.4 Con que frecuencia trabajo el estudiante
    trabajo_frecuencia: string;
    // 4.5.5 Recibio pago
    trabajo_pagado: string;
    trabajo_tipo_pago: string;

    // 4.6.1 Como llega a la UE
    transporte_tipo: string;
    transporte_otro: string;
    // 4.6.2 Tiempo maximo que demora el estudiante de su vivienda a la UE
    transporte_tiempo: string;

    // 4.7.1 Abandono de la UE la gestion pasada
    abandono_pasado: boolean;
    // 4.7.2 Causa de abandono
    abandono_ayudar: boolean;
    abandono_trabajo: boolean;
    abandono_dinero: boolean;
    abandono_edad: boolean;
    abandono_distancia: boolean;
    abandono_casa: boolean;
    abandono_embarazo: boolean;
    abandono_medico: boolean;
    abandono_viaje: boolean;
    abandono_interes: boolean;
    abandono_bullying: boolean;

    abandono_otro: string;

    // 5.1 Estudiante vive habitualmente con
    hospedador: string;
    hospedador_datos: string;
    hospedador_nombre: string; 

    // 5.2 Datos del padre
    padre_cedula: string;
    padre_complemento: string;
    padre_expedido: string;
    padre_apellido_paterno: string;
    padre_apellido_materno: string;
    padre_nombres: string;
    padre_idioma: string;
    padre_ocupacion: string;
    padre_instruccion: string;
    padre_fecha_nacimiento: string;

    // 5.3 Datos de la madre
    madre_cedula: string;
    madre_complemento: string;
    madre_expedido: string;
    madre_apellido_paterno: string;
    madre_apellido_materno: string;
    madre_nombres: string;
    madre_idioma: string;
    madre_ocupacion: string;
    madre_instruccion: string;
    madre_fecha_nacimiento: string;

    // 5.4 Datos del tutor(a)
    tutor_cedula: string;
    tutor_complemento: string;
    tutor_expedido: string;
    tutor_apellido_paterno: string;
    tutor_apellido_materno: string;
    tutor_nombres: string;
    tutor_idioma: string;
    tutor_ocupacion: string;
    tutor_instruccion: string;
    tutor_relacion: string;
    tutor_fecha_nacimiento: string;
    
    // 5.5 Tutor extraordinario
    tutor2_cedula: string;
    tutor2_complemento: string;
    tutor2_expedido: string;
    tutor2_apellido_paterno: string;
    tutor2_apellido_materno: string;
    tutor2_nombres: string;
    tutor2_cargo: string;
    tutor2_institucion: string;

    // Referencia del RUDE
    rude_lugar: string;
    rude_fecha: string;
    estado: string;

    //Salud
    salud_tipo_sangre: string;
    salud_alergias: string;
    salud_datos: string;

    constructor(data: any = {}) {
    }

    validateData() {
        let regexpEmail = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$');
        let regexPhone = new RegExp('^[0-9 ()+-]+$');
        let regexNumber = new RegExp('^[\d]+$');
        if (!this.validateText(this.idioma_materno)) return { message: 'Idioma que habla frecuentemente', status: false };
        if (!this.validateText(this.hospedador)) return { message: 'Con quién vive actualmente', status: false };

        if (!this.validateText(this.domicilio_departamento)) return { message: 'Departamento', status: false };
        if (!this.validateText(this.domicilio_provincia)) return { message: 'Provincia', status: false };
        if (!this.validateText(this.domicilio_municipio)) return { message: 'Sección/Municipio', status: false };
        if (!this.validateText(this.domicilio_localidad)) return { message: 'Localidad/Comunidad', status: false };
        if (!this.validateText(this.domicilio_zona)) return { message: 'Zona/Villa', status: false };
        if (!this.validateText(this.domicilio_avenida)) return { message: 'Avenida/Calle', status: false };
        if (!this.validateText(this.domicilio_nro_vivienda)) return { message: 'No. Vivienda', status: false };

        if (!this.validateText(this.idioma_1)) return { message: 'Idioma que habla frecuentemente', status: false };
        if (!this.validateText(this.pertenencia)) return { message: 'Pertenece a una nac...', status: false };



        if (!regexpEmail.test(this.alumno_email)) return { message: 'Email. ej: minombre@webmail.com', status: false };
        // Validate phones
        // if (!regexPhone.test(this.domicilio_telf_fijo)) return { message: 'Telf. Fijo. ej: +591 4 4858221', status: false };
        // if (!regexPhone.test(this.domicilio_telf_movil)) return { message: 'Telf. Móvil. ej: +591 77088478', status: false };
        // if (!regexPhone.test(this.trabajo_telf)) return {message: 'Telf. laboral. ej: +591 4 4858221', status: false};
        // if (!regexPhone.test(this.trabajo_movil)) return {message: 'Telf. móvil laboral. ej: +591 77088478', status: false};

        return { status: true, message: 'OK' };

    }

    validateObject(prefix: string, valids = 'valids') {
        // valid will return valid ids
        // novalid will return no valid ids
        let expected = true;
        if (valids === 'novalids') {
            expected = false;
        }
        let ids: string[] = [];
        if (this.validateText(this.alumno_nombres) === expected) { ids.push(prefix + 'alumno_nombres'); }
        if (this.validateText(this.alumno_apellido_paterno) === expected) { ids.push(prefix + 'alumno_apellido_paterno'); }
        if (this.validateText(this.alumno_apellido_materno) === expected) { ids.push(prefix + 'alumno_apellido_materno'); }
        if (this.validateText(this.alumno_apellido_materno) === expected) { ids.push(prefix + 'alumno_apellido_materno'); }
        if (this.validateText(this.alumno_documento) === expected) { ids.push(prefix + 'alumno_documento'); }
        if (this.validateText(this.alumno_email) === expected) { ids.push(prefix + 'alumno_email'); }
        if (this.validateText(this.alumno_expedido) === expected) { ids.push(prefix + 'alumno_expedido'); }
        if (this.validateText(this.hospedador) === expected) { ids.push(prefix + 'hospedador'); }
        // NACIMIENTO
        if (this.validateText(this.alumno_fecha_nac) === expected) { ids.push(prefix + 'alumno_fecha_nac'); }
        if (this.validateText(this.alumno_pais_nac) === expected) { ids.push(prefix + 'alumno_pais_nac'); }
        if (this.validateText(this.alumno_departamento_nac) === expected) { ids.push(prefix + 'alumno_departamento_nac'); }
        if (this.validateText(this.alumno_provincia_nac) === expected) { ids.push(prefix + 'alumno_provincia_nac'); }
        if (this.validateText(this.alumno_localidad_nac) === expected) { ids.push(prefix + 'alumno_localidad_nac'); }
        if (this.validateText(this.alumno_oficialia_nac) === expected) { ids.push(prefix + 'alumno_oficialia_nac'); }
        if (this.validateText(this.alumno_libro_nac) === expected) { ids.push(prefix + 'alumno_libro_nac'); }
        if (this.validateText(this.alumno_partida_nac) === expected) { ids.push(prefix + 'alumno_partida_nac'); }
        if (this.validateText(this.alumno_folio_nac) === expected) { ids.push(prefix + 'alumno_folio_nac'); }
        // DIRECCION ACTUAL
        if (this.validateText(this.domicilio_departamento) === expected) { ids.push(prefix + 'domicilio_departamento'); }
        if (this.validateText(this.domicilio_provincia) === expected) { ids.push(prefix + 'domicilio_provincia'); }
        if (this.validateText(this.domicilio_municipio) === expected) { ids.push(prefix + 'domicilio_municipio'); }
        if (this.validateText(this.domicilio_localidad) === expected) { ids.push(prefix + 'domicilio_localidad'); }
        if (this.validateText(this.domicilio_zona) === expected) { ids.push(prefix + 'domicilio_zona'); }
        if (this.validateText(this.domicilio_avenida) === expected) { ids.push(prefix + 'domicilio_avenida'); }
        if (this.validateText(this.domicilio_nro_vivienda) === expected) { ids.push(prefix + 'domicilio_nro_vivienda'); }
        if (this.validateText(this.domicilio_telf_fijo) === expected) { ids.push(prefix + 'domicilio_telf_fijo'); }


        // if (this.validateText(this.) === expected ) { ids.push(prefix + '');}
        return {
            ids: ids
        };
    }

    validateText(text_value: string) {
        if (typeof text_value === 'undefined') {
            return false;
        }
        return text_value !== null && text_value.toString().trim() !== '';
    }
}
