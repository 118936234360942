<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/paneleleccion')"
                                    class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                        class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <h5 class="header-title">Candidatos </h5>
                                <div class="header-separator"></div>
                            </div>
                            <div id="avisos" style='background-color: firebrick; color:floralwhite'></div>
                            <div class="row col-md-12">
                                <button (click)="grabarVoto(false)"
                                    class="btn btn-flat btn-outline-danger btn-red mb-5"><i class="fa fa-save"></i>
                                    Confirmar voto</button>
                                <button (click)="grabarVoto(true)"
                                    class="btn btn-flat btn-outline-danger btn-red mb-5"><i class="fa fa-remove"></i>
                                    Votar Blanco</button>
                                <div class="element3 col-md-12 table-responsive">
                                    <table class="table ">
                                        <ng-container *ngFor="let puesto of puestos">
                                            <tr>
                                                <td colspan="6">
                                                    <span><br /><br />
                                                        <h5>{{ puesto.name }}</h5> (Elegir {{
                                                        puesto.maximo}} como máximo)
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td *ngFor="let plan of puesto.planchas">
                                                    <div>
                                                        <ng-container *ngFor="let can of plan.candidatos">
                                                            <div (click)="switchVoto(can)">
                                                                <div id="{{ 'foto-' + can.candidato_id }}"
                                                                    style='display:block; width:85px; height:110px;'>
                                                                    <img class="thunbnailPortada"
                                                                        style='display:block; width:100%;'
                                                                        src='{{ can.foto }}' />
                                                                    <i class="fa fa-check"
                                                                        style="font-size:50px;color:darkgreen"
                                                                        *ngIf="can.voto==true"></i>
                                                                </div>
                                                                <div>
                                                                    <span>{{ can.candidato }}</span>
                                                                    <br />
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>