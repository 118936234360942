<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">

        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/panelsocio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                    class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <h5 class="header-title">Cita con Docentes</h5>
                                <div class="header-separator"></div>
                            </div>

                            <div class="row">
                                <div class="element3 col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-sm data-tables datatable-dark">
                                            <thead>
                                                <tr>
                                                    <th>Estudiante</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let est of citas">
                                                    <td>
                                                        <span>{{ est.estudiante }}</span>
                                                    </td>
                                                    <td>
                                                        <table class="table">
                                                            <thead>
                                                                <th>Materia</th>
                                                                <th>Docente</th>
                                                                <th>Horario</th>
                                                            </thead>
                                                            <tr *ngFor="let doc of est.docentes" class="cita_line">
                                                                <td>
                                                                    <span *ngFor="let asig of doc.asignaturas_split" class="cita_line">
                                                                        {{ asig }}<br/>
                                                                    </span>
                                                                </td>
                                                                <td >
                                                                    <span>
                                                                        {{ doc.docente}}
                                                                    </span>
                                                                </td>
                                                                <td>
                                                                    <table class="table cita_line">
                                                                        <tr class="hover-row" *ngFor="let hor of doc.horarios">
                                                                            <td>
                                                                                <button *ngIf="hor.solicitante_id===null" (click)="solicitarCita(hor.id)" 
                                                                                class="btn btn-flat btn-xsm btn-outline-dark btn-block">
                                                                                {{ hor.fecha_ini | date: 'EEEE dd/MM/yyyy HH:mm'}}
                                                                            </button>
                                                                                <span *ngIf="hor.solicitante_id>0" class="cita_solicitada">
                                                                                    <i class="fa fa-check"></i> {{ hor.fecha_ini | date: 'EEEE dd/MM/yyyy HH:mm'}} solicitada.</span>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </td>

                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>