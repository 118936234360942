import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { SocioService } from 'src/app/services/socio.service';
import { Recibo } from 'src/app/classes/recibo';
import { Socio } from 'src/app/classes/socio';
import { Config } from 'src/app/classes/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pendientes',
  templateUrl: './pendientes.component.html',
  styleUrls: ['./pendientes.component.css']
})
export class PendientesComponent implements OnInit {

  public socio: Socio;
  public recibos: any[];
  public config: Config;
  public isLoading: boolean;
  public totalRecibos: number;
  constructor(
    private _authService: AuthService,
    private _socioService: SocioService,
    private _router: Router) {
    this.isLoading = true;
    this.socio = new Socio();
    this.recibos = [];
    this.config = new Config();
    this.totalRecibos = 0;

  }

  ngOnInit(): void {
    let socioId = parseInt(localStorage.getItem("socio_id"));
    this._socioService.getCurrentSocio().subscribe(
      response => { this.socio = new Socio(response.body); },
      error => { console.log(error); }
    );
    this._authService.getConfig().subscribe(
      response => {
        if (response.status == 200) {
          this.config = new Config(response.body);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );

    this._socioService.getRecibosPendientes(socioId).subscribe(
      response => {
        if (response.status === 200) {
          this.recibos = response.body;
          this.getTotalRecibos();
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  goTo(path) {
    this._router.navigate([path]);
  }

  getTotalRecibos() {
    let totalRecibos = 0;
    this.recibos.forEach(recibo => {
      totalRecibos = totalRecibos + (Number(recibo.monto_deuda) - Number(recibo.monto_pago));
    });
    this.totalRecibos = totalRecibos;
    return totalRecibos;
  }

  logout() {
    this._authService.logout();
  }

}
