import { Component, OnInit } from '@angular/core';

import { AsignaturaService } from 'src/app/services/asignatura.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-asignatura',
  templateUrl: './asignatura.component.html',
  styleUrls: ['./asignatura.component.css']
})


export class AsignaturaComponent implements OnInit {
  public asignaturas: any[];
  public isLoading = false;
  private navigator: string;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _asignaturaService: AsignaturaService) {
  }

  ngOnInit(): void {
    this.loadAsignaturas();
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  loadAsignaturas(): void {
    this._asignaturaService.getAsignaturas().subscribe(
      response => {
        if (response.status === 200) {
          console.log(response.body);
          this.asignaturas = response.body.asignaturas;
          //console.log(this.asignaturas);
          
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

}
