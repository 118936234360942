<!--div class="submit-btn-area col-md-6" *ngFor="let encuesta of encuestas">
    <button id="encuesta_button" (click)="encuestaClick(encuesta.id )" type="button">{{ encuesta.name }} válido hasta {{
        encuesta.fecha_cierre }} <i class="ti-arrow-right"></i></button>
</div-->




<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-lg-12 pt-3 bg-white pb-5">

                                <button *ngIf="!fromApp" (click)="goTo('/#')" class="btn btn-flat btn-outline-danger btn-red mb-3">
                                    <i class="fa fa-arrow-left"></i> Atrás</button>
                                <br />

                                <h5 class="header-title">Encuestas pendientes</h5>
                                <div class="header-separator"></div>
                            </div>                                              
                            <div class="col-sm-12">
                                <div class="single-table">
                                    <div class="table-responsive">
                                        <table class="table table-sm">
                                            <tbody>
                                                <div *ngIf="encuestas.length > 0">
                                                    <tr *ngFor="let encuesta of encuestas">
                                                        <td class="text-left">
                                                            <p class="mb-3 mt-3 pr-4 pl-4">
                                                                {{ encuesta.name }}
                                                            </p>
                                                        </td>
                                                        <td class="text-right">
                                                            <button class="right" (click)="encuestaClick(encuesta.id)"
                                                                type="button"
                                                                class="btn btn-flat btn-outline-dark mb-3 mt-3 pr-4 pl-4">
                                                                Abrir<i class="ti-arrow-right"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </div>
                                                <div *ngIf="encuestas.length == 0">
                                                    <span>
                                                        No existen Encuestas pendientes en este momento.
                                                    </span>
                                                </div>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <h5 class="header-title">{{ encuestaActiva.name}}</h5>
                                <div class="header-separator"></div>
                            </div>
                            <div class="col-sm-12 mb-3" [innerHtml]="encuestaActiva.descripcion">
                            </div>
                            <div class="col-sm-12">
                                <div class="form-row align-items-center">

                                    <div class="col-sm-12" *ngFor="let pregunta of encuestaActiva.preguntas">
                                        <div class="col-sm-12 mt-3">
                                            <h6>
                                                {{ pregunta.name }}
                                            </h6>
                                        </div>
                                        <div *ngIf="isPreguntaSele(pregunta)" class="col-sm-12 mt-1">
                                            <div class="form-row align-items-center">
                                                <div class="col-md-12">
                                                    <select [(ngModel)]="pregunta.sele"
                                                        class="form-control form-control-sm">
                                                        <option *ngFor="let opt of getOpciones(pregunta.opciones)"
                                                            [ngValue]="opt.value">
                                                            {{opt.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="isPreguntaLibr(pregunta)" class="col-sm-12 mt-1">
                                            <input [(ngModel)]="pregunta.libr" type="text"
                                                class="form-control form-control-sm" />
                                        </div>
                                    </div>

                                    
                                </div>
                                <div class="col-lg-12 text-right">
                                    <button class="right" *ngIf="encuestaActiva.id && alertMessage.length == 0" (click)="showRespuestas()" type="button"
                                    class="btn btn-flat btn-outline-dark mb-3 mt-3 pr-4 pl-4">
                                    Grabar Respuestas<i class="ti-arrow-right"></i>
                                </button>
                                <div  >
                                    <!-- <ngb-alert [type]="alert.type" (closed)="close(alert);gotopagina()">{{ alertMessage }}</ngb-alert> -->
                                    <ngb-alert #staticAlert *ngIf="alertMessage.length > 0" type="success" (closed)="successMessage = ''">{{ alertMessage }}
                                    </ngb-alert>
                                    <ngb-alert #staticFailAlert *ngIf="alertFailMessage.length > 0" type="danger" (closed)="successMessage = ''">{{ alertFailMessage }}
                                    </ngb-alert>
                                </div>          

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>