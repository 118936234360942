import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/classes/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigurationService } from 'src/app/services/configuration.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})



export class LoginComponent implements OnInit {
  public configpanels: any[];
  userFocus: boolean = false;
  pinFocus: boolean = false;
  validValues: boolean = false;
  user: User;
  loaderFrame: HTMLDivElement;
  token: string = null;
  action: string = null;
  pusername: string = null;
  ppassword: string = null;
  pruta: string = null;
  ruta: string = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _configService: ConfigurationService,
    private authService: AuthService) {
      this.user = new User();
  }

  ngOnInit(): void {
    this.authService.removeSession();
    this.loaderFrame = <HTMLDivElement>document.getElementById('preloader');
    this.isLoading(false);
    //this.action = "nada";  
    //this.loadConfig();
    localStorage.setItem("fromApp", "false");

    let composed = this.route.snapshot.paramMap.get("token");
    if (composed !== null) {
      localStorage.setItem("fromApp", "true");
      let parts = composed.toString().split("-");
      if (parts.length >0) {
        this.pusername = parts[0];
        this.ppassword = parts[1];
        if (parts.length >2) {
          this.pruta = parts[2];
          
        }
      }
      

      if (this.ppassword !== null) {
        this.user.password = this.ppassword;
      }
      if (this.pusername !== null) {
        this.user.username = this.pusername;
        //this.loginClick();
        this.authenticate(this.pruta);
      }
    }
    // if (this.token !== null) {
    //   this.action = "antes loginClick";  
    //   this.loginClick();
    // }

  }

  isLoading(status = false) {
    this.loaderFrame.style.display = status ? 'flex' : 'none';
  }

  loginClick() {
    this.isLoading(true);
    //this.action = "dentro loginClick " + this.user.username + " " + this.user.password + " " +  this.token;  
    this.loadConfig();
    
  }

  loadConfig(): void {
    
    this._configService.getPanelConfig("socio").subscribe(
      response => {

        if (response.status === 200) {
          console.log(response.body);
          this.configpanels = response.body;
          if (this.isMenuAvailable('mfa')) {
            this.ruta = 'mfa';
          } else {
            this.ruta = 'panelsocio';
          }
          this.authenticate("");
          //console.log("boletin "+this.isValueInArray("boletin", this.config));
        } else {
          //console.log(response);
        }
        //this.isLoading = false;
      },
      error => {
        console.log(error);
        //this.isLoading = false;
      }
    );
  }

  authenticate(ruta) {
    this.authService.authenticate(this.user.username, this.user.password, this.token).subscribe(
    response => {
      if (response.status == 200) {        

        if (ruta == "") {
          if (localStorage.getItem("tipo_perfil")=='est' || response.body.tipo_perfil == 'est') {
            this.authService.setSession(response.body);
            this.router.navigate(['estudiante']);
          } else if (localStorage.getItem("tipo_perfil")=='tut' || response.body.tipo_perfil == 'tut') {
            //this.router.navigate(['panelsocio']);
            if (this.ruta == 'panelsocio') {
              this.authService.setSession(response.body);
            } else if (this.ruta == 'mfa') {
              this.authService.setTempData(response.body);
            }           
            this.router.navigate([this.ruta]);
          }
        } else {
          //console.log("*************** "+ruta);
          this.router.navigate([ruta]);
        }        
      }
      console.log(response);
      this.isLoading(false);
    },
    error => {
      this.isLoading(false);
      console.log(error.error);
    }
  )}

  isMenuAvailable(value:string) {
    let exists = false;
    
    for(let i=0; i < this.configpanels.length; i++){
      if (this.configpanels[i].value == value) {
        exists = true;
      } 
    }
    return exists;
    
  }

  toggleUserFocus(event: any) {
    if (event.target.value.length != 0) {
      this.userFocus = true;
      return true;
    }
    this.userFocus = !this.userFocus;
  }

  togglePinFocus(event: any) {
    if (event.target.value.length != 0) {
      this.pinFocus = true;
      return true;
    }
    this.pinFocus = !this.pinFocus;
  }

  validateValues(event: any) {
    if (this.user.username.length > 4 && this.user.password.length == 4) {
      this.validValues = true;
    } else {
      this.validValues = false;
    }
  }

}
