import { Component, OnInit } from '@angular/core';
import { BoletinService } from 'src/app/services/boletin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { URLConstants } from 'src/app/classes/constants';

@Component({
  selector: 'app-boletin',
  templateUrl: './boletin.component.html',
  styleUrls: ['./boletin.component.css']
})


export class BoletinComponent implements OnInit {
  public boletines: any[];
  public isLoading: boolean;
  private _boletinCuantitativoUrl: String;
  private _boletinCualitativoUrl: String;
  
  
  constructor(
    private _boletinService: BoletinService,
    private _router: Router
  ) { 
    this._boletinCuantitativoUrl =  URLConstants.odoo_url +  'froebel_cal/cuantitativo/froebel/';
    this._boletinCualitativoUrl =  URLConstants.odoo_url +  'froebel_cal/cualitativo/froebel/';
    this.boletines = [];
    this.isLoading = true;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadBoletines(localStorage.getItem("socio_id"), localStorage.getItem("tipo_perfil"));
  }
  goTo(path) {
    this._router.navigate([path]);
  }

  loadBoletines(criteria, tipo): void {
    if (criteria===undefined) {
      criteria="";
    }
    this._boletinService.getBoletin(criteria, tipo).subscribe(
      response => {
        if (response.status === 200) {
          console.log(response.body);
          this.boletines = response.body;
          
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }
  openBoletin(uuid, tipo_boletin): void {
    if (tipo_boletin == "cuan") {
      window.open(this._boletinCuantitativoUrl+uuid, "_blank");
    }
    if (tipo_boletin == "cual") {
      window.open(this._boletinCualitativoUrl+uuid, "_blank");
    }    
  }

  es_est(): boolean {
    return localStorage.getItem('tipo_perfil') == 'est';
  }

  es_tut(): boolean {
    return localStorage.getItem('tipo_perfil') == 'tut';
  }

}
