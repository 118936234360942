import { Component, OnInit } from '@angular/core';
import { LibroService } from 'src/app/services/libro.service';
import { PrestamoService } from 'src/app/services/prestamo.service';
import { Prestamo } from 'src/app/classes/prestamo';
import { Libro } from 'src/app/classes/libro';
import { Paginated } from 'src/app/classes/paginated';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { $ } from 'protractor';

@Component({
  selector: 'app-biblioteca',
  templateUrl: './biblioteca.component.html',
  styleUrls: ['./biblioteca.component.css']
})

//https://angular.io/guide/user-input
//https://codepen.io/Buddyweb/pen/wzOArj
export class BibliotecaComponent implements OnInit {

  public show:boolean = false;
  public prestamo: Prestamo;
  public libro: Libro;
  public isLoading: boolean;
  public libros: any[];
  public paginated: Paginated;
  public currentPage: Number;
  public isTutor: boolean;
  public fromApp: boolean;

  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private _libroService: LibroService,
    private _prestamoService: PrestamoService
  ) { 
    this.paginated = new Paginated();
  }

  loadLibros(criteria, page, pageSize, order, state): void {
    if (criteria===undefined) {
      criteria="";
    }
    this._libroService.getLibros(criteria, page, pageSize, order, state).subscribe(
      response => {

        if (response.status === 200) {
          this.libros = response.body;
          this.paginated.totalPages = response.totalPages;
          //this.getTotalRecibos();
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  ngOnInit(): void {
    //this.loadLibros(null, null, null, null, "dis");
    this.fromApp = localStorage.getItem("fromApp") == "true";
    this.isTutor = localStorage.getItem("tipo_perfil") == "tut";
    console.log(this.fromApp);
    this.paginated.state = "dis";
    this.paginated.pageSize = 5;
    this.paginated.page = 1;
    this.loadLibros("", 1, this.paginated.pageSize, "name", this.paginated.state);
  }

  goTo(path) {
    this._router.navigate([path]);
  }


  toggle(this) {
    this.show = !this.show;
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  public onpageSizeSelected(event) {
    const value = event.target.value;
    this.paginated.pageSize = value;
    this.loadLibros(this.paginated.criteria, 1, this.paginated.pageSize, "name", this.paginated.state);
  }

  search() {
    this.loadLibros(this.paginated.criteria, 1, this.paginated.pageSize, "name", this.paginated.state);
  }

  previous() {
    if (this.paginated.page>1) {
      this.paginated.page = this.paginated.page - 1;
      this.loadLibros(this.paginated.criteria, this.paginated.page, this.paginated.pageSize, "name", this.paginated.state);
    }
  }

  next() {
    if (this.paginated.page < this.paginated.totalPages) {
      this.paginated.page = this.paginated.page + 1;
      this.loadLibros(this.paginated.criteria, this.paginated.page, this.paginated.pageSize, "name", this.paginated.state);
    }
  }

  solicitarLibro(libro_id): void {
    this.prestamo = new Prestamo(); 
    this.prestamo.estudiante_id = Number(localStorage.getItem("socio_id"))
    this.prestamo.libro_id = Number(libro_id);
    this.prestamo.fecha_solicitud = new Date().toISOString().slice(0,10); 
    this.prestamo.state = "sol";

    this._prestamoService.updateData(this.prestamo).subscribe(
      response => {
        if (response.status == 200) {
          //this.prestamo = new Prestamo(response.body);
          alert(response.message);
          this.showSuccess();
          this.goTo('estudiante');
          
        }
        this.hideLoader();
      },
      error => {
        this.hideLoader();
        this.showError();
      }
    );
  }

  currDiv: string = '10';

  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }

  normalSize = true;

  imageClicked(elementId) {
    var img = document.getElementById(elementId); 
    if (this.normalSize) {
      img.style.height = '600px';
      img.style.width = '450px';  
    } else {
      img.style.height = '200px';
      img.style.width = '150px';
    }
    this.normalSize = !this.normalSize;    

  }

  clickThumbnailPortada(id) {
    var elementId = "thumbnailPortada-"+id;
    this.imageClicked(elementId); 
  }

  clickThumbnailCPortada(id) {
    var elementId = "thumbnailCPortada-"+id;
    this.imageClicked(elementId); 
  }

}
