<!-- header area start -->

<div class="header-area bg-red" style="padding: 0 30px;">
    <div class="row align-items-center">
        <!-- profile info & task notification -->
        <div class="col-md-12 col-sm-12 clearfix mt-2">
            <img class="avatar user-thumb" style="display: inline-block;" src="assets/img/LOGO_CEFF-01.png" alt="logo">
            <h4 class="user-name " style="display: inline-block; position:absolute; padding-left: 15px"> {{ displayUsername() }}</h4>

            <div *ngIf="!fromApp" class="user-profile pull-right sbg-red" (click)="logout()">
                <h4 class="user-name "> Salir <i class="fa fa-sign-out"></i></h4>
            </div>
        </div>
    </div>
</div>
<!-- header area end -->