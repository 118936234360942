<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <button (click)="goTo('/panelsocio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                class="fa fa-arrow-left"></i> Atrás</button>
                            <div class="col-sm-12">
                                <h5 class="header-title">Estudiantes</h5>
                                <div class="header-separator"></div>
                            </div>

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="single-table">
                                        <div class="table-responsive">
                                            <table class="table table-sm data-tables datatable-dark">
                                                <thead>
                                                    <tr>
                                                        <th>Estudiante</th>
                                                        <th>Acción</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let est of estudiantes">
                                                        <td>{{ est.estudiante }}</td>
                                                        <td>
                                                            <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                                                <div *ngIf="!est.beid">
                                                                    <button
                                                                        (click)="clickEstudiante(est.id, est.estudiante)"
                                                                        type="button">Solicitar transporte
                                                                        <i class="ti-arrow-right"></i></button>
                                                                </div>
                                                                <div *ngIf="est.beid">
                                                                    <button id="contrato_button"
                                                                        (click)="clickContrato(est.beid)"
                                                                        type="button">Generar Contrato</button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>