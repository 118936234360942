import { ThisReceiver } from "@angular/compiler";

export class Voto {
    socio_id: number;
    puesto_id: number;
    candidato_id: number;
    eleccion_id: number;

    constructor(data: any = {}) {
        this.socio_id = data.socio_id;
        this.puesto_id = data.puesto_id;
        this.candidato_id = data.candidato_id;
        this.eleccion_id = data.eleccion_id;
    }
}
