import { ThisReceiver } from "@angular/compiler";

export class Prestamo {
    name: string;
    autor: string;
    codigo: string;
    l_state: string; 
    fecha_solicitud: string;
    fecha_entrega: string;
    fecha_plazo: string;
    fecha_devolucion: string;
    observaciones: string;
    libro_id: number;
    estudiante_id: number;
    state: string;

    constructor(data: any = {}) {
        this.name = data.name;
        this.autor = data.autor;
        this.codigo = data.codigo;
        this.l_state = data.l_state;
        this.fecha_solicitud = data.fecha_solicitud;
        this.fecha_entrega = data.fecha_entrega;
        this.fecha_plazo = data.fecha_plazo;
        this.fecha_devolucion = data.fecha_devolucion;
        this.observaciones = data.observaciones;
        this.libro_id = data.libro_id;
        this.estudiante_id = Number(data.estudiante_id);
        this.state = data.state;
    }
}