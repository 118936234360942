<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/panelsocio')" class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                    class="fa fa-arrow-left"></i> Atrás</button>
                                <br />

                                <h5 class="header-title">Pagos pendientes</h5>
                                <div class="header-separator"></div>
                            </div>
                            <div class="col-sm-12">
                                <div class="single-table">
                                    <div class="table-responsive">
                                        <table class="table table-sm">
                                            <thead>
                                                <tr>
                                                    <th>Comprobante</th>
                                                    <th>Fecha</th>
                                                    <th>Detalle</th>
                                                    <th>Adeudado</th>
                                                    <th>Pagado</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let trans of recibos">
                                                    <td>{{ trans.recibo_name }}</td>
                                                    <td>{{ trans.fecha }}</td>
                                                    <td>{{ trans.name }}</td>
                                                    <td class="text-right">{{ trans.monto_deuda }} Bs</td>
                                                    <td class="text-right">{{ trans.monto_pago }} Bs</td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="text-right pt-5"><strong>A pagar:</strong></td>
                                                    <td class="text-right pt-5"><strong>{{ totalRecibos | number: '1.2' }} Bs</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 mt-5">
                                <h6 class="text-center text-danger">{{ config.mensaje_deudores }}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>