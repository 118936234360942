import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/classes/user';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-mfa',
  templateUrl: './mfa.component.html',
  styleUrls: ['./mfa.component.css']
})
export class MfaComponent implements OnInit {
  userFocus: boolean = false;
  pinFocus: boolean = false;
  validValues: boolean = false;
  user: User;
  loaderFrame: HTMLDivElement;
  errorMessage: string = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
      this.user = new User();
  }

  ngOnInit(): void {
    this.loaderFrame = <HTMLDivElement>document.getElementById('preloader');
    this.isLoading(false);  
  }

  isLoading(status = false) {
    this.loaderFrame.style.display = status ? 'flex' : 'none';
  }

  validateMFACode(ruta) {

    this.authService.validateMFACode(localStorage.getItem("socio_idTemp").toString(), localStorage.getItem("tokenTemp"), this.user.mfaCode).subscribe(
    response => {
      if (response.status == 200) {
        this.authService.setSession(response.body);
        if (ruta == "") {
          if (localStorage.getItem("tipo_perfil")=='tut') {
            this.authService.setSession(response.body);
            this.router.navigate(['panelsocio']);
          }
        } else {
          console.log("*************** "+ruta);
          this.router.navigate([ruta]);
        }        
      }
      console.log(response);
      this.errorMessage = response.message;
      this.isLoading(false);
    },
    error => {
      this.isLoading(false);
      console.log(error.error);
    }
  )}

  validateMFAClick() {
    this.validateMFACode("");
  }

  reloginClick() {
    this.router.navigate(['login']);
  }

  toggleUserFocus(event: any) {
    if (event.target.value.length != 0) {
      this.userFocus = true;
      return true;
    }
    this.userFocus = !this.userFocus;
  }

  togglePinFocus(event: any) {
    if (event.target.value.length != 0) {
      this.pinFocus = true;
      return true;
    }
    this.pinFocus = !this.pinFocus;
  }

  validateValues(event: any) {
    if (this.user.mfaCode.length != 6 ) {
      this.validValues = true;
    } else {
      this.validValues = false;
    }
  }

}
