<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <!--HEADER AREA START-->
        <app-header></app-header>
        <!--HEADER AREA END-->

        <div class="main-content-inner">

            <div class="row">
                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <h5 class="header-title">Panel de información para el socio</h5>
                                <div class="header-separator"></div>
                                <!-- <div *ngIf="tieneDeuda"> 
                                    
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <span>Para completar la inscripción por favor<br/>regularice las cuentas pendientes con el Colegio.<br/>Gracias.</span>
                                    </div>
                                </div> -->
                                <!-- <div *ngIf="!tieneDeuda">  -->
                                    <div *ngIf="isMenuAvailable('inscripcion')">
                                        <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="inscripcion_button" (click)="inscripcionClick()"
                                            type="button">Inscripción
                                            <i class="ti-arrow-right"></i></button>
                                        </div>
                                    </div>
                                <!-- </div> -->
                                <div *ngIf="isMenuAvailable('bus')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="bus_button" (click)="busClick()"
                                            type="button">Contratar Transporte
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('boletin')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="boletin_button" (click)="boletinClick()" type="button">Boletín de
                                            calificaciones
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('pedagogico')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="pedagogico_button" (click)="pedagogicoClick()" type="button">Desarrollo
                                            Pedagógico
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('pagos')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="pagos_button" (click)="pagosClick()" type="button">Seguimiento Pensiones
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('prestamolibros')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="prestamo_libros_button" (click)="prestamoLibrosClick()"
                                            type="button">Préstamo de libros por Estudiante
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('cita')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="cita_button_button" (click)="citaClick()" type="button">Solicitar cita
                                            con Docente
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </div>
                                <div *ngIf="isMenuAvailable('elecciones')">
                                <ng-container *ngFor="let ele of elecciones">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="panel_eleccion_button" (click)="paneleleccionClick()" type="button">
                                            {{ ele.name }}
                                            <i class="ti-arrow-right"></i></button>
                                    </div>
                                </ng-container> 
                                </div>
                                <div *ngIf="isMenuAvailable('encuesta')">
                                    <div class="submit-btn-area col-md-12 mb-3 mt-1">
                                        <button id="encuesta_button" (click)="encuestaClick()" type="button">Encuestas
                                            <i class="ti-arrow-right"></i></button>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Panel de circulares -->
                <div class="col-lg-6 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12">
                                <div *ngIf="isMenuAvailable('circulares')">
                                    <h5 class="header-title">Circulares</h5>
                                    <div class="header-separator"></div>
                                    <ng-container *ngFor="let circular of circulares">
                                        <div class="col-md-12">
                                            <span class="date-circular">{{ formatFecha(circular.fecha) }}</span>
                                            <h6 class="circular-title" (click)="circularClick(circular.id)">{{ circular.name }}</h6>
                                        </div>
                                        <div class="col-md-12" *ngIf="circular.mostrar" [innerHtml]="circular.contenido">
                                        </div>
                                        <div class="separator col-md-12"></div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    </div>
</div>