export const URLConstants = {
    // odoo_url: 'http://192.168.0.198:8169/',
    // backend_url: 'http://localhost/backend/',
    
    backend_url: 'http://portal.ffroebel.com/backend/',
    odoo_url: 'http://plataforma.ffroebel.com/',
    odoo_db: 'froebel'
}

export const FormConstants = {
    editables: true,
    zonas: [
        { value: '0', name: 'NINGUNO' },
        { value: '1', name: 'BELLA VISTA' },
        { value: '2', name: 'RUTA SACABA' },
        { value: '3', name: 'PACATA' },
        { value: '4', name: 'AV.AMERICA ESTE' },
        { value: '5', name: 'ZONA SAN PEDRO' },
        { value: '6', name: 'B. IRLANDES - LAS CUADRAS' },
        { value: '7', name: ' AV.PAPA PAULO' },
        { value: '8', name: 'AV.  DORBIGNI' },
        { value: '9', name: 'MAGNOLIAS III' },
        { value: '10', name: 'RUTA QUILLACOLLO' },
        { value: '11', name: 'PUENTE COBIJA' },
        { value: '12', name: 'JUAN DE LA ROSA' },
        { value: '13', name: 'CENTRO - AYACUCHO' },
        { value: '14', name: 'PUENTE KILLMAN' },
        { value: '15', name: 'CHIQUICOLLO' },
        { value: '16', name: 'FINAL AMERICA OESTE' },
        { value: '17', name: 'TIQUIPAYA' },
        { value: '18', name: 'CIRCUNVALACION OESTE' },
        { value: '19', name: 'SIMON LOPEZ' }
    ],
    gradosDiscapacidad: [
        { value: 'leve', name: 'Leve' },
        { value: 'mod', name: 'Moderado' },
        { value: 'gra', name: 'Grave' },
        { value: 'mgra', name: 'Muy grave' },
        { value: 'cegt', name: 'Ceguera total' },
        { value: 'bvis', name: 'Baja visión' }
    ],
    tiposDiscapacidad: [
        { value: 'psi', name: 'Psíquica' },
        { value: 'aut', name: 'Autismo' },
        { value: 'down', name: 'Síndrome de Dwn' },
        { value: 'int', name: 'Intelectual' },
        { value: 'aud', name: 'Auditiva' },
        { value: 'fis', name: 'Física-Motora' },
        { value: 'sor', name: 'Sordoceguera' },
        { value: 'mul', name: 'Múltiple' },
        { value: 'vis', name: 'Visual' }
    ],
    hospedadores: [
        { value: 'padres', name: 'Padre y madre' },
        { value: 'padre', name: 'Solo padre' },
        { value: 'madre', name: 'Solo madre' },
        { value: 'tutor', name: 'Otro' },
        { value: 'solo', name: 'Solo(a)' },
        { value: 'extraordinaria', name: 'En tutela extraordinaria' }
    ],
    tiemposTransporte: [
        { value: '30_min', name: 'Menos de media hora' },
        { value: '30-60_min', name: 'Entre media y una hora' },
        { value: '1-2_hrs', name: 'Entre una o dos horas' },
        { value: '2_hrs', name: 'Dos horas o más' }
    ],
    tiposTransporte: [
        { value: 'pie', name: 'A pie' },
        { value: 'terrestre', name: 'Vehículo de transporte terrestre' },
        { value: 'fluvial', name: 'Fluvial' },
        { value: 'otro', name: 'Otro' }
    ],
    frecuenciaInternet: [
        { value: 'diario', name: 'Diariamente' },
        { value: 'semana', name: 'Una vez a la semana' },
        { value: 'semanal', name: 'Más de una vez a la semana' },
        { value: 'mensual', name: 'Una vez al mes o menos' }
    ],
    tiposVivienda: [
        { value: 'propio', name: 'Propia' },
        { value: 'alquiler', name: 'Alquilada' },
        { value: 'anticretico', name: 'Anticrético' },
        { value: 'cedida', name: 'Cedida por servicios' },
        { value: 'prestada', name: 'Prestada por parientes o amigos' },
        { value: 'mixto', name: 'Contrato mixto(alquiler y anticrético)' }
    ],
    saludVisitas: [
        { value: '1-2', name: '1 a 2 veces' },
        { value: '3-5', name: '3 a 5 veces' },
        { value: '6+', name: '6 o más' },
        { value: 'no', name: 'Ninguna' }
    ],
    tiposRelacion: [
        { value: 'padre', name: 'Padre' },
        { value: 'madre', name: 'Madre' },
        { value: 'tutor', name: 'Otro' }
    ],
    sexos: [
        { value: 'mas', name: 'Masculino' },
        { value: 'fem', name: 'Femenino' }
    ],
    ciudades: [
        { value: 'cba', name: 'Cochabamba' },
        { value: 'ben', name: 'Beni' },
        { value: 'chu', name: 'Chuquisaca' },
        { value: 'lap', name: 'La Paz' },
        { value: 'oru', name: 'Oruro' },
        { value: 'pan', name: 'Pando' },
        { value: 'pot', name: 'Potos & iacute;' },
        { value: 'scz', name: 'Santa Cruz' },
        { value: 'tar', name: 'Tarija' }
    ],
    pueblos: [
        { 'value': 'no', name: 'Ninguno' },
        { 'value': 'afroboliviano', name: 'Afroboliviano' },
        { 'value': 'araona', name: 'Araona' },
        { 'value': 'aymara', name: 'Aymara' },
        { 'value': 'ayoreo', name: 'Ayoreo' },
        { 'value': 'baures', name: 'Baures' },
        { 'value': 'canichana', name: 'Canichana' },
        { 'value': 'cavinenno', name: 'Cavineño' },
        { 'value': 'cayubaba', name: 'Cayubaba' },
        { 'value': 'chacobo', name: 'Ch ácobo' },
        { 'value': 'chiman', name: 'Chiman' },
        { 'value': 'chiquitano', name: 'Chiquitano (Monkox)' },
        { 'value': 'eseejja', name: 'Ese ejja' },
        { 'value': 'guarani', name: 'Guaraní' },
        { 'value': 'guarasugwe', name: 'Guarasug\'we' },
        { 'value': 'gwarayu', name: 'Gwarayu' },
        { 'value': 'itonoma', name: 'Itonoma' },
        { 'value': 'leco', name: 'Leco' },
        { 'value': 'kallawaya', name: 'Kallawaya' },
        { 'value': 'machineri', name: 'Machineri' },
        { 'value': 'maropa', name: 'Maropa' },
        { 'value': 'mojos_ign', name: 'Mojos-Ignasiano' },
        { 'value': 'mojos_tri', name: 'Mojos-Trinitario' },
        { 'value': 'more', name: 'Moré' },
        { 'value': 'moseten', name: 'Moseten' },
        { 'value': 'movima', name: 'Movima' },
        { 'value': 'pacawara', name: 'Pacawara' },
        { 'value': 'pukina', name: 'Pukina' },
        { 'value': 'quechua', name: 'Quechua' },
        { 'value': 'siriono', name: 'Siriono' },
        { 'value': 'tacana', name: 'Tacana' },
        { 'value': 'tapiete', name: 'Tapiete' },
        { 'value': 'toromona', name: 'Toromona' },
        { 'value': 'uruchipaya', name: 'Uru Chipaya' },
        { 'value': 'weenhayek', name: 'Weenhayek' },
        { 'value': 'yaminahua', name: 'Yaminahua' },
        { 'value': 'yuki', name: 'Yuki' },
        { 'value': 'yurakare', name: 'Yurakaré' }
    ]

};
