import { Component, OnInit, ViewChild } from '@angular/core';
import { Encuesta, Pregunta } from 'src/app/classes/encuesta';
import { Router } from '@angular/router';
import { EncuestaService } from 'src/app/services/encuesta.service';


import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {NgbAlert} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.css']
})
//Ejecutar desde la consola
//ng add @ng-bootstrap/ng-bootstrap

export class EncuestaComponent implements OnInit {
  public encuestas: Encuesta[];
  public preguntas: any[];
  public encuestaActiva: any;
  public isLoading: boolean;
  public alertMessage = "";
  public alertFailMessage = "";
  public staticAlertClosed = false;
  public fromApp: boolean;

  constructor(
    private _encuestaService: EncuestaService,
    private _router: Router,
  ) { 
    this.encuestas = [];
    this.preguntas = [];
    this.encuestaActiva = {};
  }

  @ViewChild('staticAlert', {static: false}) staticAlert: NgbAlert;
  @ViewChild('staticAlert', {static: false}) staticFailAlert: NgbAlert;
  @ViewChild('selfClosingAlert', {static: false}) selfClosingAlert: NgbAlert;

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
    this.alertMessage = "Su respuesta a la encuesta se registró. Gracias.";
    setTimeout(() => this.closeAlert(), 4000);
    if (this.selfClosingAlert) {
      this.selfClosingAlert.close();
    }
    //Su respuesta a la encuesta NO se registró. Por favor intente más tarde o contáctese con el Colegio.
  }

  showFail() {
    this.playAudio('error.mp3');
    this.alertMessage = "Ya se registró una respuesta anteriormente.";
    setTimeout(() => this.closeAlert(), 4000);
    if (this.selfClosingAlert) {
      this.selfClosingAlert.close();
    }
  }

  closeFailAlert() {
    this.staticFailAlert.close();
  }

  closeAlert() {
    this.staticAlert.close();
    if (localStorage.getItem('tipo_perfil') == 'tut'){
      this._router.navigate(['/panelsocio']);
    }else{
      this._router.navigate(['/estudiante']);
    }
  }

  hideLoader() {
    this.isLoading = false;
  }

  showError() {
    this.playAudio('error.mp3');
    this.alertMessage = "Su respuesta a la encuesta NO se registró. Por favor intente más tarde o contáctese con el Colegio.";
    setTimeout(() => this.closeFailAlert(), 4000);
    if (this.selfClosingAlert) {
      this.selfClosingAlert.close();
    }
    //Su respuesta a la encuesta NO se registró. Por favor intente más tarde o contáctese con el Colegio.

  }

  ngOnInit(): void {
    this.fromApp = localStorage.getItem("fromApp") == "true";
    this.loadEncuestas();
  }

  loadEncuestas() {
    this._encuestaService.getEncuestas().subscribe(
      result => {
        this.encuestas = [];
        result.body.forEach(element => {
          let enc = new Encuesta();
          enc = Object.assign(enc, element);
          this.encuestas.push(enc);
        });
      },
      error => [ console.log(error)]
    );
  }

  getOpciones(opciones:String){
    let result = []
    let opciones_parse = opciones.split('\n');
    opciones_parse.forEach(element => {
      result.push({'value':element, 'name':element});
    });
    return result;
  }

  getRespuestasbyPregunta(preguntaId, perfilId) {

    this._encuestaService.getRespuestas(preguntaId, perfilId).subscribe(
      result => {
        this.encuestas = [];
        result.body.forEach(element => {
          let enc = new Encuesta();
          enc = Object.assign(enc, element);
          this.encuestas.push(enc);
        });
      },
      error => [ console.log(error)]
    );
  }

  isPreguntaSino(pregunta) {
    if(pregunta.tipo == 'sino') {
      return true;
    }
    return false;
  }

  isPreguntaLibr(pregunta) {
    if(pregunta.tipo == 'libr') {
      return true;
    }
    return false;
  }

  isPreguntaSele(pregunta) {
    if(pregunta.tipo == 'sele') {
      return true;
    }
    return false;
  }

  goTo(url){
    if (localStorage.getItem('tipo_perfil') == 'tut'){
      this._router.navigate(['/socio']);
    }else{
      this._router.navigate(['/estudiante']);
    }
  }

  encuestaClick(encuestaId) {
    this._encuestaService.getPreguntas(encuestaId).subscribe(
      result => {
        this.encuestaActiva = this.encuestas.find(elm => elm.id === encuestaId);
        this.encuestaActiva.preguntas = [];
        result.body.forEach(elm => {
          let pregunta = new Pregunta;
          pregunta = Object.assign(pregunta, elm);
          this.encuestaActiva.preguntas.push(pregunta);
        });
      },
      error => [ console.log(error)]
    );
  }
  
  showRespuestas() {
    this.getRespuesta(localStorage.getItem("socio_id"), this.encuestaActiva.preguntas[0].id);
  }

  getRespuesta(perfilId, preguntaId) {
    this._encuestaService.getRespuestas(perfilId, preguntaId).subscribe(
      response => {

        if (response.status == 200) {
          
          if (response.body[0]["respuestas"] > 0) {
            this.showFail();
          } else {
            this.saveRespuesta();
            this.showSuccess();
          }
          //this.showSuccess();
          
        }
        this.hideLoader();
      },
      error => {
        this.hideLoader();
        this.showError();
      }
    );
  }

  saveRespuesta() {
    this.encuestaActiva.perfilId = localStorage.getItem("socio_id");
    this._encuestaService.updateData(this.encuestaActiva).subscribe(
      response => {
        if (response.status == 200) {
          //alert("Gracias por responder");
          this.showSuccess(); 
        }
        this.hideLoader();
      },
      error => {
        this.hideLoader();
        this.showError();
      }
    );
  }

}
