import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { URLConstants } from "src/app/classes/constants";
import { Encuesta } from '../classes/encuesta';

@Injectable({
  providedIn: 'root'
})
export class EncuestaService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    this.SERVER_URL = URLConstants.backend_url + 'encuesta/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }


  getEncuestas() {
    if (localStorage.getItem("tipo_perfil") == "est") {
      const service_url = this.SERVER_URL + 'get.php';
      return this._http.get<PHPResponse>(service_url, {
        headers: this.httpHeaders,
        params: {
          'estudiante_id': localStorage.getItem("socio_id"),
        }
      });
  
    }
    if (localStorage.getItem("tipo_perfil") == "tut") {
      const service_url = this.SERVER_URL + 'get.php';
      return this._http.get<PHPResponse>(service_url, {
        headers: this.httpHeaders,
        params: {
          'socio_id': localStorage.getItem("socio_id"),
        }
      });
    }

  }

  getPreguntas(encuestaId) {
      const service_url = this.SERVER_URL + 'get.php';
      return this._http.get<PHPResponse>(service_url, {
        headers: this.httpHeaders,
        params: {
          'encuesta_id': encuestaId
        }
      });  

  }

  getRespuestas(perfilId, preguntaId) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'perfil_id': perfilId,
        'pregunta_id': preguntaId
      }
    });  

  }

  updateData(encuesta: Encuesta) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: encuesta }, { headers: this.httpHeaders });
  }

}
