import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { AuthService } from 'src/app/services/auth.service';
import { Socio } from 'src/app/classes/socio';
import { Estudiante } from 'src/app/classes/estudiante';
import { SocioService } from 'src/app/services/socio.service';
import { EstudianteService } from 'src/app/services/estudiante.service';
import { Formrude } from 'src/app/classes/formrude';
import { InscripcionService } from 'src/app/services/inscripcion.service';
import { FormConstants } from 'src/app/classes/constants';
import { Config } from 'src/app/classes/config';


@Component({
  selector: 'app-inscripcion',
  templateUrl: './inscripcion.component.html',
  styleUrls: ['./inscripcion.component.css']
})
export class InscripcionComponent implements OnInit, AfterViewChecked {
  public padre: Socio;
  public madre: Socio;
  public estudiante: Estudiante;
  public formRude: Formrude;
  public config: Config;
  public sexos: any;
  public ciudades: any;
  public pueblos;
  public saludVisitas;
  public selectValues;
  public isLoading: boolean;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _socioService: SocioService,
    private _estudianteService: EstudianteService,
    private _inscripcionService: InscripcionService) {
    this.selectValues = FormConstants;
    //this.socio = new Socio();
    this.config = new Config();
    this.padre = new Socio();
    this.madre = new Socio();
    this.estudiante = new Estudiante();
    this.formRude = new Formrude();
    this.isLoading = true;
  }

  ngOnInit(): void {
    this._authService.validateAccount();
    let estudianteId = this._activatedRoute.snapshot.paramMap.get("id");
    this.getFormulario(estudianteId);
    this.getPadres(estudianteId);
    this.getConfig();
    // this.getSocio();

  }

  ngAfterViewChecked() {
  }

  async playAudio(audioName: string) {
    let audio = new Audio();
    audio.src = "assets/audio/" + audioName;
    audio.load();
    await audio.play();
  }

  showSuccess() {
    this.playAudio('success.mp3');
  }

  showError() {
    // this.playAudio('error.mp3');
  }

  showLoader() {
    this.isLoading = true;
  }
  hideLoader() {
    this.isLoading = false;
  }

  logout() {
    this._authService.logout();
  }

  goTo(path) {
    this._router.navigate([path]);
  }

  getNameValue(obCollection: any, value) {
    let obResponse = obCollection.find(element => element.value === value)
    if (obResponse !== undefined) {
      return obResponse.name;
    }
    return '';
  }

  // getSocio() {
  //   this._socioService.getCurrentSocio().subscribe(
  //     response => {
  //       if (response.status == 200) {
  //         this.socio = new Socio(response.body);
  //         this.nosocio = new Socio(response.body.corepresentante);
  //       }
  //     },
  //     error => { console.log(error.error) }
  //   );
  // }

  getConfig() {
    this._authService.getConfig().subscribe(
      response => {
        if (response.status === 200) {
          Object.assign(this.config, response.body);
        }
      },
      error => { console.log(error); }
    );
  }

  getPadres(estudianteId) {
    this._inscripcionService.getPadres(estudianteId).subscribe(
      response => {
        if (response.status == 200) {
          response.body.forEach(element => {
            console.log(element.tipo_relacion);
            if (element.tipo_relacion === 'padre') {
              this.padre = new Socio(element);
            }
            if (element.tipo_relacion === 'madre') {
              this.madre = new Socio(element);
            }
          });
        }
        // console.log(this.padre);
        // console.log(this.madre);
      },
      error => { console.log(error); }
    )
  }

  getEstudiante(estudianteId) {
    this._estudianteService.getEstudiante(estudianteId).subscribe(
      response => {
        if (response.status == 200) { this.estudiante = response.body }
      },
      error => { console.log(error.error) }
    );
  }

  getFormulario(estudianteId) {
    this.showLoader();
    this._inscripcionService.getFormulario(estudianteId).subscribe(
      response => {
        if (response.status == 200) {

          // this.formRude = response.body 
          this.formRude = new Formrude();
          Object.assign(this.formRude, response.body);
        }
        console.log('FORM');
        console.log(this.formRude);
        this.hideLoader();
      },
      error => {
        console.log(error.error);
        this.hideLoader();
      }
    );
  }

  onchangeHospedador(hospedador: string) {
    if (hospedador === 'padre' || hospedador === 'padres') {
      this.formRude.domicilio_departamento = this.padre.departamento;
      this.formRude.domicilio_provincia = this.padre.provincia;
      this.formRude.domicilio_municipio = this.padre.municipio;
      this.formRude.domicilio_localidad = this.padre.localidad;
      this.formRude.domicilio_zona = this.padre.zona;
      this.formRude.domicilio_avenida = this.padre.avenida;
      this.formRude.domicilio_nro_vivienda = this.padre.nro_vivienda;
      this.formRude.domicilio_telf_fijo = this.padre.telf_fijo;
      this.formRude.domicilio_telf_movil = this.padre.telf_movil;
      this.formRude.domicilio_punto_referencia = this.padre.direccion_ref;
    }
    if (hospedador === 'madre') {
      this.formRude.domicilio_departamento = this.madre.departamento;
      this.formRude.domicilio_provincia = this.madre.provincia;
      this.formRude.domicilio_municipio = this.madre.municipio;
      this.formRude.domicilio_localidad = this.madre.localidad;
      this.formRude.domicilio_zona = this.madre.zona;
      this.formRude.domicilio_avenida = this.madre.avenida;
      this.formRude.domicilio_nro_vivienda = this.madre.nro_vivienda;
      this.formRude.domicilio_telf_fijo = this.madre.telf_fijo;
      this.formRude.domicilio_telf_movil = this.madre.telf_movil;
      this.formRude.domicilio_punto_referencia = this.madre.direccion_ref;
    }
  }

  copyPadresRude() {
    this.formRude.padre_cedula = this.padre.documento;
    this.formRude.padre_complemento = this.padre.documento_complemento;
    this.formRude.padre_expedido = this.padre.documento_expedido;
    this.formRude.padre_apellido_paterno = this.padre.apellido_paterno;
    this.formRude.padre_apellido_materno = this.padre.apellido_materno;
    this.formRude.padre_nombres = this.padre.nombres;
    this.formRude.padre_idioma = this.padre.idioma;
    this.formRude.padre_ocupacion = this.padre.trabajo_actual;
    this.formRude.padre_instruccion = this.padre.instruccion;
    this.formRude.padre_fecha_nacimiento = this.padre.fecha_nacimiento;

    this.formRude.madre_cedula = this.madre.documento;
    this.formRude.madre_complemento = this.madre.documento_complemento;
    this.formRude.madre_expedido = this.madre.documento_expedido;
    this.formRude.madre_apellido_paterno = this.madre.apellido_paterno;
    this.formRude.madre_apellido_materno = this.madre.apellido_materno;
    this.formRude.madre_nombres = this.madre.nombres;
    this.formRude.madre_idioma = this.madre.idioma;
    this.formRude.madre_ocupacion = this.madre.trabajo_actual;
    this.formRude.madre_instruccion = this.madre.instruccion;
    this.formRude.madre_fecha_nacimiento = this.madre.fecha_nacimiento;
  }

  saveInscripcion() {
    this.copyPadresRude();
    let validation = this.formRude.validateObject('formRude_', 'novalids');
    if (validation.ids.length !== 0) {
      let validData = this.formRude.validateObject('formRude_', 'valids');
      this.spotInputs(validData.ids, 'form-control form-control-sm');
      console.log('NO VALID DATA, CHECK FORM');
      this.spotInputs(validation.ids, 'form-control form-control-sm novalid');
      alert('VERIFIQUE LOS DATOS DE INSCRIPCION RESALTADOS');
      return false;
    }
    this.showLoader();
    this._inscripcionService.saveInscripcion(this.formRude).subscribe(
      response => {
        if (response.status === 200) {
          this.formRude = new Formrude();
          // console.log("*****");
          // console.log(response.body);
          Object.assign(this.formRude, response.body);
        }
        this.hideLoader();
        this.showSuccess();
      },
      error => {
        console.log(error);
        this.showError();
        this.hideLoader();
      }
    );
  }

  spotInputs(inputIds: string[], class_name: string) {
    for (let x of inputIds) {
      let doc = document.getElementById(x);
      if (doc === null) {
        console.log('Element ID: ' + x + ' not found');
        continue;
      }
      doc.className = class_name;
    }
  }

}