import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { Voto } from '../classes/voto';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class EleccionService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    // this.SERVER_URL = 'http://localhost/froebel-webservice/dist/socio/';
    this.SERVER_URL = URLConstants.backend_url + 'eleccion/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getElecciones() {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'socio_id': localStorage.getItem("socio_id")
      }
    });
  }

  getCandidatos(eleccion_id, consejo_id) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'eleccion_id': eleccion_id,
        'consejo_id': consejo_id
      }
    });
  }

  getVotosocio(eleccion_id, consejo_id, socio_id) {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'eleccion_id': eleccion_id,
        'consejo_id': consejo_id,
        'socio_id': socio_id
      }
    });
  }

  //updateData(payLoad: PayLoad) {
  updateData(payLoad) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: payLoad }, { headers: this.httpHeaders });
  }

}
