export class Encuesta {
    id: Number;
    perfilId: Number;
    name: String;
    state: String;
    fecha_creacion: String;
    fecha_cierre: String;
    preguntas: Pregunta[];
    para_socio: Boolean;
    para_estudiante: Boolean;
    todos_grados: Boolean;
    descripcion: String;
}

export class Pregunta {
    id: Number;
    name: String;
    respuesta: String;
    opciones: String;
    sino:boolean;
    sele:String;
    libr:String;
    tipo: String;
}
