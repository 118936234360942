import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { PopupSocio, Socio } from '../classes/socio';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class SocioService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    // this.SERVER_URL = 'http://localhost/froebel-webservice/dist/socio/';
    this.SERVER_URL = URLConstants.backend_url + 'socio/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getCurrentSocio() {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'id': localStorage.getItem("socio_id")
      }
    });
  }

  updateData(socio: Socio) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'update.php', { body: socio }, { headers: this.httpHeaders });
  }
  
  postPopup(popup: PopupSocio) {
    return this._http.post<PHPResponse>(this.SERVER_URL + 'popup.php', { body: popup }, { headers: this.httpHeaders });
  }

  getRecibosPendientes(socioId) {
    return this._http.get<PHPResponse>(this.SERVER_URL + 'recibos_pendientes.php', {
      headers: this.httpHeaders,
      params: {
        'socio_id': socioId
      }
    });
  }

  getTieneDeuda() {
    const service_url = this.SERVER_URL + 'get_kardex.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {
        'socio_id': localStorage.getItem("socio_id"),
        'tiene_deuda' : '1'
      }
    });
  }

}
