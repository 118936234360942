import { Component, OnInit } from '@angular/core';
import { PrestamoLibrosService } from 'src/app/services/prestamo-libros.service';
import { AuthService } from 'src/app/services/auth.service';
import { Config } from 'src/app/classes/config';
import { Router } from '@angular/router';

@Component({
  selector: 'app-prestamo-libros',
  templateUrl: './prestamo-libros.component.html',
  styleUrls: ['./prestamo-libros.component.css']
})
export class PrestamoLibrosComponent implements OnInit {
  public prestamoLibros: any[];
  public show: boolean = false;
  public isLoading: boolean;
  public config: Config;

  constructor(
    private _prestamoLibrosService: PrestamoLibrosService,
    private _authService: AuthService,
    private _router: Router
  ) {
    this.config = new Config();
  }

  ngOnInit(): void {
    this.loadPrestamoLibros(localStorage.getItem("socio_id"));
    this.getConfig();
  }
  
  goTo(path) {
    this._router.navigate([path]);
  }

  loadPrestamoLibros(socio_id): void {
    this._prestamoLibrosService.getPrestamos(socio_id).subscribe(
      response => {
        console.log(response);
        if (response.status === 200) {
          this.prestamoLibros = response.body;
        } else {
          console.log(response);
        }
        this.isLoading = false;
      },
      error => {
        console.log(error);
        this.isLoading = false;
      }
    );
  }

  getConfig() {
    this._authService.getConfig().subscribe(
      response => {
        if (response.status === 200) {
          Object.assign(this.config, response.body);
        }
      },
      error => { console.log(error); }
    );
  }

  mailto(emailSubject: any) {
    return "mailto:" + this.config.biblioteca_email + "?subject=" + emailSubject
  }

  currentDate() {
    let result = new Date();
    console.log("currentDate ");
    console.log(result);
    return result;
  }

  convertDateToString(dateString:string) {
    let curr = this.currentDate();
    let result;
    try {
      let newDate = new Date(dateString);
      result = newDate;      
    } catch (exception) {      
    }
    return result;
  }

  plazoVencido(plazo:string, state:string) {
    let curr = this.currentDate();
    let result = false;
    try {
      if (plazo.length>0 && state=='ent') {
        let plazoDate = this.convertDateToString(plazo);
        result = curr > plazoDate;
      }
    } catch (exception) {      
    }
    return result;
  }

}
