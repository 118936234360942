<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">
        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">
                        <div class="card-body">
                            <div class="col-sm-12 pt-3">
                                <button (click)="goTo('/estudiante')"
                                    class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                        class="fa fa-arrow-left"></i> Atrás</button>
                                <br />
                                <h5 class="header-title">Actividades planificadas</h5>
                                <div class="header-separator"></div>
                            </div>
                            <div id="avisos" style='background-color: firebrick; color:floralwhite'></div>
                            <div class="row col-md-12">
                                <div class="element3 col-md-12 table-responsive mdtext">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>
                                                <th>Docente</th>
                                                <th>Fecha</th>
                                                <th>Actividad</th>
                                                <th>Detalle</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                        
                                            <tr *ngFor="let eve of eventos">
                                                <td class="asignatura-row"><strong>{{ eve.docente }}</strong></td>
                                                <td><span>{{ eve.fecha_ini }}</span></td>
                                                <td><span>{{ eve.name }}</span></td>
                                                <td><span>{{ eve.detalle }}</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>