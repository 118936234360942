import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { URLConstants } from "src/app/classes/constants";

@Injectable({
  providedIn: 'root'
})
export class GestionService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    this.SERVER_URL = URLConstants.backend_url + 'gestion/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getGestionActual() {
    const service_url = this.SERVER_URL + 'get.php';
    return this._http.get<PHPResponse>(service_url, {
      headers: this.httpHeaders,
      params: {}
    });
  }

}
