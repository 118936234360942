import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PHPResponse } from '../classes/phpresponse';
import { URLConstants } from "src/app/classes/constants";
import { Busestudiante } from '../classes/busestudiante';

@Injectable({
  providedIn: 'root'
})
export class ZonaService {
  SERVER_URL: string;
  httpHeaders: {};
  constructor(private _http: HttpClient) {
    this.SERVER_URL = URLConstants.backend_url + 'zona/';
    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ` + localStorage.getItem('token')
    });
  }

  getZonas() {
      const service_url = this.SERVER_URL + 'get.php';
      return this._http.get<PHPResponse>(service_url, {
        headers: this.httpHeaders,
        params: {
        }
      });
  }

}
