export class Estudiante {
    id: number;
    nombres: string;
    apellido_paterno: string;
    apellido_materno: string;
    sexo: string;
    documento: string;
    documento_complemento: string;
    documento_expedido: string;
    email: string;

    idioma: string;
    telf_fijo: string;
    telf_movil: string;
    estado_rude: string;
    salud_tipo_sangre: string;
    salud_alergias: string;
    salud_datos: string;

    constructor(data: any = {}) {
        this.nombres = data.nombres;
        this.apellido_paterno = data.apellido_paterno;
        this.apellido_materno = data.apellido_materno;
        this.sexo = data.sexo;
        this.documento = data.documento;
        this.documento_complemento = data.documento_complemento;
        this.documento_expedido = data.documento_expedido;
        this.email = data.email;
        this.idioma = data.idioma;
        this.telf_fijo = data.telf_fijo;
        this.telf_movil = data.telf_movil;
        this.estado_rude = data.estado_rude;
        this.salud_tipo_sangre = data.salud_tipo_sangre;
        this.salud_alergias = data.salud_alergias;
        this.salud_datos = data.salud_datos;

    }
}
