<div id="preloader" *ngIf="isLoading">
    <div class="loader">
        <img src="assets/img/loading.gif" />
        <span class="loader-label">Cargando</span>
    </div>
</div>

<div class="page-container sbar_collapsed bg-black">
    <!-- main content area start -->
    <div class="main-content bg-black">

        <div class="main-content-inner">
            <div class="row">
                <div class="col-lg-12 mt-5">
                    <div class="card">

                        <div class="card-body">
                            <div class="col-sm-12 pt-3">

                                <ng-container *ngIf="es_est()">
                                    <button (click)="goTo('/estudiante')"
                                        class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                            class="fa fa-arrow-left"></i> Atrás</button>
                                </ng-container>
                                <ng-container *ngIf="es_tut()">
                                    <button (click)="goTo('/panelsocio')"
                                        class="btn btn-flat btn-outline-danger btn-red mb-5"><i
                                            class="fa fa-arrow-left"></i> Atrás</button>
                                </ng-container>

                                <br />

                                <h5 class="header-title">Boletin de Calificaciones</h5>
                                <div class="header-separator"></div>
                            </div>

                            <div class="row">
                                <div class="element1 col-md-2">
                                    <label>Estudiante(s)</label>
                                </div>
                                <div class="element3 col-md-12">
                                    <div class="table-responsive">
                                        <table class="table table-sm data-tables datatable-dark">
                                            <thead>
                                                <tr>
                                                    <th>Estudiante</th>
                                                    <th> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="hover-row" *ngFor="let estudiante of boletines">
                                                    <td>
                                                        {{ estudiante.estudiante }} Los boletines se entregarán personalmente o pueden verse desde la app del colegio.
                                                    </td>
                                                    <!-- <td class="text-right">
                                                        <button (click)="openBoletin(estudiante.uuid, estudiante.tipo_boletin)"
                                                            class="btn btn-flat btn-sm btn-outline-dark">
                                                            Ver Boletín
                                                            <i class="fa fa-file-text-o"></i>
                                                        </button>
                                                    </td> -->
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>